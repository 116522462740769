import * as React from 'react'

import NavLink from 'components/common/NavLink'
import { hasContent } from 'common/Utils'
import { NavigationNode, PageType, Page } from '../interfaces/Interfaces'
import { isArticleActiveNow } from '../common/CmsUtils'
import RootState from 'vo/RootState'
import { ModIconButton } from './ui/ModIconButton'
import { IconType } from './common/ModIcon'
import { ModLanguageSwitchAndSocialIcons, ModSearch } from './ModApp'

export interface ModNavigationProps {
  searchNavigationNode: NavigationNode
  navigationTree: NavigationNode
  rootState: RootState
  currentLanguage: string
  pathname: string
}

export function ModNavigation({
  rootState,
  currentLanguage,
  navigationTree,
  searchNavigationNode,
  pathname
}: ModNavigationProps) {
  const [activeIndexWhenDesktop, setActiveIndexWhenDesktop] = React.useState(-1)
  const [isCollapsedWhenMobile, setCollapsedWhenMobile] = React.useState(true)

  function isNodeVisible(node: NavigationNode) {
    if (!node._i18nCL.showInMenu) {
      return false
    }
    if (hasContent(node.children)) {
      return true
    }
    if (node.pageId) {
      try {
        return isArticleActiveNow(rootState.pages[PageType.Page][node.pageId] as Page, currentLanguage)
      } catch (error) {
        return false
      }
    }
    return true
  }

  function onMainNodeActive(id: string) {
    setActiveIndexWhenDesktop(Number(id))
  }

  function createMainNaviNode(node: NavigationNode, key: string) {
    return (
      <NavLink
        className="main_item"
        key={node._i18nCL.relativeUrl}
        to={node._i18nCL.relativeUrl}
        onActive={() => onMainNodeActive(key)}
        onClick={e => setCollapsedWhenMobile(true)}
      >
        {node._i18nCL.label}
      </NavLink>
    )
  }

  function createSubNaviNode(node: NavigationNode) {
    return (
      <NavLink
        className="sub_item"
        key={node._i18nCL.relativeUrl}
        to={node._i18nCL.relativeUrl}
        onClick={e => setCollapsedWhenMobile(true)}
      >
        {node._i18nCL.label}
      </NavLink>
    )
  }

  function createNavigationNodes(nodes: NavigationNode[], isSubNaviItem: boolean) {
    if (nodes.length < 1) {
      return null
    }
    return nodes.reduce((prevItem, item, index) => {
      if (!item._i18nCL) {
        return prevItem
      }
      if (!isNodeVisible(item)) {
        return prevItem
      }
      if (item.children.length > 0 && !isSubNaviItem) {
        // nur zweistufige Navigation erlaubt. Alle anderen werden ignoriert.
        prevItem.push(createMainNaviNode(item, `${index}`))

        const subiNavigationNodes = createNavigationNodes(item.children, true)
        const showHide = index == activeIndexWhenDesktop ? 'show' : 'hide'
        prevItem.push(
          <div key={`sub${index}`} className={`subnavigation ${showHide}`}>
            {subiNavigationNodes}
          </div>
        )
      } else {
        prevItem.push(isSubNaviItem ? createSubNaviNode(item) : createMainNaviNode(item, `${index}`))
      }
      return prevItem
    }, [])
  }

  React.useEffect(() => {    
    if(pathname === '/' + currentLanguage) {
      setActiveIndexWhenDesktop(-1)
    }
  }, [pathname, currentLanguage]);

  return (
    <>
    <div className={`navigation ${isCollapsedWhenMobile ? "hide" : "show"}`} >

      <nav>
        {createNavigationNodes(navigationTree.children, false)}
      </nav>


      <div className='navigation-mobile'>
        <ModLanguageSwitchAndSocialIcons rootNavigationNode={navigationTree} currentLanguage={currentLanguage} rootState={rootState}/>
        <ModSearch searchNavigationNode={searchNavigationNode} currentLanguage={currentLanguage} />
      </div>
    </div>
    <div className="navigation-button-mobile navigation-mobile">
        <ModIconButton
          type="button"
          className='navi-button-mobile'
          onClick={e => setCollapsedWhenMobile(!isCollapsedWhenMobile)}
          iconType={isCollapsedWhenMobile ? IconType.Hamburger : IconType.Close}
        />
      </div>
    </>
  );

}
