import * as React from 'react';
import { BlockNewsletter } from 'interfaces/InterfacesBlocks';
import { FormattedMessage } from 'react-intl'

export interface ModBlockNewsletterProps {
  readonly content: BlockNewsletter
}

export function ModBlockNewsletter({ content }: ModBlockNewsletterProps) {

  if (!content._i18nCL) {
    return null
  }  

  return (
    <div className="newsletter-addon">
      <form className="layout_form cr_form cr_font" action="https://eu1.cleverreach.com/f/81752-187021/wcs/" method="post" target="_blank">
        <div className="cr_body cr_page cr_font formbox">
          <h3 className="non_sortable" style={{ textAlign: 'left' }}>
            {content._i18nCL && content._i18nCL.title}
          </h3>

          <div className="editable_content" style={{ textAlign: 'left' }}>
            <div id="3918147" className="cr_ipe_item ui-sortable musthave">
              <label htmlFor="text3918147" className="itemname"><FormattedMessage id="form.mail" defaultMessage="E-Mail" />*</label> <input id="text3918147" name="email" type="text" style={{ width: '100%' }} />
            </div><div id="3918149" className="cr_ipe_item ui-sortable submit_container" style={{ textAlign: 'center' }}>
              <button type="submit" className="cr_button"><FormattedMessage id="form.confirm" defaultMessage="Anmelden" /></button>
            </div>
          </div>

          <noscript><a href="http://www.cleverreach.de">www.CleverReach.de</a></noscript>
        </div>

      </form>
    </div>
  )
}