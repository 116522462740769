import * as React from 'react'
import {ModInput} from '../../ui/ModInput'
import {ModGridRow} from '../../layout/ModGridRow'
import {ModPrimaryButton} from '../../ui/ModPrimaryButton'
import {FormattedMessage} from 'react-intl'
import {ModRadioGroup} from 'components/ui/ModRadioGroup'
import {BlockFormTalentscout} from 'interfaces/InterfacesBlocks'
import {ModFormCredentials} from './ModFormCredentials'
import ModIntlWrapper from 'components/common/utils/ModIntlWrapper'
import {ModEducationInputs} from './ModEducationInputs'
import {AuditionType, FormEducationCat, FormTalentscoutExtras} from './FormInterfaces'
import {
  isCheckedYes,
  getCheckedId,
  radioGroupOptions,
  getEducationSubjectOptions,
  isBallett,
  onFileUpload
} from './FormHelpers'
import {NavigationNodeContext} from 'components/ModApp'
import {
  FormInputTalentscoutNewRegistry,
  isoPostNewTalentscoutRegistry
} from './ClassFormTalentscoutViewModel'
import {FormResponseType, ModFormResponse} from './ModFormResponseMessage'
import {SchoolType} from './ModBlockFormTalentscoutRecurring'
import {ModCheckbox} from 'components/ui/ModCheckbox'

export interface FormInputTalentscout
  extends FormInputTalentscoutNewRegistry,
    FormTalentscoutExtras {
  newFor: {
    [SchoolType.SEK]: boolean
    [SchoolType.GYM_LONG]: boolean
    [SchoolType.GYM_SHORT]: boolean
    [SchoolType.DIFF]: boolean
  }
}

const emptySubject = {category: null, subject: '', lectures: '', years: ''}
const defaultFormState: FormInputTalentscout = {
  name: '',
  surname: '',
  street: '',
  postalCode: '',
  city: '',
  canton: '',
  phone: '',
  emergencyPhone: '',
  birthDate: '',
  email: '',
  email2: '',

  newFor: {
    [SchoolType.SEK]: false,
    [SchoolType.GYM_LONG]: false,
    [SchoolType.GYM_SHORT]: false,
    [SchoolType.DIFF]: false
  },

  hasAlreadyAttended: false,
  yearAlreadyAttended: '',

  isOnlyTalent: false,
  kSSchoolName: '',
  kSTypeGymLong: false,
  kSTypeGymShort: false,
  kSTypeSek: false,
  kSTypeOther: '',

  currentSchool: '',
  currentTeacher: '',
  oldSchool: '',
  preferredCareer: '',

  education: [{...emptySubject}],

  ballettEducationMethod: '',
  ballettEducationLevel: '',

  auditionFor: ''
}

export interface ModBlockFormTalentscoutProps {
  content: BlockFormTalentscout
}

export function ModBlockFormTalentscout({content}: ModBlockFormTalentscoutProps) {
  if (!content._i18nCL) {
    return null
  }

  const i18n = content._i18nCL
  const educationi18n = content.education._i18nCL
  if (!educationi18n) {
    return null
  }

  const [file, setFile] = React.useState<File>()
  const [formState, setFormState] = React.useState(defaultFormState)
  const [responseState, setResponseState] = React.useState(<div></div>)
  const currentLanguage = React.useContext(NavigationNodeContext).currentLanguage

  function onFormChange(key: string, value: string | boolean) {
    let newState = {...formState}
    newState[key] = value
    setFormState(newState)
  }

  function onNewForChange(key: string, value: boolean) {
    let newState = {...formState}
    newState.newFor[key] = value
    setFormState(newState)
  }

  function isAtLeastOneChecked(values: boolean[]) {
    for (var i = 0; i < values.length; i++) {
      if (values[i]) return false
    }

    return true
  }

  function onEducationChange(
    values: {idx: number; key: string; value: string | FormEducationCat}[]
  ) {
    let newState = {...formState}
    for (let val of values) {
      newState.education[val.idx][val.key] = val.value
    }
    setFormState(newState)
  }

  function onAddSubject() {
    let newState = {...formState}
    newState.education.push({...emptySubject}) // clone object
    setFormState(newState)
  }

  function onSave(e, formatMessage) {
    e.preventDefault()
    isoPostNewTalentscoutRegistry(
      file,
      formState,
      currentLanguage,
      new Date(content.eventDate)
    ).then(response => {
      console.log('on save response', response)
      if (response.ok) {
        let message: string = formatMessage({
          id: 'form.successTSD',
          defaultMessage: 'Erfolgreiche Anmeldung'
        })
        setResponseState(<ModFormResponse message={message} type={FormResponseType.Success} />)
        setFormState(defaultFormState)
      } else {
        setResponseState(
          <ModFormResponse
            message={`Server Error ${response.status} ${response.statusText}`}
            type={FormResponseType.Error}
          />
        )
      }
    })
  }

  return (
    <div className="block form talentscout">
      <h3>{i18n.formTitle}</h3>

      <ModIntlWrapper>
        {({formatMessage}) => (
          <form onSubmit={e => onSave(e, formatMessage)}>
            <fieldset>
              <ModIntlWrapper>
                {({formatMessage}) => (
                  <>
                    <ModFormCredentials
                      showEmergencyNumber={true}
                      showSecondMailAdress={true}
                      formLabels={content}
                      name={formState.name}
                      surname={formState.surname}
                      street={formState.street}
                      postalCode={formState.postalCode}
                      city={formState.city}
                      canton={formState.canton}
                      phone={formState.phone}
                      emergencyPhone={formState.emergencyPhone}
                      birthDate={formState.birthDate}
                      email={formState.email}
                      email2={formState.email2}
                      onFormChange={onFormChange}
                    />

                    <ModGridRow>
                      {[
                        {
                          columnWidth: 1,
                          component: (
                            <ModRadioGroup
                              groupName={'attended'}
                              key={i18n.labelAttended}
                              label={i18n.labelAttended}
                              options={radioGroupOptions(formatMessage)}
                              checked={getCheckedId(formState.hasAlreadyAttended)}
                              isInline={true}
                              onCheck={option =>
                                onFormChange('hasAlreadyAttended', isCheckedYes(option.id))
                              }
                            />
                          )
                        },
                        {
                          columnWidth: 1,
                          component: formState.hasAlreadyAttended ? (
                            <ModInput
                              key={i18n.labelAttendenceYear}
                              type={'text'}
                              label={i18n.labelAttendenceYear}
                              value={formState.yearAlreadyAttended}
                              onChange={e => onFormChange('yearAlreadyAttended', e.target.value)}
                            />
                          ) : (
                            <></>
                          )
                        }
                      ]}
                    </ModGridRow>

                    <h3>{i18n.titleNew}</h3>
                    <ModRadioGroup
                      groupName={'onlyTalentscouting'}
                      isInline={true}
                      options={[
                        {id: 'ksReco', label: i18n.labelKSRecommondation},
                        {id: 'onlyTalent', label: i18n.labelOnlyTalentscouting}
                      ]}
                      checked={formState.isOnlyTalent ? 'onlyTalent' : 'ksReco'}
                      onCheck={option => onFormChange('isOnlyTalent', option.id == 'onlyTalent')}
                      required={true}
                    />

                    {!formState.isOnlyTalent ? (
                      <>
                        <ModInput
                          type={'text'}
                          label={i18n.labelNameNewSchool}
                          value={formState.kSSchoolName}
                          onChange={e => onFormChange('kSSchoolName', e.target.value)}
                          required={true}
                        />

                        {/* <ModRadioGroup
                          groupName={'newFor'}
                          label={i18n.infoNewFor}
                          options={[
                            {id: SchoolType.SEK, label: i18n.labelSek},
                            {id: SchoolType.GYM_SHORT, label: i18n.labelGymShort},
                            {id: SchoolType.GYM_LONG, label: i18n.labelGymLong},
                            {id: SchoolType.DIFF, label: i18n.labelOther}
                          ]}
                          checked={getCheckedId(formState.newFor)}
                          isInline={false}
                          onCheck={option => onFormChange('newFor', isCheckedYes(option.id, true))}
                          required={true}
                        /> */}
                        <p>{i18n.infoNewFor}</p>
                        <ModCheckbox
                          label={i18n.labelSek}
                          onChange={isChecked => onNewForChange(SchoolType.SEK, isChecked)}
                          isChecked={formState.newFor[SchoolType.SEK]}
                          required={isAtLeastOneChecked(Object.values(formState.newFor))}
                          groupName={'checkNewFor'}
                        />
                        <ModCheckbox
                          label={i18n.labelGymShort}
                          onChange={isChecked => onNewForChange(SchoolType.GYM_SHORT, isChecked)}
                          isChecked={formState.newFor[SchoolType.GYM_SHORT]}
                          required={isAtLeastOneChecked(Object.values(formState.newFor))}
                          groupName={'checkNewFor'}
                        />
                        <ModCheckbox
                          label={i18n.labelGymLong}
                          onChange={isChecked => onNewForChange(SchoolType.GYM_LONG, isChecked)}
                          isChecked={formState.newFor[SchoolType.GYM_LONG]}
                          required={isAtLeastOneChecked(Object.values(formState.newFor))}
                          groupName={'checkNewFor'}
                        />
                        <ModCheckbox
                          label={i18n.labelOther}
                          onChange={isChecked => onNewForChange(SchoolType.DIFF, isChecked)}
                          isChecked={formState.newFor[SchoolType.DIFF]}
                          required={isAtLeastOneChecked(Object.values(formState.newFor))}
                          groupName={'checkNewFor'}
                        />

                        {formState.newFor.toDiffKs ? (
                          <ModInput
                            type={'text'}
                            label={i18n.labelOther}
                            value={formState.kSTypeOther}
                            onChange={e => onFormChange('kSTypeOther', e.target.value)}
                            required={true}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}

                    {/* school */}
                    <h3>{educationi18n.titleEducation}</h3>
                    <h4>{educationi18n.subtitleSchool}</h4>
                    <ModInput
                      type={'text'}
                      label={educationi18n.labelNameSchool}
                      value={formState.currentSchool}
                      onChange={e => onFormChange('currentSchool', e.target.value)}
                      required={true}
                    />
                    <ModInput
                      type={'text'}
                      label={educationi18n.labelNameTeacher}
                      value={formState.currentTeacher}
                      onChange={e => onFormChange('currentTeacher', e.target.value)}
                      required={true}
                    />
                    <ModInput
                      type={'text'}
                      label={educationi18n.labelOldSchool}
                      value={formState.oldSchool}
                      onChange={e => onFormChange('oldSchool', e.target.value)}
                    />
                    <ModInput
                      type={'text'}
                      label={educationi18n.labelPrefferedSubject}
                      value={formState.preferredCareer}
                      onChange={e => onFormChange('preferredCareer', e.target.value)}
                      required={true}
                    />

                    {/*  addable inputs */}
                    <h4>{educationi18n.subtitleEducation}</h4>
                    <ModEducationInputs
                      labels={{
                        mainSubject: educationi18n.labelMainSubject,
                        lectures: educationi18n.labelLectures,
                        years: educationi18n.labelEducationYears,
                        addMore: educationi18n.labelAddMore
                      }}
                      options={getEducationSubjectOptions(educationi18n)}
                      education={formState.education}
                      onAddSubject={onAddSubject}
                      onSubjectChange={onEducationChange}
                    />

                    {/* ballett */}
                    {isBallett(formState) && (
                      <React.Fragment>
                        <h3>{educationi18n.subtitleBallet}</h3>
                        <ModGridRow>
                          {[
                            {
                              columnWidth: 1,
                              component: (
                                <React.Fragment key={educationi18n.labelEducationMethod}>
                                  <ModInput
                                    type={'text'}
                                    required
                                    label={educationi18n.labelEducationMethod}
                                    value={formState.ballettEducationMethod}
                                    onChange={e =>
                                      onFormChange('ballettEducationMethod', e.target.value)
                                    }
                                  />
                                  <ModInput
                                    type={'text'}
                                    required
                                    key={educationi18n.labelEducationLevel}
                                    label={educationi18n.labelEducationLevel}
                                    value={formState.ballettEducationLevel}
                                    onChange={e =>
                                      onFormChange('ballettEducationLevel', e.target.value)
                                    }
                                  />
                                </React.Fragment>
                              )
                            }
                          ]}
                        </ModGridRow>
                      </React.Fragment>
                    )}

                    <ModRadioGroup
                      groupName={'auditionFor'}
                      label={i18n.labelAuditionFor}
                      options={[
                        {id: AuditionType.BALLET, label: i18n.labelAuditionForBallet},
                        {id: AuditionType.HIPHOP, label: i18n.labelAuditionForHipHop}
                      ]}
                      checked={getCheckedId(formState.auditionFor)}
                      onCheck={option => onFormChange('auditionFor', isCheckedYes(option.id, true))}
                      required={true}
                    />
                    <br />

                    <p>{i18n.motivationText}</p>
                    <div className="input-wrapper">
                      <input
                        className="choose"
                        id="fileUpload"
                        type="file"
                        name="file"
                        accept="application/pdf"
                        onChange={event => {
                          const response = onFileUpload(event)
                          if (response.error) {
                            setResponseState(
                              <ModFormResponse
                                message={response.error}
                                type={FormResponseType.Error}
                              />
                            )
                            event.target.value = null
                          } else {
                            setResponseState(<div></div>)
                            setFile(response)
                          }
                        }}
                        required
                      />
                    </div>

                    {responseState}

                    <ModPrimaryButton type="submit">
                      <FormattedMessage id="form.confirm" defaultMessage="Anmelden" />
                    </ModPrimaryButton>
                  </>
                )}
              </ModIntlWrapper>
            </fieldset>
          </form>
        )}
      </ModIntlWrapper>
    </div>
  )
}
