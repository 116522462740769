import * as React from 'react'
import {ModInput} from '../../ui/ModInput'
import {ModGridRow} from '../../layout/ModGridRow'
import {ModPrimaryButton} from '../../ui/ModPrimaryButton'
import {FormattedMessage} from 'react-intl'
import {ModFormCredentials} from './ModFormCredentials'
import ModIntlWrapper from 'components/common/utils/ModIntlWrapper'
import {ModEducationInputs} from './ModEducationInputs'
import {
  FormEducationCat,
  FormInputCredentials,
  FormInputEducation,
  FormInputBallettEducation,
  FormTalentscoutExtras,
  AuditionType
} from './FormInterfaces'
import {
  isCheckedYes,
  getCheckedId,
  getEducationSubjectOptions,
  isBallett,
  onFileUpload
} from './FormHelpers'
import {isoPostRecurringTalentscoutRegistry} from './ClassFormTalentscoutViewModel'
import {BlockFormTalentscoutRecurring} from '../../../interfaces/InterfacesBlocks'
import {ModRadioGroup} from 'components/ui/ModRadioGroup'
import {NavigationNodeContext} from 'components/ModApp'
import {FormResponseType, ModFormResponse} from './ModFormResponseMessage'

export interface FormInputTalentscoutRecurring
  extends FormInputCredentials,
    FormInputEducation,
    FormInputBallettEducation,
    FormTalentscoutExtras {
  hasAlreadyAttended: boolean
  yearAlreadyAttended: string

  ksCurrentSchool: string
  ksSinceCurrentSchool: string
  currentKsTeacher: string
  currentKsPrincipal: string
  recurringFor: string
  kSTypeOther: string
}

export enum SchoolType {
  SEK = 'sek',
  GYM_SHORT = 'gymShort',
  GYM_LONG = 'gymLong',
  SEK_TO_SHORT = 'sekToGymShort',
  SEK_TO_LONG = 'sekToGymLong',
  DIFF = 'toDiffKs',
  TO_DIFF = 'changeToDifferent'
}

const emptySubject = {category: null, subject: '', lectures: '', years: ''}
const defaultFormState: FormInputTalentscoutRecurring = {
  name: '',
  surname: '',
  street: '',
  postalCode: '',
  city: '',
  canton: '',
  phone: '',
  emergencyPhone: '',
  birthDate: '',
  email: '',
  email2: '',

  ksCurrentSchool: '',
  ksSinceCurrentSchool: '',
  currentKsTeacher: '',
  currentKsPrincipal: '',

  recurringFor: '',
  kSTypeOther: '',

  hasAlreadyAttended: false,
  yearAlreadyAttended: '',

  currentSchool: '',
  currentTeacher: '',
  oldSchool: '',
  preferredCareer: '',

  education: [{...emptySubject}],

  ballettEducationMethod: '',
  ballettEducationLevel: '',

  auditionFor: ''
}

export interface ModBlockFormTalentscoutProps {
  content: BlockFormTalentscoutRecurring
}

export function ModBlockFormTalentscoutRecurring({content}: ModBlockFormTalentscoutProps) {
  if (!content._i18nCL) {
    return null
  }

  const i18n = content._i18nCL

  const educationi18n = content.education._i18nCL
  if (!educationi18n) {
    return null
  }

  const [file, setFile] = React.useState<File>()
  const [formState, setFormState] = React.useState(defaultFormState)
  const [responseState, setResponseState] = React.useState(<div></div>)
  const currentLanguage = React.useContext(NavigationNodeContext).currentLanguage

  function onFormChange(key: string, value: string | boolean) {
    let newState = {...formState}
    newState[key] = value
    setFormState(newState)
  }

  function onEducationChange(
    values: {idx: number; key: string; value: string | FormEducationCat}[]
  ) {
    let newState = {...formState}
    for (let val of values) {
      newState.education[val.idx][val.key] = val.value
    }
    setFormState(newState)
  }

  function onAddSubject() {
    let newState = {...formState}
    newState.education.push({...emptySubject}) // clone object
    setFormState(newState)
  }

  function onSave(e, formatMessage) {
    e.preventDefault()
    isoPostRecurringTalentscoutRegistry(
      file,
      formState,
      currentLanguage,
      new Date(content.eventDate)
    ).then(response => {
      if (response.ok) {
        let message: string = formatMessage({
          id: 'form.successTSD',
          defaultMessage: 'Erfolgreiche Anmeldung'
        })
        setResponseState(<ModFormResponse message={message} type={FormResponseType.Success} />)
        setFormState(defaultFormState)
      } else {
        setResponseState(
          <ModFormResponse
            message={`Server Error ${response.status} ${response.statusText}`}
            type={FormResponseType.Error}
          />
        )
      }
    })
  }

  return (
    <div className="block form talentscout">
      <h3>{i18n.formTitle}</h3>
      <ModIntlWrapper>
        {({formatMessage}) => (
          <form onSubmit={e => onSave(e, formatMessage)}>
            <fieldset>
              <>
                <ModFormCredentials
                  showEmergencyNumber={true}
                  showSecondMailAdress={true}
                  formLabels={content}
                  name={formState.name}
                  surname={formState.surname}
                  street={formState.street}
                  postalCode={formState.postalCode}
                  city={formState.city}
                  canton={formState.canton}
                  phone={formState.phone}
                  birthDate={formState.birthDate}
                  email={formState.email}
                  email2={formState.email2}
                  emergencyPhone={formState.emergencyPhone}
                  onFormChange={onFormChange}
                />

                <ModInput
                  type={'text'}
                  label={i18n.labelAttendenceYear}
                  value={formState.yearAlreadyAttended}
                  onChange={e => onFormChange('yearAlreadyAttended', e.target.value)}
                  required={true}
                />
                <h3>{i18n.titleCurrentKSSchool}</h3>
                <ModInput
                  type={'text'}
                  label={i18n.labelNameKSSchool}
                  value={formState.ksCurrentSchool}
                  onChange={e => onFormChange('ksCurrentSchool', e.target.value)}
                  required={true}
                />
                <ModInput
                  type={'date'}
                  label={i18n.labelKsSinceInSchool}
                  value={formState.ksSinceCurrentSchool}
                  onChange={e => onFormChange('ksSinceCurrentSchool', e.target.value)}
                  required={true}
                />
                <ModInput
                  type={'text'}
                  label={i18n.labelNameKsTeacher}
                  value={formState.currentKsTeacher}
                  onChange={e => onFormChange('currentKsTeacher', e.target.value)}
                  required={true}
                />
                <ModInput
                  type={'text'}
                  label={i18n.labelNameKsPrincipal}
                  value={formState.currentKsPrincipal}
                  onChange={e => onFormChange('currentKsPrincipal', e.target.value)}
                  required={true}
                />

                <ModRadioGroup
                  groupName={'recurringOrChange'}
                  label={i18n.titleRecurringFor}
                  options={[
                    {id: SchoolType.SEK, label: i18n.labelSek},
                    {id: SchoolType.GYM_SHORT, label: i18n.labelGymShort},
                    {id: SchoolType.GYM_LONG, label: i18n.labelGymLong},
                    {
                      id: SchoolType.DIFF,
                      label: i18n.labelRecurringForOtherProgramm
                    }
                  ]}
                  checked={getCheckedId(formState.recurringFor)}
                  onCheck={option => onFormChange('recurringFor', isCheckedYes(option.id, true))}
                  required={true}
                />
                <ModRadioGroup
                  groupName={'recurringOrChange'}
                  label={i18n.titleChangeSchool}
                  options={[
                    {id: SchoolType.SEK_TO_SHORT, label: i18n.sekToGymShort},
                    {id: SchoolType.SEK_TO_LONG, label: i18n.sekToGymLong},
                    {id: SchoolType.TO_DIFF, label: i18n.toDiffKs}
                  ]}
                  checked={getCheckedId(formState.recurringFor)}
                  onCheck={option => onFormChange('recurringFor', isCheckedYes(option.id, true))}
                  required={true}
                />
                {formState.recurringFor == SchoolType.TO_DIFF ? (
                  <ModInput
                    type={'text'}
                    label={i18n.labelDifferentProgram}
                    value={formState.kSTypeOther}
                    onChange={e => onFormChange('kSTypeOther', e.target.value)}
                    required={true}
                  />
                ) : (
                  ''
                )}

                {/* school */}
                <h3>{educationi18n.titleEducation}</h3>
                <h4>{educationi18n.subtitleSchool}</h4>
                <ModInput
                  type={'text'}
                  label={educationi18n.labelNameSchool}
                  value={formState.currentSchool}
                  onChange={e => onFormChange('currentSchool', e.target.value)}
                  required={true}
                />
                <ModInput
                  type={'text'}
                  label={educationi18n.labelNameTeacher}
                  value={formState.currentTeacher}
                  onChange={e => onFormChange('currentTeacher', e.target.value)}
                  required={true}
                />
                <ModInput
                  type={'text'}
                  label={educationi18n.labelOldSchool}
                  value={formState.oldSchool}
                  onChange={e => onFormChange('oldSchool', e.target.value)}
                />
                <ModInput
                  type={'text'}
                  label={educationi18n.labelPrefferedSubject}
                  value={formState.preferredCareer}
                  onChange={e => onFormChange('preferredCareer', e.target.value)}
                  required={true}
                />
                {/* <div>{i18n.infoNewFor}</div> */}

                <h4>{educationi18n.subtitleEducation}</h4>

                <ModEducationInputs
                  required={true}
                  labels={{
                    mainSubject: educationi18n.labelMainSubject,
                    lectures: educationi18n.labelLectures,
                    years: educationi18n.labelEducationYears,
                    addMore: educationi18n.labelAddMore
                  }}
                  options={getEducationSubjectOptions(educationi18n)}
                  education={formState.education}
                  onAddSubject={onAddSubject}
                  onSubjectChange={onEducationChange}
                />

                {/* ballett */}
                {isBallett(formState) && (
                  <>
                    <h3>{educationi18n.subtitleBallet}</h3>
                    <ModGridRow>
                      {[
                        {
                          columnWidth: 1,
                          component: (
                            <>
                              <ModInput
                                required
                                type={'text'}
                                key={educationi18n.labelEducationMethod}
                                label={educationi18n.labelEducationMethod}
                                value={formState.ballettEducationMethod}
                                onChange={e =>
                                  onFormChange('ballettEducationMethod', e.target.value)
                                }
                              />
                              <ModInput
                                required
                                type={'text'}
                                key={educationi18n.labelEducationLevel}
                                label={educationi18n.labelEducationLevel}
                                value={formState.ballettEducationLevel}
                                onChange={e =>
                                  onFormChange('ballettEducationLevel', e.target.value)
                                }
                              />
                            </>
                          )
                        }
                      ]}
                    </ModGridRow>
                  </>
                )}

                <ModRadioGroup
                  groupName={'auditionFor'}
                  label={i18n.labelAuditionFor}
                  options={[
                    {id: AuditionType.BALLET, label: i18n.labelAuditionForBallet},
                    {id: AuditionType.HIPHOP, label: i18n.labelAuditionForHipHop}
                  ]}
                  checked={getCheckedId(formState.auditionFor)}
                  onCheck={option => onFormChange('auditionFor', isCheckedYes(option.id, true))}
                  required={true}
                />
                <br />

                <p>{i18n.motivationText}</p>
                <div className="input-wrapper">
                  <input
                    className="choose"
                    id="fileUpload"
                    type="file"
                    name="file"
                    accept="application/pdf"
                    onChange={event => {
                      const response = onFileUpload(event)
                      if (response.error) {
                        setResponseState(
                          <ModFormResponse message={response.error} type={FormResponseType.Error} />
                        )
                        event.target.value = null
                      } else {
                        setResponseState(<div></div>)
                        setFile(response)
                      }
                    }}
                    required
                  />
                </div>

                {responseState}

                <ModPrimaryButton type="submit">
                  <FormattedMessage id="form.confirm" defaultMessage="Anmelden" />
                </ModPrimaryButton>
              </>
            </fieldset>
          </form>
        )}
      </ModIntlWrapper>
    </div>
  )
}
