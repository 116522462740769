import * as React from 'react'

export interface ModPrimaryButtonProps {
  readonly label?: string
  readonly children?: React.ReactNode
  readonly type: 'button' | 'submit' | 'reset'
  onClick?(e): void
}

export function ModPrimaryButton({ label, onClick, children, type }: ModPrimaryButtonProps) {
  return (
    <button type={type} className="primary-button" onClick={onClick}>
      {label || children}
    </button>
  )
}
