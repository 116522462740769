import * as React from 'react';

import { ModIcon, IconType } from 'components/common/ModIcon';
import { NavigationNodeContext } from 'components/ModApp';
import NavLink from 'components/common/NavLink';
import { FormattedMessage } from 'react-intl'

export interface ModSimplePaginationProps{
  readonly entryCount: number
  readonly pageCount: number;
}

export function ModSimplePagination({ entryCount, pageCount }: ModSimplePaginationProps) {
  const naviContext = React.useContext(NavigationNodeContext)

  let query = naviContext.location.query
  let activePage = (query && query.get('page')) ? parseInt(query.get('page')) : 1

  let prevSearchParams = new URLSearchParams(`page=${activePage - 1}`)
  let nextSearchParams = new URLSearchParams(`page=${activePage + 1}`)

  return (
    <div className="simple-pagination">
      {activePage == 1 ? (
        <ModIcon type={IconType.Chevron_Left} />
      ) : (
        <NavLink
          to={{
            pathname: naviContext.location.pathname,
            query: prevSearchParams
          }}>
          <ModIcon type={IconType.Chevron_Left} />
        </NavLink>
        )}
      
      <span><FormattedMessage id="pagination.page" defaultMessage="Seite"/> {activePage} <FormattedMessage id="pagination.from" defaultMessage="von"/> {pageCount}</span>

      {activePage == pageCount ? (
        <ModIcon type={IconType.Chevron_Right} />
      ) : (
        <NavLink
          to={{
            pathname: naviContext.location.pathname,
            query: nextSearchParams
          }}>
          <ModIcon type={IconType.Chevron_Right} />
        </NavLink>
      )}
    </div>
  );

}