import * as React from 'react'
import {Link} from 'interfaces/InterfacesEntities'
import ModAnchorTag from 'components/common/ModAnchorTag'

export interface ModCircularNewsProps {
  readonly link: Link
  readonly title: string
  readonly lead: string
  readonly description: string
}

export function ModCircularNews({link, title, lead, description}: ModCircularNewsProps) {
  return (
    <div className="top-news">
      <ModAnchorTag linkObject={link}>
        <div className="">
          <div className="top-news-text">
            <h4>{title}</h4>
            <p className="small-text news-lead">{lead}</p>
            {description && (
              <p>
                <span className="small-text">{description}</span>
              </p>
            )}
          </div>
        </div>
      </ModAnchorTag>
    </div>
  )
}
