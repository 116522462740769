
export function useFormatTime(date: Date){
  const FormattedTime = Intl.DateTimeFormat('de-CH', {
  hour: '2-digit',
  minute: '2-digit'
  }).format(date)
  return FormattedTime
}

export function useFormatDate(date: Date) {
  const FormattedDate = Intl.DateTimeFormat('de-CH', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  }).format(date)
  return FormattedDate
}

export function useFormatDay(date: Date) {
  const FormattedDay = Intl.DateTimeFormat('de-CH', {
    weekday: 'long'
  }).format(date)
  return FormattedDay
}