import { FormInputCredentials } from "./FormInterfaces"
import { SupportedLangauges } from "common/Languages"
import { calculateAge } from "./FormHelpers"

export class CredentialsApi {
  name: string
  surname: string
  street: string
  postalCode: number
  city: string
  canton: string
  phone: string
  birthDate: Date
  email: string
  age: number

  isLangGerman: boolean
  isLangFrench: boolean
  isLangItalian: boolean

  constructor(formInput: FormInputCredentials, currentLanguage: SupportedLangauges, eventDate: Date) {
    this.setLanguage = this.setLanguage.bind(this)
    this.setPersonCreds = this.setPersonCreds.bind(this)

    this.setLanguage(currentLanguage)
    this.setPersonCreds(formInput, eventDate)
  }

  // formInput extends FormInputCredentials
  setPersonCreds(formInput: FormInputCredentials, eventDate: Date) {
    this.name = formInput.name
    this.surname = formInput.surname
    this.street = formInput.street
    this.postalCode = formInput.postalCode == '' ? 0 : parseInt(formInput.postalCode)
    this.city = formInput.city
    this.canton = formInput.canton
    this.phone = formInput.phone + (formInput.emergencyPhone.length == 0 ? '' : ` ; ${formInput.emergencyPhone}`)
    this.email = formInput.email + (formInput.email2.length == 0 ? '' : ` ; ${formInput.email2}`)

    if (formInput.birthDate == '') {
      this.birthDate = new Date()
      this.age = 0
    } else {
      let date = new Date(formInput.birthDate)
      if (!this.isValidDate(date)) {
        date = this.inputToDateString(formInput.birthDate)
      }
      this.birthDate = date
      this.age = calculateAge(eventDate, this.birthDate)
    }
  }

  inputToDateString(value: string): Date {
    if (value.length < 7) {
      return null
    }

    let splitInput = value.split('.')

    // validate input
    if (!(splitInput.length == 3 &&
      ((splitInput[0].length == 1 || splitInput[0].length == 2)
        && (splitInput[1].length == 1 || splitInput[1].length == 2)
        && splitInput[2].length == 4))) {
      return null
    }

    // make date from input string
    let date = new Date()
    date.setDate(parseInt(splitInput[0]))
    date.setMonth(parseInt(splitInput[1]) - 1)
    date.setFullYear(parseInt(splitInput[2]))

    return date
  }

  isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  setLanguage(lang: SupportedLangauges) {
    switch (lang) {
      case SupportedLangauges.DE:
      case SupportedLangauges.EN:
        this.isLangGerman = true
        this.isLangFrench = false
        this.isLangItalian = false
        break
      case SupportedLangauges.FR:
        this.isLangGerman = false
        this.isLangFrench = true
        this.isLangItalian = false
        break
      case SupportedLangauges.IT:
        this.isLangGerman = false
        this.isLangFrench = false
        this.isLangItalian = true
        break
      default:
        this.isLangGerman = false
        this.isLangFrench = false
        this.isLangItalian = false
    }
  }

  public getInsertableDate() {
    return {
      timestamp: new Date(),
      name: this.name,
      surname: this.surname,
      street: this.street,
      postalCode: this.postalCode,
      city: this.city,
      canton: this.canton,
      phone: this.phone,
      birthDate: this.birthDate,
      email: this.email,
      age: this.age,

      isLangGerman: this.isLangGerman,
      isLangFrench: this.isLangFrench,
      isLangItalian: this.isLangItalian
    }
  }
}