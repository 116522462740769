import { FormInputCredentials, FormInputEducation, FormEducationCat } from './FormInterfaces'
import { EducationApi } from './ClassEducationApi'
import { CredentialsApi } from './ClassCredentialsApi'
import { SupportedLangauges } from 'common/Languages'
import { FormEducationOptions } from 'interfaces/InterfacesBlocks'

export function onFileUpload(event) {
  if (event.target.files) {
    const files = event.target.files
    if (files.length === 0) {
      return { error: 'No file selected' }
    }

    const file = files[0]

    if (!file.type.startsWith('application/pdf')) {
      return { error: 'The file must be a PDF' }
    }

    return file
  }
}

export function calculateAge(endDate: Date, birthday: Date) {
  var ageDifMs = endDate.getTime() - birthday.getTime()
  var ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function credentialsAdapter(inputs: FormInputCredentials, currentLanguage: SupportedLangauges, eventDate: Date) {
  let credentialData = new CredentialsApi(inputs, currentLanguage, eventDate)
  return credentialData.getInsertableDate()
}

export function educationAdapter(inputs: FormInputEducation, useMainSubject: boolean = false) {
  let educationData = new EducationApi()
  educationData.setSubjects(inputs.education)
  educationData.setSchool(inputs.currentSchool, inputs.currentTeacher, inputs.oldSchool)
  return educationData.getInsertableDate(useMainSubject)
}

export async function cloudinaryFileUpload(file: File) {
  const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/karmarun/upload'
  const CLOUDINARY_UPLOAD_PRESET = 'kjtxl57o'

  const formData = new FormData()
  formData.append('file', file)
  formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)

  return fetch(CLOUDINARY_URL, {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.secure_url !== '') {
        return data
      }
    })
    .catch((err) => console.error(err))
}

const idYes = 'yes'
const idNo = 'no'

export function isBallett(formState: FormInputEducation): boolean {
  for (let education of formState.education) {
    if (education.category == FormEducationCat.BALLETT) return true
  }
  return false
}

export function radioGroupOptions(formatMessageCallback) {
  return [
    { id: idYes, label: formatMessageCallback({ id: 'yes', defaultMessage: 'ja' }) },
    { id: idNo, label: formatMessageCallback({ id: 'no', defaultMessage: 'Nein' }) },
  ]
}

export function getCheckedId(id: boolean | string) {
  if (typeof id === 'boolean') return id ? idYes : idNo
  return id.toString()
}

export function isCheckedYes(id: string, multiValue: boolean = false) {
  if (multiValue) return id
  return id == idYes
}

export function getEducationSubjectOptions(educationi18n: FormEducationOptions) {
  let options = []
  if (educationi18n.optionsBallett && educationi18n.optionsBallett.length > 0) {
    for (let op of educationi18n.optionsBallett) {
      options.push({ label: op, cat: FormEducationCat.BALLETT, catLabel: educationi18n.labelOptionsBallett })
    }
  }
  if (educationi18n.optionsModern && educationi18n.optionsModern.length > 0) {
    for (let op of educationi18n.optionsModern) {
      options.push({ label: op, cat: FormEducationCat.MODERN, catLabel: educationi18n.labelOptionsModern })
    }
  }
  if (educationi18n.optionsUrban && educationi18n.optionsUrban.length > 0) {
    for (let op of educationi18n.optionsUrban) {
      options.push({ label: op, cat: FormEducationCat.URBAN, catLabel: educationi18n.labelOptionsUrban })
    }
  }
  if (educationi18n.optionsBreaking && educationi18n.optionsBreaking.length > 0) {
    for (let op of educationi18n.optionsBreaking) {
      options.push({ label: op, cat: FormEducationCat.BREAKING, catLabel: educationi18n.labelOptionsBreaking })
    }
  }
  if (educationi18n.optionsJazz && educationi18n.optionsJazz.length > 0) {
    for (let op of educationi18n.optionsJazz) {
      options.push({ label: op, cat: FormEducationCat.JAZZ, catLabel: educationi18n.labelOptionsJazz })
    }
  }
  if (educationi18n.optionsWorld && educationi18n.optionsWorld.length > 0) {
    for (let op of educationi18n.optionsWorld) {
      options.push({ label: op, cat: FormEducationCat.WORLD, catLabel: educationi18n.labelOptionsWorld })
    }
  }
  if (educationi18n.optionsMore && educationi18n.optionsMore.length > 0) {
    for (let op of educationi18n.optionsMore) {
      options.push({ label: op, cat: FormEducationCat.MORE, catLabel: educationi18n.labelOptionsMore })
    }
  }
  if (educationi18n.optionsAddition && educationi18n.optionsAddition.length > 0) {
    for (let op of educationi18n.optionsAddition) {
      options.push({ label: op, cat: FormEducationCat.ADDITON, catLabel: educationi18n.labelOptionsAddition })
    }
  }
  return options
}

export const credentialsDefaultState = {
  name: '',
  surname: '',
  street: '',
  postalCode: '',
  city: '',
  canton: '',
  phone: '',
  birthDate: '',
  email: '',
}
