/**
 *
 * @param property
 * @returns {boolean}
 */
export function hasContent(property) {
    if (typeof property === 'string') {
        return property !== ''
    }

    if (property instanceof Array) {
        return property.length > 0
    }

    return false
}

export function labelToAnchor(label: string): string {
    // replace all nonalphanumeric characters with -
    return label.replace(/\W+/g, '-').toLowerCase()
}