import * as React from 'react'
import Downshift, {
  useSelect,
  GetMenuPropsOptions,
  GetPropsCommonOptions,
  GetItemPropsOptions
} from 'downshift'
import {ModIconButton} from './ModIconButton'
import {IconType} from 'components/common/ModIcon'

export interface ModDropDownProps<Item> {
  readonly label: string
  readonly showOnlyLabel?: boolean
  readonly options: Array<Item>
  readonly required?: boolean
  onSelectionChange?(selected: Item): void
  itemToString?(item: Item): string
  itemExtraToString?(item: Item): string
}

export interface SelectedItem {
  readonly label: string
  readonly cat: number
  readonly catLabel: string
}

export function ModDropDown<Item>({
  label,
  showOnlyLabel = false,
  options,
  required = false,
  onSelectionChange,
  itemToString,
  itemExtraToString
}: ModDropDownProps<Item>) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: options,
    onSelectedItemChange: change => onSelectionChange(change.selectedItem)
  })

  const selectedItemTyped = selectedItem as unknown as SelectedItem

  return (
    <div className="dropdown">
      <label {...getLabelProps()}>{!showOnlyLabel && label}</label>
      <ModIconButton
        type="button"
        buttonProps={getToggleButtonProps()}
        iconType={isOpen ? IconType.Chevron_Up : IconType.Chevron_Down}
      />
      <ModDropDownList
        options={options}
        getMenuProps={getMenuProps}
        isOpen={isOpen}
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
        itemToString={itemToString}
        itemExtraToString={itemExtraToString}
      />
      {/* {!showOnlyLabel &&
        <div className="selected-value">{selectedItemTyped ? `${selectedItemTyped.label}` : ''}</div>
      } */}
      {showOnlyLabel ? (
        <div className="selected-value">{label}</div>
      ) : (
        <div className="selected-value">
          {selectedItemTyped ? `${selectedItemTyped.label}` : ''}
          {/* this hidden input is just to add form 'required' functionality. could be used instead of the div and style accordingly but i am no styler */}
          <input
            className="selected-hidden-input"
            required={required}
            onChange={() => {}} // to prevent console error
            value={selectedItemTyped ? `${selectedItemTyped.label}` : ''}
          />
        </div>
      )}
    </div>
  )
}

export interface ModDropDownListProps<Item> {
  readonly options: Array<Item>
  readonly getMenuProps: (
    options?: GetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => any
  readonly getItemProps: (options: GetItemPropsOptions<Item>) => any
  readonly isOpen: boolean
  readonly highlightedIndex: number
  itemToString?(item: Item): string
  itemExtraToString?(item: Item): string
}

export function ModDropDownList<Item>({
  options,
  getMenuProps,
  getItemProps,
  isOpen,
  highlightedIndex,
  itemToString,
  itemExtraToString
}: ModDropDownListProps<Item>) {
  return (
    <ul {...getMenuProps()} className={isOpen ? 'drop_down_list open' : 'drop_down_list'}>
      {isOpen &&
        options.map((option, index) => (
          <li
            className="dropdown-item"
            style={highlightedIndex === index ? {backgroundColor: '#bde4ff'} : {}}
            key={`${option}${index}`}
            {...getItemProps({item: option, index})}>
            <span>{itemToString ? itemToString(option) : option}</span>
            <span className="dropdown-item-category">
              {itemExtraToString && itemExtraToString(option)}
            </span>
          </li>
        ))}
    </ul>
  )
}
