import * as React from 'react'
import {FilterViewModel, StringFilterSelection, TimeFilterSelection} from './ClassFilterViewModel'
import {ModGridRow} from 'components/layout/ModGridRow'
import {ModTags, Tag} from '../ModTag'
import {TimeFilter, StringFilter} from './ClassFilter'
import {ModFilterDatepicker} from './ModFilterDatePicker'
import {ModDropDown} from '../ModDropDown'

export enum FilterType {
  TimePicker,
  String
}
export interface TimePeriod {
  startDate: Date
  endDate: Date
}
export interface FilterOption {
  id: string
  label: string
}
export interface IFilter {
  id: string
  label: string
}

export interface TimePicker extends IFilter {
  type: FilterType.TimePicker
}
export interface Filter extends IFilter {
  type: FilterType.String
  options: FilterOption[]
}

export interface ModFilterProps {
  readonly filters: Array<Filter | TimePicker>
  onFilterChange?(selection: Array<StringFilterSelection | TimeFilterSelection>): void // array< {key, string[] | period} >
  readonly currentlanguage: string
  // object on which filter options should change -> for language switch
  // works with currentLanguage for Professionals templates but not for events
  // therefore this hack with type any.
  readonly filterDataChangeIndicator: any
}

export function ModFilter({
  filters,
  onFilterChange,
  currentlanguage,
  filterDataChangeIndicator
}: ModFilterProps) {
  const [filterStateModel, setFilterStateModel] = React.useState(new FilterViewModel(filters))

  function onSelectionChange(filterGroupKey: string, selected: FilterOption | TimePeriod) {
    filterStateModel.updateActiveFilter(filterGroupKey, selected)
    setFilterStateModel(filterStateModel.clone())
    if (onFilterChange) onFilterChange(filterStateModel.getSimpleSelection())
  }

  function onDeleteSelection(filterGroupKey: string, selectionId: string) {
    filterStateModel.deleteActiveFilter(filterGroupKey, selectionId)
    setFilterStateModel(filterStateModel.clone())
    if (onFilterChange) onFilterChange(filterStateModel.getSimpleSelection())
  }

  function timePeriodToTag(selection: TimePeriod): Tag[] {
    const FormattedDate = Intl.DateTimeFormat('de-CH', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    })
    const selectionString = `${
      selection.startDate && FormattedDate.format(selection.startDate)
    } - ${selection.endDate && FormattedDate.format(selection.endDate)}`

    return [{id: 'timepicker', label: selectionString}]
  }

  React.useEffect(() => {
    const newFilters = new FilterViewModel(filters)
    setFilterStateModel(newFilters)
  }, [filterDataChangeIndicator])

  React.useEffect(() => {
    filterStateModel.getFilters().map((filter: StringFilter | TimeFilter) => {
      if (filter) {
        filterStateModel.deleteAllActiveFilter(filter.key)
        if (onFilterChange) onFilterChange(filterStateModel.getSimpleSelection())
      }
    })
  }, [currentlanguage])

  return (
    <div className="filter">
      <ModGridRow>
        {filterStateModel.getFilters().map((filter: StringFilter | TimeFilter) => {
          return {
            columnWidth: 1,
            component:
              filter.type == FilterType.String ? (
                <div key={filter.label} className="filter-options">
                  <ModDropDown
                    label={filter.label}
                    showOnlyLabel={true}
                    options={filter.options}
                    onSelectionChange={selected => onSelectionChange(filter.key, selected)}
                    itemToString={item => item.label}
                  />
                  {filter.value && (
                    <ModTags
                      items={filter.value}
                      onDeleteItem={itemId => onDeleteSelection(filter.key, itemId)}
                    />
                  )}
                </div>
              ) : (
                <div key={filter.label} className="filter-options">
                  <ModFilterDatepicker
                    label={filter.label}
                    onSelectionChange={selected => onSelectionChange(filter.key, selected)}
                  />
                  {filter.value && (
                    <ModTags
                      items={timePeriodToTag(filter.value)}
                      onDeleteItem={itemId => onDeleteSelection(filter.key, itemId)}
                    />
                  )}
                </div>
              )
          }
        })}
      </ModGridRow>
    </div>
  )
}
