import * as React from 'react'

import ModBlockRichText from './ModBlockRichText'
import ModBlockImageText from './ModBlockImageText'
import ModBlockSpacer from './ModBlockSpacer'
import ModBlockImage from './ModBlockImage'
import ModBlockSlider from './ModBlockSlider'
import ModEditBlock from '../common/ModEditBlock'
import RootState from '../../vo/RootState'
import {tags} from '../../vo/Tags'
import {ModBlockAccordion} from './ModBlockAccordion'
import {ModAnchors} from '../common/ModAnchors'
import {ModBlockQuicklinks} from './ModBlockQuicklilnks'
import {ModBlockAdvertisment} from './ModBlockAdvertisment'
import {ModBlockCourseSignUp} from './forms/ModBlockFormCourseSignUp'
import {ModBlockFormSummerdance} from './forms/ModBlockFormSummerdance'
import {ModBlockFormTalentscout} from './forms/ModBlockFormTalentscout'
import {ModBlockLink} from './ModBlockLink'
import {ModBlockNewsletter} from './ModBlockNewsletter'
import ModBlockTitle from './ModBlockTitle'
import {ModBlockFormTalentscoutRecurring} from './forms/ModBlockFormTalentscoutRecurring'
import {ModBlockCookieDeclaration} from 'components/common/ModBlockCookieDeclaration'

export interface BlocksRendererProps {
  rootState: RootState
  blockListData: any[]
  currentLanguage?: string
}

export function BlocksRenderer({
  blockListData: templateData,
  rootState,
  currentLanguage
}: BlocksRendererProps) {
  const blockList = templateData.map((_item, index) => {
    const typeKey = Object.keys(_item)[0]
    const content = _item[typeKey]
    return {typeKey, content}
  })

  let html = blockList.reduce((itemList, item, index) => {
    const {typeKey, content} = item

    let blockMarkup = null

    switch (typeKey) {
      case 'blockRichText':
        blockMarkup = <ModBlockRichText key={`${content._id}${index}`} content={content.block} />
        break
      case 'blockImageText':
        blockMarkup = <ModBlockImageText key={`${content._id}${index}`} content={content.block} />
        break
      case 'blockSpacer':
        blockMarkup = <ModBlockSpacer key={`${content._id}${index}`} content={content.block} />
        break
      case tags.blockSlider:
        if (content.block.list.length === 1) {
          // If there is just one item in the list, display a simple Image Block
          blockMarkup = (
            <ModBlockImage
              key={`${content._id}${index}`}
              content={{image: content.block.list[0].image, _i18nCL: content.block.list[0]._i18nCL}}
            />
          )
        } else {
          blockMarkup = (
            <ModBlockSlider key={`${content._id}${index}`} content={content.block} index={index} />
          )
        }
        break
      case tags.blockAccordion:
        blockMarkup = <ModBlockAccordion key={`${content._id}${index}`} content={content.block} />
        break
      case tags.blockTitle:
        blockMarkup = <ModBlockTitle key={`${content._id}${index}`} content={content.block} />
        break
      // case tags.blockAnchors:
      //   blockMarkup = <ModBlockAnchors key={`${content._id}${index}`} content={content.block} />
      //   break
      case tags.blockQuicklinks:
        blockMarkup = <ModBlockQuicklinks key={`${content._id}${index}`} content={content.block} />
        break
      case tags.blockAdvertisment:
        blockMarkup = (
          <ModBlockAdvertisment key={`${content._id}${index}`} content={content.block} />
        )
        break
      case tags.blockCourseSignUp:
        blockMarkup = (
          <ModBlockCourseSignUp key={`${content._id}${index}`} content={content.block} />
        )
        break
      case tags.blockFormSummerdance:
        blockMarkup = (
          <ModBlockFormSummerdance key={`${content._id}${index}`} content={content.block} />
        )
        break
      case tags.blockFormTalentscout:
        blockMarkup = (
          <ModBlockFormTalentscout key={`${content._id}${index}`} content={content.block} />
        )
        break
      case tags.blockFormTalentscoutRecurring:
        blockMarkup = (
          <ModBlockFormTalentscoutRecurring
            key={`${content._id}${index}`}
            content={content.block}
          />
        )
        break
      case tags.blockLink:
        blockMarkup = <ModBlockLink key={`${content._id}${index}`} content={content.block} />
        break
      case tags.blockNewsletter:
        blockMarkup = <ModBlockNewsletter key={`${content._id}${index}`} content={content.block} />
        break
      case 'blockCookieDisclaimer':
        blockMarkup = (
          <ModBlockCookieDeclaration
            key={index}
            content={content}
            currentLanguage={currentLanguage}
          />
        )
        break
      default:
        blockMarkup = null
    }

    if (rootState.editMode && blockMarkup) {
      itemList.push(
        <ModEditBlock
          key={'_' + index}
          entityObject={content.block}
          title={typeKey}
          rootState={rootState}
        />
      )
    }
    itemList.push(blockMarkup)
    return itemList
  }, [])

  return <div className="template-block-list">{html}</div>
}
