import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { Website } from '../interfaces/Interfaces'
import { CookieContext } from 'common/CookieContext'

export interface ModCookiePolicyProps {
  websiteSettings: Website
}

export default function ModCookiePolicy({ websiteSettings }: ModCookiePolicyProps) {
  const cookieContext = React.useContext(CookieContext)

  if (!cookieContext || cookieContext.cookies.cookie_consent_dismissed === true) {
    return null
  }

  return (
    <div className="cookie-policy">
      <p>{websiteSettings._i18nCL.cookiePolicy}</p>

      <button
        onClick={() => {
          cookieContext.setCookie('cookie_consent_dismissed', true)
        }}
      >
        <p>
          <FormattedMessage id="cookiePolicy.ok" defaultMessage="I agree" />
        </p>
      </button>
    </div>
  )
}
