import * as isoFetch from 'isomorphic-fetch'
import { SupportedLangauges } from 'common/Languages'
import { credentialsAdapter, educationAdapter } from './FormHelpers'
import { FormInputSummerdanceRegistry } from './ModBlockFormSummerdance'
import { FormSummerdanceConstants } from 'interfaces/InterfacesBlocks'
import { SummerdanceRegistry } from 'interfaces/InterfacesEntities'


export async function isoPostSummerdanceRegistry(inputs: FormInputSummerdanceRegistry, currentLanguage: SupportedLangauges, dataConstants: FormSummerdanceConstants) {
  return await isoFetch('/api/summerdance', {
    method: 'POST',
    headers: {
      Accept: 'application/json, application/xml, text/play, text/html, *.*',
      'Content-Type': 'application/json',
      'Current-Languag': currentLanguage
    },
    body: JSON.stringify(formValuesToInsertableData(inputs, currentLanguage, dataConstants))
  })
}

function formValuesToInsertableData(inputs: FormInputSummerdanceRegistry, currentLanguage: SupportedLangauges, dataConstants: FormSummerdanceConstants): SummerdanceRegistry {
  let summerDance = summerdanceRegistryAdapter(inputs, dataConstants)
  let credentials = credentialsAdapter(inputs, currentLanguage, new Date(dataConstants.eventDate)) // TODO alter berechnen mit constants!!
  let education = educationAdapter(inputs)

  return { ...credentials, ...summerDance, ...education }
}

function summerdanceRegistryAdapter(inputs: FormInputSummerdanceRegistry, dataConstants: FormSummerdanceConstants) {
  function fees() {
    let courseFees = 0
    let travelFees = 0

    if (inputs.courseFees == 'inclHosting') courseFees = dataConstants.costsInclHosting
    else if (inputs.courseFees == 'inclFood') courseFees = dataConstants.costsInclFood
    if (inputs.travel == 'CarFromSolothurn') travelFees = dataConstants.costsCarSolothurn
    else if (inputs.travel == 'CarFromZuerich') travelFees = dataConstants.costsCarZurich

    return courseFees + travelFees
  }

  return {
    eventYear: new Date(dataConstants.eventDate).getFullYear(),
    yearAlreadyAttended: inputs.hasAlreadyAttended ? inputs.yearAlreadyAttended : 'nein',
    healthInsurance: inputs.healthInsurance,
    insurancePolice: inputs.insurancePolice,

    inclCosts: inputs.courseFees == 'inclHosting',
    exclCosts: inputs.courseFees == 'inclFood',

    carZurich: inputs.travel == 'CarFromZuerich',
    carSolothurn: inputs.travel == 'CarFromSolothurn',
    carIndividual: inputs.travel == 'Individual',

    totalFees: fees(),

    vegetarian: inputs.food == 'vegetarian',
    vegan: inputs.food == 'vegan',

    preProf: inputs.isProfEducation ? `ja, ${inputs.profEducation}` : 'nein',
    examPrep: '',
    pointDance: inputs.isPointDance,

    comments: inputs.medicalComments + (inputs.comments.length > 0 ? ` ; ${inputs.comments}` : '')
  }
}
