import * as React from 'react'
import { BlockSpacer } from '../../interfaces/InterfacesBlocks'

export interface ModBlockSpacerProps {
  content: BlockSpacer
}

export default class ModBlockSpacer extends React.Component<ModBlockSpacerProps, {}> {
  render() {
    const { content } = this.props

    const divStyle = {
      marginBottom: content.value + 'px'
    }
    return <div className="block-spacer" style={divStyle} />
  }
}
