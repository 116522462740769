import * as React from 'react'

import {Footer} from "../interfaces/Interfaces";
import ModAnchorTag from "./common/ModAnchorTag";
import ModImgTag from "./common/ModImgTag";
import ModRichText from "./common/ModRichText";
import { ModGridRow } from './layout/ModGridRow';
import { ModIcon, IconType } from './common/ModIcon';

export interface ModFooterProps {
  content: Footer,
  currentLanguage: string
}

export default class ModFooter extends React.Component<ModFooterProps, {}> {

  render() {
    const {content, currentLanguage} = this.props

    if (!content || !content._i18nCL) {
      return null
    }

    // const socialMedia = content.socialMedia.reduce((prevItem, item, index) => {
    //   if (item.hasOwnProperty("image")) {
    //     prevItem.push(
    //       <ModAnchorTag key={index} linkObject={item.link}>
    //         <ModImgTag width={40} height={40} imgObject={item.image} transformation={'c_crop,r_max'}
    //                    fileFormat={"png"}/>
    //       </ModAnchorTag>
    //     )
    //   }
    //   return prevItem
    // }, [])

    return (
      <footer id="footer">
        <ModGridRow>
            {content._i18nCL.links.map((link, index) => {
              return {
                columnWidth: 1,
                component: (
                 <div key={`${link.label}${index}`} className="footer-col"> <ModAnchorTag linkObject={link.link}> {link.label} <ModIcon type={IconType.Arrow_Right}/> </ModAnchorTag></div>
                )
              };
            })}
          </ModGridRow>
      </footer>
    )
  }
}
