import * as React from "react";

export enum IconSize {
  Smaller = "size_smaller",
  Equal = "size_equal",
  Larger = "size_larger",
  Double = "size_double"
}

export enum IconType {
  Chevron_Right,
  Chevron_Left,
  Chevron_Up,
  Chevron_Down,
  Arrow_Right,
  Close_Filled,
  Close,
  Download,
  Open,
  Hamburger,
  Search,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  Calendar
}

export interface ModIconProps {
  readonly type: IconType;
  readonly size?: IconSize;
  readonly className?: string;
}

export function ModIcon({ type, size, className }: ModIconProps) {
  function icon() {
    switch (type) {
      case IconType.Chevron_Right:
        return <ChevronRightIcon />;
      case IconType.Chevron_Left:
        return <ChevronLeftIcon />;
      case IconType.Chevron_Up:
        return <ChevronUpIcon />;
      case IconType.Chevron_Down:
        return <ChevronDownIcon />;
      case IconType.Arrow_Right:
        return <ArrowRightIcon />;
      case IconType.Close_Filled:
        return <CloseFilledIcon />;
      case IconType.Facebook:
        return <FacebookIcon />;
      case IconType.Twitter:
        return <TwitterIcon />;
      case IconType.Instagram:
        return <InstagramIcon />;
        case IconType.Linkedin:
          return <LinkedinIcon />;
      case IconType.Close:
        return <CloseIcon />;
      case IconType.Download:
        return <DownloadIcon />;
      case IconType.Open:
        return <OpenIcon />;
      case IconType.Hamburger:
        return <HamburgerIcon />;
      case IconType.Search:
        return <SearchIcon />;
      case IconType.Calendar:
        return <CalendarIcon />
    }
  }

  return (
    <div className={`icon ${size ? size : ""} ${className ? className : ""}`}>
      {icon()}
    </div>
  );
}

export function ChevronRightIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.122 18.716">
      <g transform="rotate(-90 9.358 9.358)">
        <path d="M16.509 0L9.358 6.878 2.207 0 0 2.122l9.358 9 9.358-9z" />
      </g>
    </svg>
  );
}

export function ChevronLeftIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.122 18.716">
      <g transform="rotate(90 9.358 9.358)">
        <path d="M16.509 0L9.358 6.878 2.207 0 0 2.122l9.358 9 9.358-9z" transform="translate(0 7.475)" />
      </g>
    </svg>
  );
}

export function ChevronUpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g transform="rotate(180 10 10)">
        <path
          d="M7.94 0L4.5 3.44 1.06 0 0 1.06l4.5 4.5L9 1.06z"
          transform="translate(5.5 7.475)"
        />
      </g>
    </svg>
  );
}

export function ChevronDownIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path d="M12.44 6.44L9 9.88 5.56 6.44 4.5 7.5 9 12l4.5-4.5z" />
    </svg>
  );
}

export function ArrowRightIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M12 0L9.818 2.182l8.26 8.26H0v3.117h18.078l-8.26 8.26L12 24l12-12z"
        data-name="Pfad 10"
      />
    </svg>
  );
}

export function CloseFilledIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <g transform="translate(-1 -1)">
        <path d="M7 1a6 6 0 1 0 6 6 6 6 0 0 0-6-6zm3 8.153L9.153 10 7 7.847 4.847 10 4 9.153 6.153 7 4 4.847 4.847 4 7 6.153 9.153 4l.847.847L7.847 7z" />
      </g>
    </svg>
  );
}

export function TwitterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.7 16">
      <path d="M17.675 3.988c.013.175.013.35.013.525A11.409 11.409 0 0 1 6.2 16 11.41 11.41 0 0 1 0 14.188a8.353 8.353 0 0 0 .975.05 8.086 8.086 0 0 0 5.013-1.725 4.045 4.045 0 0 1-3.775-2.8 5.092 5.092 0 0 0 .763.062 4.27 4.27 0 0 0 1.062-.137A4.038 4.038 0 0 1 .8 5.675v-.05a4.066 4.066 0 0 0 1.825.512 4.044 4.044 0 0 1-1.25-5.4A11.476 11.476 0 0 0 9.7 4.963a4.558 4.558 0 0 1-.1-.925 4.041 4.041 0 0 1 6.988-2.763A7.949 7.949 0 0 0 19.151.3a4.027 4.027 0 0 1-1.775 2.225 8.094 8.094 0 0 0 2.325-.625 8.679 8.679 0 0 1-2.025 2.087z" />
    </svg>
  );
}

export function FacebookIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.639 16.542">
      <path
        id="FB_Icon"
        d="M85.606 16.542V9.006h2.573l.368-2.941h-2.941V4.227c0-.827.276-1.47 1.47-1.47h1.562V.092c-.368 0-1.287-.092-2.3-.092a3.548 3.548 0 0 0-3.768 3.86v2.205H80v2.941h2.573v7.536z"
        transform="translate(-80)"
      />
    </svg>
  );
}

export function InstagramIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M10 1.778a30.662 30.662 0 0 1 4 .111 5.154 5.154 0 0 1 1.889.333 3.9 3.9 0 0 1 1.889 1.889A5.153 5.153 0 0 1 18.111 6c0 1 .111 1.333.111 4a30.662 30.662 0 0 1-.111 4 5.154 5.154 0 0 1-.333 1.889 3.9 3.9 0 0 1-1.889 1.889 5.153 5.153 0 0 1-1.889.333c-1 0-1.333.111-4 .111a30.662 30.662 0 0 1-4-.111 5.154 5.154 0 0 1-1.889-.333 3.9 3.9 0 0 1-1.889-1.889A5.154 5.154 0 0 1 1.889 14c0-1-.111-1.333-.111-4a30.662 30.662 0 0 1 .111-4 5.154 5.154 0 0 1 .333-1.889A3.991 3.991 0 0 1 3 3a1.879 1.879 0 0 1 1.111-.778A5.154 5.154 0 0 1 6 1.889a30.662 30.662 0 0 1 4-.111M10 0a32.83 32.83 0 0 0-4.111.111 6.86 6.86 0 0 0-2.445.445 4.35 4.35 0 0 0-1.777 1.111A4.35 4.35 0 0 0 .556 3.444a5.063 5.063 0 0 0-.445 2.445A32.83 32.83 0 0 0 0 10a32.83 32.83 0 0 0 .111 4.111 6.86 6.86 0 0 0 .444 2.444 4.35 4.35 0 0 0 1.111 1.778 4.35 4.35 0 0 0 1.778 1.111 6.86 6.86 0 0 0 2.444.444A32.83 32.83 0 0 0 10 20a32.83 32.83 0 0 0 4.111-.111 6.86 6.86 0 0 0 2.444-.444 4.662 4.662 0 0 0 2.889-2.889 6.86 6.86 0 0 0 .444-2.444C19.889 13 20 12.667 20 10a32.83 32.83 0 0 0-.111-4.111 6.86 6.86 0 0 0-.444-2.444 4.35 4.35 0 0 0-1.111-1.778A4.35 4.35 0 0 0 16.556.556a6.86 6.86 0 0 0-2.445-.445A32.83 32.83 0 0 0 10 0m0 4.889A5.029 5.029 0 0 0 4.889 10 5.111 5.111 0 1 0 10 4.889m0 8.444A3.274 3.274 0 0 1 6.667 10 3.274 3.274 0 0 1 10 6.667 3.274 3.274 0 0 1 13.333 10 3.274 3.274 0 0 1 10 13.333m5.333-9.889a1.222 1.222 0 1 0 1.222 1.222 1.233 1.233 0 0 0-1.222-1.222" />
    </svg>
  );
}

export function LinkedinIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" >
      <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" />
    </svg>
  )
}

export function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 20.506">
      <g transform="translate(0 1.753)">
        <path d="M0 0h26v3H0z" transform="rotate(-45 21.45 5)" />
        <path d="M0 0h26v3H0z" transform="rotate(45 4.55 5)" />
      </g>
    </svg>
  );
}

export function OpenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
      <g transform="translate(-8 -10)">
        <path d="M11.333 10v1.333h4.39L8 19.057l.943.943 7.723-7.723v4.39H18V10z" />
      </g>
    </svg>
  );
}

export function DownloadIcon() {
  return (
    <svg className="download-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.142 14.142">
      <g id="ic-call-made-48px" transform="rotate(135 11.678 9.807)">
        <path d="M11.333 10v1.333h4.39L8 19.057l.943.943 7.723-7.723v4.39H18V10z" />
      </g>
    </svg>
  );
}

export function HamburgerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
      <g transform="translate(-17 -18)">
        <path d="M0 0h26v3H0z" transform="translate(17 18)" />
        <path d="M0 0h26v3H0z" transform="translate(17 25)" />
        <path d="M0 0h26v3H0z" transform="translate(17 32)" />
      </g>
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M14.294 12.579h-.909l-.314-.314a7.38 7.38 0 1 0-.806.812l.314.314v.9L18.3 20l1.7-1.7zm-6.861 0a5.146 5.146 0 1 1 5.146-5.146 5.146 5.146 0 0 1-5.146 5.146z" />
    </svg>
  );
}

export function CalendarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"/>
    </svg>
  );
}