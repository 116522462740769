import * as React from 'react'

import {EventsTemplate} from 'interfaces/Interfaces'
import {Event} from 'interfaces/InterfacesEntities'
import {BlocksRenderer} from 'components/blocks/BlocksRenderer'
import {PageTemplateProps} from './IPageTemplateProps'
import {ModGridRow} from 'components/layout/ModGridRow'
import ModBreadCrumb from 'components/ModBreadCrumb'
import {ModDateTime} from 'components/ui/ModDateTime'
import {ModFilter, FilterType} from 'components/ui/filter/ModFilter'
import {ModPageHeader} from './DetailPageTemplate'
import {FormattedMessage} from 'react-intl'
import ModRichText from 'components/common/ModRichText'
import ModIntlWrapper from 'components/common/utils/ModIntlWrapper'

export interface EventsPageTemplateProps extends PageTemplateProps, EventsTemplate {}

export function EventsPageTemplate({
  navigationTree,
  currentLanguage,
  rootState,
  venues,
  types,
  events,
  pathname,
  header,
  sidePanelContent: sidePanelBlockListData
}: EventsPageTemplateProps) {
  if (events == undefined || events == null) {
    // console.warn('events are undefined or null')
    return null
  }

  const filterGroupIds = {
    PeriodId: 'periodFilterGroup',
    CityId: 'cityFilterGroup',
    TypeId: 'typeFilterGroup'
  }

  const now = new Date()

  const eventsByDate: {date: Date; event: Event}[] = events
    .reduce((allEvents, event) => {
      for (let date of event.dates) {
        const eventDate = new Date(date)
        if (eventDate.getTime() - now.getTime() > 0) {
          allEvents.push({date: new Date(date), event: event})
        }
      }
      return allEvents
    }, [])
    .sort((a, b) => {
      return a.date.getTime() - b.date.getTime()
    })

  const [filteredEvents, setFilteredEvents] = React.useState(eventsByDate)

  React.useEffect(() => {
    setFilteredEvents(eventsByDate)
  }, [events])

  function onFilterChange(filters: {key: string; selection: any}[]) {
    let filtered = eventsByDate

    for (let fil of filters) {
      filtered = filtered.filter(eventWithDate => {
        if (fil.key == filterGroupIds.CityId) {
          for (let selected of fil.selection) {
            if (eventWithDate.event.venue.adress.city == selected.id) {
              return true
            }
          }
          return false
        }

        if (fil.key == filterGroupIds.TypeId) {
          for (let selected of fil.selection) {
            if (
              eventWithDate.event.type &&
              eventWithDate.event.type._i18nCL &&
              eventWithDate.event.type._i18nCL.name == selected.id
            ) {
              return true
            }
          }
          return false
        }

        if (fil.key == filterGroupIds.PeriodId) {
          if (
            fil.selection.endDate.getTime() - eventWithDate.date.getTime() <
              fil.selection.endDate.getTime() - fil.selection.startDate.getTime() &&
            eventWithDate.date.getTime() < fil.selection.endDate.getTime()
          ) {
            return true
          }
          return false
        }
      })
    }
    setFilteredEvents(filtered)
  }

  function resetFilters() {
    setFilteredEvents(eventsByDate)
  }

  function sortAlphabetically(arrayToSort) {
    return arrayToSort.sort((a, b) => a.label.localeCompare(b.label))
  }

  // get cities (once) from venue for city filter options and sort alphabetically
  const cityOptions = venues.reduce((cities, currentVenue) => {
    for (let cityOption of cities) {
      if (cityOption.label == currentVenue.adress.city) return cities
    }
    cities.push({id: currentVenue.adress.city, label: currentVenue.adress.city})
    return cities
  }, [])
  sortAlphabetically(cityOptions)

  // get event types for type filter options and sort alphabetically
  const eventTypeOptions = types
    .filter(type => {
      if (type._i18nCL == undefined) {
        console.log('i18n undefined for filter', type)
      }
      return type._i18nCL != undefined
    })
    .map(type => {
      return {id: type._i18nCL.name, label: type._i18nCL.name}
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  sortAlphabetically(eventTypeOptions)

  return (
    <ModIntlWrapper>
      {({formatMessage}) => (
        <ModGridRow>
          {[
            {
              columnWidth: 2,
              component: (
                <div className="main-content" key="content">
                  <ModPageHeader title={header._i18nCL.title} lead={header._i18nCL.lead} />

                  <p className="small-text filter-title">
                    <FormattedMessage
                      id={'filter.filterTitleCalendar'}
                      defaultMessage={'Filter setzen'}
                    />
                  </p>
                  <ModFilter
                    onFilterChange={onFilterChange}
                    currentlanguage={currentLanguage}
                    filterDataChangeIndicator={types}
                    filters={[
                      {
                        id: filterGroupIds.PeriodId,
                        type: FilterType.TimePicker,
                        label: formatMessage({id: 'filter.period', defaultMessage: 'Zeitraum'})
                      },
                      {
                        id: filterGroupIds.CityId,
                        type: FilterType.String,
                        label: formatMessage({id: 'filter.place', defaultMessage: 'Ort'}),
                        options: cityOptions
                      },
                      {
                        id: filterGroupIds.TypeId,
                        type: FilterType.String,
                        label: formatMessage({id: 'filter.type', defaultMessage: 'Art'}),
                        options: eventTypeOptions
                      }
                    ]}
                  />

                  {filteredEvents.length > 0 ? (
                    restructureEventsForRendering(filteredEvents).map((events, index) => {
                      return (
                        <div className="events-by-date" key={`${currentLanguage}${index}`}>
                          <div className="events-date">
                            <ModDateTime date={events.overallDate} hideTime={true} />
                          </div>

                          {events.events.map((eventAndDate, index) => {
                            if (!eventAndDate.event._i18nCL) {
                              // console.warn('event is missing language');
                              return null
                            }
                            if (eventAndDate.event.venue && !eventAndDate.event.venue._i18nCL) {
                              // console.warn(`venue for event ${eventAndDate.event._i18nCL.name} is missing language`);
                              return null
                            }
                            if (eventAndDate.event.type && !eventAndDate.event.type._i18nCL) {
                              // console.warn(`type for event ${eventAndDate.event._i18nCL.name} is missing language`);
                              return null
                            }
                            return (
                              <div className="events" key={`event${currentLanguage}${index}`}>
                                <ModDateTime
                                  date={eventAndDate.date}
                                  showOnlyTime={true}
                                  city={eventAndDate.event.venue.adress.city}
                                />{' '}
                                <div className="event">
                                  <span className="event-name">
                                    {eventAndDate.event._i18nCL.name}
                                  </span>{' '}
                                  | <ModRichText richText={eventAndDate.event._i18nCL.text} />{' '}
                                  {eventAndDate.event.venue._i18nCL.link ? (
                                    <a
                                      href={eventAndDate.event.venue._i18nCL.link}
                                      target="_blank"
                                      className={'event-venue-link'}>
                                      <span className={'event-venue'}>
                                        {eventAndDate.event.venue._i18nCL.name}
                                      </span>
                                      {', '}
                                      <span className="event-place">
                                        {eventAndDate.event.venue.adress.city}{' '}
                                      </span>
                                    </a>
                                  ) : (
                                    <>
                                      <span className={'event-venue'}>
                                        {eventAndDate.event.venue._i18nCL.name}
                                      </span>
                                      {', '}
                                      <span className="event-place">
                                        {eventAndDate.event.venue.adress.city}{' '}
                                      </span>
                                    </>
                                  )}{' '}
                                  {eventAndDate.event.type &&
                                    (eventAndDate.event.type._i18nCL.link ? (
                                      <a
                                        href={eventAndDate.event.type._i18nCL.link}
                                        target="_blank"
                                        className={'event-type  event-type-link'}>
                                        {eventAndDate.event.type._i18nCL.name}
                                      </a>
                                    ) : (
                                      <span className="event-type ">
                                        {eventAndDate.event.type._i18nCL.name}
                                      </span>
                                    ))}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })
                  ) : (
                    <div className="no-event">
                      <FormattedMessage id="noEntries" defaultMessage="keine Einträge" />
                    </div>
                  )}

                  {/* no pagination because not offered. and not easy to use, because filtering is only in frontend <ModSimplePagination entryCount={entryCount} pageCount={pageCount}/> */}

                  <ModBreadCrumb navigationTree={navigationTree} pathName={pathname} />
                </div>
              )
            },

            {
              columnWidth: 1,
              component: (
                <BlocksRenderer
                  key={'side'}
                  rootState={rootState}
                  blockListData={sidePanelBlockListData}
                />
              )
            }
          ]}
        </ModGridRow>
      )}
    </ModIntlWrapper>
  )
}

type Dates = {overallDate: Date; events: FilteredEvent[]}[]
type FilteredEvent = {date: Date; event: Event}

function restructureEventsForRendering(filteredEvents: FilteredEvent[]): Dates {
  const restructeredEvents = filteredEvents.reduce((allCheckedEvents, newEvent: FilteredEvent) => {
    // add this new event to an existing event date
    for (let checkedEvent of allCheckedEvents) {
      if (
        checkedEvent.overallDate.getFullYear() == newEvent.date.getFullYear() &&
        checkedEvent.overallDate.getMonth() == newEvent.date.getMonth() &&
        checkedEvent.overallDate.getDate() == newEvent.date.getDate()
      ) {
        checkedEvent.events.push(newEvent)
        return allCheckedEvents
      }
    }
    // or add it as a new event date
    allCheckedEvents.push({overallDate: newEvent.date, events: [newEvent]})
    return allCheckedEvents
  }, [])

  // sort events for same day alphabetically
  for (let day of restructeredEvents) {
    if (day.events && day.events.length > 0) {
      day.events.sort((a, b) => a.event.venue.adress.city.localeCompare(b.event.venue.adress.city))
    }
  }

  return restructeredEvents
}
