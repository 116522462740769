import * as React from "react";
import { ModGridRow } from "./layout/ModGridRow";
import { ModNavigation } from "./ModNavigation";
import { PageTemplateProps } from "./templates/IPageTemplateProps";
import { BlocksRenderer } from "./blocks/BlocksRenderer";

export interface Mod404Props extends PageTemplateProps {
  templateData: any[];
}

export default class Mod404 extends React.Component<Mod404Props, {}> {
  render() {
    const {
      navigationTree,
      currentLanguage,
      rootState,
      pathname,
      templateData
    } = this.props;

    return (
      <main>
        <ModGridRow>
          {[
            // {
            //   columnWidth: 1,
            //   component: (
            //     <ModNavigation
            //       key='navi'
            //       navigationTree={navigationTree}
            //       currentLanguage={currentLanguage}
            //       rootState={rootState}
            //       pathname={pathname}
            //     />
            //   )
            // },

            {
              columnWidth: 2,
              component: (
                <BlocksRenderer
                  key='content'
                    blockListData={templateData}
                    rootState={rootState}
                  />
              )
            },

            { columnWidth: 1, component: <div key='empty'></div> }
          ]}
        </ModGridRow>
      </main>
    );
  }
}
