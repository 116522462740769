import * as React from 'react'
import {AccordeonItem, BlockAccordion} from 'interfaces/InterfacesBlocks'
import {IconType} from 'components/common/ModIcon'
import {ModIconButton} from 'components/ui/ModIconButton'
import {RichText} from 'interfaces/InterfacesEntities'
import ModRichText from 'components/common/ModRichText'
import {isBlockActiveNow} from 'common/CmsUtils'

export interface ModBlockAccordionProps {
  content: BlockAccordion
}

export function ModBlockAccordion({content}: ModBlockAccordionProps) {
  if (!content.items) {
    // console.warn('content.items null in ModBlockAccordion')
    return null
  }

  if (!isBlockActiveNow(content)) {
    return null
  }

  function isItemActive(item: AccordeonItem): boolean {
    const now = new Date().getTime()
    if (item.expiryDate && now > new Date(item.expiryDate).getTime()) {
      return false
    }

    return true
  }

  return (
    <div className="block-accordion">
      {content.items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item._i18nCL && isItemActive(item) ? (
              <AccordionItem key={index} title={item._i18nCL.title} detail={item._i18nCL.detail} />
            ) : null}
          </React.Fragment>
        )
      })}
    </div>
  )
}

/**
 *
 * Accordion Item
 */
interface AccordionItemProps {
  readonly title: string
  readonly detail: RichText
}

function AccordionItem({title, detail}: AccordionItemProps) {
  const [isCollapsed, setCollapsed] = React.useState(true)

  return (
    <>
      <div className={`accordion-header `} onClick={e => setCollapsed(!isCollapsed)}>
        <ModIconButton
          type="button"
          className={isCollapsed ? 'accordion_collapsed' : 'accordion_expand'}
          iconType={IconType.Chevron_Right}
        />
        <span className="accordion-title">{title}</span>
      </div>
      {!isCollapsed && <div className="accordion-detail">{<ModRichText richText={detail} />}</div>}
    </>
  )
}
