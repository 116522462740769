import * as React from "react";
import { BlockLink } from "interfaces/InterfacesBlocks";
import { ModIcon, IconType } from "components/common/ModIcon";
import ModAnchorTag from "components/common/ModAnchorTag";

export interface ModBlockLinkProps {
  content: BlockLink;
}

export function ModBlockLink({ content }: ModBlockLinkProps) {

  if (!content._i18nCL) {
    return null
  }

  const isDownloadLink = content.link.document != undefined;

  return (
    <div className="block-links">
      <ModIcon type={isDownloadLink ? IconType.Download : IconType.Open} />
      <ModAnchorTag linkObject={content.link}>
        {content._i18nCL.label}
      </ModAnchorTag>
    </div>
  );
}