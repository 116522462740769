import * as React from 'react'
import Mod404 from './Mod404'
import ModEditBlock from './common/ModEditBlock'
import ModTemplateBlockList from './templates/ModTemplateBlockList'
import RootState, {LoadingState} from '../vo/RootState'
import {offlineImage} from '../common/OfflineMedia'
import {DetailPageTemplate} from './templates/DetailPageTemplate'
import {NewsPageTemplate} from './templates/news/NewsPageTemplate'
import {
  NavigationNode,
  TemplateType,
  PageType,
  Page,
  ProfessionalsType
} from 'interfaces/Interfaces'
import {ModNavigation} from './ModNavigation'
import {FrontPageTopTemplate, FrontPageBottomTemplate} from './templates/FrontPageTemplate'
import {ProfessionalsEducationPageTemplate} from './templates/ProfessionalsEducationPageTemplate'
import {EventsPageTemplate} from './templates/EventsPageTemplate'
import {ModGridRow} from './layout/ModGridRow'
import {DancerMediation, NewsDetailPage, DancerEducation} from 'interfaces/InterfacesEntities'
import {NewsDetailPageTemplate} from './templates/news/NewsDetailPageTemplate'
import {SearchTemplate} from './templates/SearchPageTemplate'
import {ImportExportTemplate} from './templates/importExport/ImportExportTemplate'
import Notifications from 'react-notify-toast'
import {ProfessionalsMediationPageTemplate} from './templates/ProfessionalsMediationPageTemplate'
import {ExpertTrainingPageTemplate} from './templates/ExpertTrainingPageTemplate'

export interface ModTemplateBlockListProps {
  searchNavigationNode: NavigationNode
  rootState: RootState
  currentLanguage: string
  navigationTree: NavigationNode
  pathname: string
}

export default class ModContent extends React.Component<ModTemplateBlockListProps> {
  render() {
    const {rootState, currentLanguage, navigationTree, pathname, searchNavigationNode} = this.props
    const {pages} = rootState
    let currentPage
    try {
      currentPage = pages[rootState.currentPage.type][rootState.currentPage.id]
    } catch (error) {}

    let contentHtml = null
    let moreContentFront = null
    let editMode = null
    let title = ''
    let colorCat = 'clr-theme-0'

    if (currentPage && currentPage.loadedDateTime && currentPage._pageType) {
      switch (currentPage._pageType) {
        case PageType.Page:
          const page = currentPage as Page
          colorCat = page.colorCat

          if (
            rootState.loadingState !== LoadingState.offline &&
            page.content[TemplateType.ImportExport]
          ) {
            return (
              <main id="content">
                <ImportExportTemplate />
              </main>
            )
          }

          if (page.content[TemplateType.Page]) {
            contentHtml = (
              <DetailPageTemplate
                header={page.content.templatePage.header}
                blocks={page.content.templatePage.blocks}
                sidePanelContent={page.content.templatePage.sidePanelContent}
                rootState={rootState}
                currentLanguage={currentLanguage}
                navigationTree={navigationTree}
                pathname={pathname}
              />
            )
          }
          if (page.content[TemplateType.Blocklist]) {
            contentHtml = (
              <main>
                <ModNavigation
                  searchNavigationNode={searchNavigationNode}
                  navigationTree={navigationTree}
                  currentLanguage={currentLanguage}
                  rootState={rootState}
                  pathname={pathname}
                />
                <ModTemplateBlockList
                  templateData={page.content.templateBlockList}
                  rootState={rootState}
                />
              </main>
            )
          }
          if (page.content[TemplateType.NewsPage]) {
            contentHtml = (
              <NewsPageTemplate
                header={page.content.templateNewsPage.header}
                news={page.content.templateNewsPage.news}
                entryCount={page.content.templateNewsPage.entryCount}
                pageCount={page.content.templateNewsPage.pageCount}
                sidePanelContent={
                  page.content.templateNewsPage.sidePanelContent &&
                  page.content.templateNewsPage.sidePanelContent
                }
                rootState={rootState}
                currentLanguage={currentLanguage}
                navigationTree={navigationTree}
                pathname={pathname}
              />
            )
          }
          if (page.content[TemplateType.Events]) {
            contentHtml = (
              <EventsPageTemplate
                header={page.content.templateEventsPage.header}
                venues={page.content.templateEventsPage.venues}
                types={page.content.templateEventsPage.types}
                events={page.content.templateEventsPage.events}
                sidePanelContent={
                  page.content.templateEventsPage.sidePanelContent &&
                  page.content.templateEventsPage.sidePanelContent
                }
                rootState={rootState}
                currentLanguage={currentLanguage}
                navigationTree={navigationTree}
                pathname={pathname}
              />
            )
          }
          if (page.content[TemplateType.ExpertTraining]) {
            contentHtml = (
              <ExpertTrainingPageTemplate
                header={page.content.templateExpertTrainingPage.header}
                venues={page.content.templateExpertTrainingPage.venues}
                types={page.content.templateExpertTrainingPage.types}
                events={page.content.templateExpertTrainingPage.events}
                sidePanelContent={
                  page.content.templateExpertTrainingPage.sidePanelContent &&
                  page.content.templateExpertTrainingPage.sidePanelContent
                }
                rootState={rootState}
                currentLanguage={currentLanguage}
                navigationTree={navigationTree}
                pathname={pathname}
              />
            )
          }
          if (page.content[TemplateType.Professionals]) {
            if (page.content.templateProfessionalsPage.type === ProfessionalsType.education) {
              contentHtml = (
                <ProfessionalsEducationPageTemplate
                  header={page.content.templateProfessionalsPage.header}
                  type={page.content.templateProfessionalsPage.type}
                  dancers={page.content.templateProfessionalsPage.dancers as DancerEducation[]}
                  sidePanelContent={
                    page.content.templateProfessionalsPage.sidePanelContent &&
                    page.content.templateProfessionalsPage.sidePanelContent
                  }
                  rootState={rootState}
                  currentLanguage={currentLanguage}
                  navigationTree={navigationTree}
                  pathname={pathname}
                />
              )
            } else if (
              page.content.templateProfessionalsPage.type === ProfessionalsType.mediation
            ) {
              contentHtml = (
                <ProfessionalsMediationPageTemplate
                  header={page.content.templateProfessionalsPage.header}
                  type={page.content.templateProfessionalsPage.type}
                  dancers={page.content.templateProfessionalsPage.dancers as DancerMediation[]}
                  sidePanelContent={
                    page.content.templateProfessionalsPage.sidePanelContent &&
                    page.content.templateProfessionalsPage.sidePanelContent
                  }
                  rootState={rootState}
                  currentLanguage={currentLanguage}
                  navigationTree={navigationTree}
                  pathname={pathname}
                />
              )
            }
          }
          if (page.content[TemplateType.Front]) {
            contentHtml = (
              <FrontPageTopTemplate key={'top'} templateData={page.content.templateFrontPage} />
            )
            moreContentFront = (
              <FrontPageBottomTemplate
                key={'bottom'}
                templateData={page.content.templateFrontPage}
              />
            )
          }
          if (page.content[TemplateType.Search]) {
            contentHtml = <SearchTemplate key={'search'} currentLanguage={currentLanguage} />
          }

          break

        case PageType.NewsDetail:
          let news = currentPage as NewsDetailPage
          title = news._i18nCL.title
          contentHtml = (
            <NewsDetailPageTemplate
              content={news}
              rootState={rootState}
              currentLanguage={currentLanguage}
              navigationTree={navigationTree}
              pathname={pathname}
              breadcrumbsExtras={{type: currentPage._pageType, title: title}}
            />
          )
          break
      }

      if (rootState.editMode) {
        editMode = <ModEditBlock entityObject={currentPage} title={title} rootState={rootState} />
      }
    }

    if (rootState.loadingState === LoadingState.offline) {
      contentHtml = (
        <div id="content" className="loading-state">
          <img alt="offline icon" src={offlineImage} />
        </div>
      )
    } else if (!contentHtml) {
      if (rootState.loadingState === LoadingState.loading) {
        contentHtml = (
          <div id="content" className="loading-state">
            <img alt="loading indicator" src={require('static/img/loading.gif')} />
          </div>
        )
      } else {
        contentHtml = (
          <Mod404
            navigationTree={navigationTree}
            currentLanguage={currentLanguage}
            pathname={pathname}
            rootState={rootState}
            templateData={rootState.websiteSettings.blocks404}
          />
        )
      }
    }

    return (
      <main className={colorCat} id="content">
        {editMode}
        <ModGridRow>
          {[
            {
              columnWidth: 1,
              component: (
                <ModNavigation
                  searchNavigationNode={searchNavigationNode}
                  key={`${currentLanguage}navigation`}
                  navigationTree={navigationTree}
                  currentLanguage={currentLanguage}
                  rootState={rootState}
                  pathname={pathname}
                />
              )
            },

            {
              columnWidth: 3,
              component: (
                <div key={'modcontent'}>
                  <Notifications />
                  {contentHtml}
                </div>
              )
            }
          ]}
        </ModGridRow>
        {moreContentFront && moreContentFront}
      </main>
    )
  }
}
