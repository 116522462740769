import { FormEducationSubject, FormEducationCat } from "./FormInterfaces";
import { EducationRegistry } from "interfaces/InterfacesEntities";

export class EducationApi {
  currentSchool: string
  currentTeacher: string
  oldSchool: string

  mainSubject: string

  ballett: string
  ballettLecturesWeek: string
  ballettYears: string

  modern: string
  modernLecturesWeek: string
  modernYears: string

  urban: string
  urbanLecturesWeek: string
  urbanYears: string

  breaking: string
  breakingLecturesWeek: string
  breakingYears: string

  jazz: string
  jazzLecturesWeek: string
  jazzYears: string

  world: string
  worldLecturesWeek: string
  worldYears: string

  addition: string
  additionLecturesWeek: string
  additionYears: string

  more: string
  moreLecturesWeek: string
  moreYears: string

  constructor() { }

  public setSubjects(subjects: FormEducationSubject[]) {
    this.mainSubject = subjects[0].subject

    // add new subject to (existing string of) this subject category
    for (let subj of subjects) {
      switch (subj.category) {
        case FormEducationCat.BALLETT:
          this.ballett = this.ballett ? `${this.ballett} / ${subj.subject}` : subj.subject
          this.ballettLecturesWeek = this.ballettLecturesWeek ? `${this.ballettLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.ballettYears = this.ballettYears ? `${this.ballettYears} / ${subj.years}` : subj.years
          break
        case FormEducationCat.MODERN:
          this.modern = this.modern ? `${this.modern} / ${subj.subject}` : subj.subject
          this.modernLecturesWeek = this.modernLecturesWeek ? `${this.modernLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.modernYears = this.modernYears ? `${this.modernYears} / ${subj.years}` : subj.years
          break
        case FormEducationCat.URBAN:
          this.urban = this.urban ? `${this.urban} / ${subj.subject}` : subj.subject
          this.urbanLecturesWeek = this.urbanLecturesWeek ? `${this.urbanLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.urbanYears = this.urbanYears ? `${this.urbanYears} / ${subj.years}` : subj.years
          break
        case FormEducationCat.BREAKING:
          this.breaking = this.breaking ? `${this.breaking} / ${subj.subject}` : subj.subject
          this.breakingLecturesWeek = this.breakingLecturesWeek ? `${this.breakingLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.breakingYears = this.breakingYears ? `${this.breakingYears} / ${subj.years}` : subj.years
          break
        case FormEducationCat.JAZZ:
          this.jazz = this.jazz ? `${this.jazz} / ${subj.subject}` : subj.subject
          this.jazzLecturesWeek = this.jazzLecturesWeek ? `${this.jazzLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.jazzYears = this.jazzYears ? `${this.jazzYears} / ${subj.years}` : subj.years
          break
        case FormEducationCat.WORLD:
          this.world = this.world ? `${this.world} / ${subj.subject}` : subj.subject
          this.worldLecturesWeek = this.worldLecturesWeek ? `${this.worldLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.worldYears = this.worldYears ? `${this.worldYears} / ${subj.years}` : subj.years
          break
        case FormEducationCat.ADDITON:
          this.addition = this.addition ? `${this.addition} / ${subj.subject}` : subj.subject
          this.additionLecturesWeek = this.additionLecturesWeek ? `${this.additionLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.additionYears = this.additionYears ? `${this.additionYears} / ${subj.years}` : subj.years
          break
        case FormEducationCat.MORE:
          this.more = this.more ? `${this.more} / ${subj.subject}` : subj.subject
          this.moreLecturesWeek = this.moreLecturesWeek ? `${this.moreLecturesWeek} / ${subj.lectures}` : subj.lectures
          this.moreYears = this.moreYears ? `${this.moreYears} / ${subj.years}` : subj.years
          break
      }
    }
  }

  public setSchool(currentSchool: string, currentTeacher: string, oldSchool: string) {
    this.currentSchool = currentSchool
    this.currentTeacher = currentTeacher
    this.oldSchool = oldSchool
  }

  public getInsertableDate(useMainSubject: boolean): EducationRegistry & { mainSubject?: string } {
    const subjects = {
      currentSchool: this.currentSchool ? this.currentSchool : '',
      currentTeacher: this.currentTeacher ? this.currentTeacher : '',
      oldSchool: this.oldSchool ? this.oldSchool : '',

      ballett: this.ballett ? this.ballett : '',
      ballettLecturesWeek: this.ballettLecturesWeek ? this.ballettLecturesWeek : '',
      ballettYears: this.ballettYears ? this.ballettYears : '',

      modern: this.modern ? this.modern : '',
      modernLecturesWeek: this.modernLecturesWeek ? this.modernLecturesWeek : '',
      modernYears: this.modernYears ? this.modernYears : '',

      urban: this.urban ? this.urban : '',
      urbanLecturesWeek: this.urbanLecturesWeek ? this.urbanLecturesWeek : '',
      urbanYears: this.urbanYears ? this.urbanYears : '',

      breaking: this.breaking ? this.breaking : '',
      breakingLecturesWeek: this.breakingLecturesWeek ? this.breakingLecturesWeek : '',
      breakingYears: this.breakingYears ? this.breakingYears : '',

      jazz: this.jazz ? this.jazz : '',
      jazzLecturesWeek: this.jazzLecturesWeek ? this.jazzLecturesWeek : '',
      jazzYears: this.jazzYears ? this.jazzYears : '',

      world: this.world ? this.world : '',
      worldLecturesWeek: this.worldLecturesWeek ? this.worldLecturesWeek : '',
      worldYears: this.worldYears ? this.worldYears : '',

      addition: this.addition ? this.addition : '',
      additionLecturesWeek: this.additionLecturesWeek ? this.additionLecturesWeek : '',
      additionYears: this.additionYears ? this.additionYears : '',

      more: this.more ? this.more : '',
      moreLecturesWeek: this.moreLecturesWeek ? this.moreLecturesWeek : '',
      moreYears: this.moreYears ? this.moreYears : ''
    }
    return useMainSubject ? { mainSubject: this.mainSubject, ...subjects } : subjects
  }
}