import * as React from 'react'
import {ModGridRow} from 'components/layout/ModGridRow'
import {FrontTemplate, PageType} from 'interfaces/Interfaces'
import {ModCircularNews} from 'components/templates/news/ModCircularNews'
import {ModBlockQuicklinks} from 'components/blocks/ModBlockQuicklilnks'
import {ModBlockAdvertisment} from 'components/blocks/ModBlockAdvertisment'
import ModAnchorTag from 'components/common/ModAnchorTag'
import {Link, NewsDetailPage, Event, RichText} from 'interfaces/InterfacesEntities'
import {ModIcon, IconType, IconSize} from 'components/common/ModIcon'
import {ModDateTime} from 'components/ui/ModDateTime'
import {createStaticPageLinkObject, createNodeLinkObject} from 'common/CmsUtils'
import ModRichText from 'components/common/ModRichText'
import ModBlockElfsight from 'components/blocks/ModBlockElfsight'

export interface FrontPageTemplateProps {
  templateData: FrontTemplate
}

export function FrontPageTopTemplate({templateData}: FrontPageTemplateProps) {
  const {breaking, newsPreview} = templateData

  if (!breaking._i18nCL || !newsPreview._i18nCL) {
    // console.warn('language not defined!')
    return null
  }

  return (
    /* breaking */
    <div className="top-news-container">
      {...breaking._i18nCL.map((item, index) => {
        return (
          <ModCircularNews
            key={`breaking${index}`}
            link={
              item.link.news
                ? createStaticPageLinkObject(item.link.news, PageType.NewsDetail)
                : createNodeLinkObject(item.link.navigationNode)
            }
            title={item.title}
            lead={item.lead}
            description={item.text}
          />
        )
      })}
    </div>
  )
}

export function FrontPageBottomTemplate({templateData}: FrontPageTemplateProps) {
  const {quicklinks, events, news, advertisment, eventsPreview, newsPreview, elfsight} =
    templateData

  if (!eventsPreview._i18nCL) {
    // console.warn('langauge for event label not defined!')
    return null
  }

  if (!newsPreview._i18nCL) {
    // console.warn('langauge for news label not defined!')
    return null
  }

  function newsToFrontEntries(news: NewsDetailPage[]): FrontEntryEntity[] {
    return news.map(newsItem => {
      if (!newsItem._i18nCL) {
        // console.warn('langauge for newsItem not defined!')
        return null
      }

      return {
        date: newsItem.publishDate,
        title: newsItem._i18nCL.title,
        text: newsItem._i18nCL.abstract,
        link: createStaticPageLinkObject(newsItem, PageType.NewsDetail)
      }
    })
  }

  function eventsToFrontEntries(events: Event[], link: Link): FrontEventEntryEntity[] {
    const now = new Date()
    const eventsByDate: {date: string; event: Event}[] = events
      .reduce((allEvents, event) => {
        for (let date of event.dates) {
          const eventDate = new Date(date)
          if (eventDate.getTime() - now.getTime() > 0) {
            allEvents.push({date: new Date(date), event: event})
          }
        }
        return allEvents
      }, [])
      .sort((a, b) => {
        return a.date.getTime() - b.date.getTime()
      })
      .slice(0, 6)

    return eventsByDate.map(event => {
      if (!event.event._i18nCL || !event.event.venue._i18nCL) {
        // console.warn('langauge for event not defined!')
        return null
      }
      return {
        date: event.date,
        title: event.event._i18nCL.name,
        richText: event.event._i18nCL.text,
        text: `${event.event.venue._i18nCL.name}, ${event.event.venue.adress.city}`,
        link: link
      }
    })
  }

  return (
    <>
      {/* quicklinks */}
      <ModBlockQuicklinks isInline={true} content={quicklinks} />

      {/* news */}
      <ModGridRow>
        {[
          {
            columnWidth: 3,
            component: (
              <React.Fragment key={'news'}>
                {newsPreview._i18nCL && (
                  <FrontNews
                    title={newsPreview._i18nCL.newsTitle}
                    link={{internalLink: newsPreview._i18nCL.linkNews}}
                    entries={newsToFrontEntries(
                      news
                        ? news.filter(newsItem => newsItem._i18nCL != undefined || null).slice(0, 6)
                        : []
                    )}
                    showDateAndHideTimeAndHideIcon={true}
                  />
                )}
              </React.Fragment>
            )
          },
          {
            columnWidth: 1,
            component: <div key={'emptynews'}></div>
          }
        ]}
      </ModGridRow>

      {/* elfsight feed */}
      <ModGridRow>
        {[
          {
            columnWidth: 3,
            component: (
              <React.Fragment key={'elfsight'}>
                {elfsight && <ModBlockElfsight content={elfsight} />}
              </React.Fragment>
            )
          },
          {
            columnWidth: 1,
            component: <div key={'emptyelfsight'}></div>
          }
        ]}
      </ModGridRow>

      {/* events */}
      <ModGridRow>
        {[
          {
            columnWidth: 3,
            component: (
              <React.Fragment key={'events'}>
                {eventsPreview._i18nCL && (
                  <FrontEvents
                    title={eventsPreview._i18nCL.eventsTitle}
                    link={{internalLink: eventsPreview._i18nCL.linkEvents}}
                    entries={eventsToFrontEntries(
                      events ? events.filter(event => event._i18nCL != undefined || null) : [],
                      {internalLink: eventsPreview._i18nCL.linkEvents}
                    )}
                  />
                )}
              </React.Fragment>
            )
          },
          advertisment
            ? {
                columnWidth: 1,
                component: (
                  <div key={'advert'} className="advertisment line">
                    <ModBlockAdvertisment content={advertisment} />
                  </div>
                )
              }
            : {
                columnWidth: 1,
                component: <div key={'emptyevents'}></div>
              }
        ]}
      </ModGridRow>

    </>
  )
}

interface FrontEventsAndNewsProps {
  readonly title: string
  readonly link: Link
  readonly showDateAndHideTimeAndHideIcon?: boolean
}

interface FrontNewsProps extends FrontEventsAndNewsProps {
  readonly entries: FrontEntryEntity[]
}

function FrontNews({
  title,
  link,
  entries,
  showDateAndHideTimeAndHideIcon: showDateAndHideTime = false
}: FrontNewsProps) {
  const firstRowEntries = entries.slice(0, 3)
  const secondRowEntries = entries.slice(3, 6)

  return (
    <div className="front-teaser">
      <FrontLink label={title} link={link} />
      <ModGridRow>
        {entries.map(entry => {
          return {
            columnWidth: 1,
            maxWidth: 3,
            component: entry && (
              <FrontEntry
                key={`${entry.title}${entry.date}`}
                date={entry.date}
                title={entry.title}
                text={entry.text}
                showDateAndHideTimeAndHideIcon={showDateAndHideTime}
                link={entry.link}
              />
            )
          }
        })}
      </ModGridRow>
    </div>
  )
}

interface FrontEventsProps extends FrontEventsAndNewsProps {
  readonly entries: FrontEventEntryEntity[]
}

function FrontEvents({
  title,
  link,
  entries,
  showDateAndHideTimeAndHideIcon: showDateAndHideTime = false
}: FrontEventsProps) {
  return (
    <div className="front-teaser">
      <FrontLink label={title} link={link} />
      <ModGridRow>
        {entries.map(entry => {
          return {
            columnWidth: 1,
            maxWidth: 3,
            component: entry && (
              <FrontEventEntry
                key={`${entry.title}${entry.date}`}
                date={entry.date}
                title={entry.title}
                richText={entry.richText}
                text={entry.text}
                showDateAndHideTimeAndHideIcon={showDateAndHideTime}
                link={entry.link}
              />
            )
          }
        })}
      </ModGridRow>
    </div>
  )
}

interface FrontLinkProps {
  readonly label: string
  readonly link: Link
}

function FrontLink({label, link}: FrontLinkProps) {
  //return (<div> {label} <ModIcon type={IconType.Arrow_Right} size={IconSize.Equal} /></div>)
  return (
    <div className="frontteaser-title">
      <ModAnchorTag linkObject={link}>
        {label} <ModIcon type={IconType.Arrow_Right} size={IconSize.Equal} />
      </ModAnchorTag>
    </div>
  )
}

interface FrontEntryEntity {
  readonly date: string
  readonly title: string
  readonly text: string
  readonly link: Link
}

interface FrontEntryProps extends FrontEntryEntity {
  readonly showDateAndHideTimeAndHideIcon?: boolean
}

function FrontEntry({
  date,
  title,
  text,
  link,
  showDateAndHideTimeAndHideIcon: showDateAndHideTime = false
}: FrontEntryProps) {
  return (
    <div className="front-teaser-content">
      <ModDateTime
        isTimeFirst={true}
        date={date}
        showDay={showDateAndHideTime}
        hideTime={showDateAndHideTime}
        showCalendarIcon={!showDateAndHideTime}
      />
      <ModAnchorTag linkObject={link}>
        <h3>{title}</h3>
        <p className="small-text">{text}</p>
      </ModAnchorTag>
    </div>
  )
}

interface FrontEventEntryEntity {
  readonly date: string
  readonly title: string
  readonly richText: RichText
  readonly text: string
  readonly link: Link
}

interface FrontEventEntryProps extends FrontEventEntryEntity {
  readonly showDateAndHideTimeAndHideIcon?: boolean
}

function FrontEventEntry({
  date,
  title,
  text,
  richText,
  link,
  showDateAndHideTimeAndHideIcon: showDateAndHideTime = false
}: FrontEventEntryProps) {
  return (
    <div className="front-teaser-content">
      <ModDateTime
        isTimeFirst={true}
        date={date}
        showDay={showDateAndHideTime}
        hideTime={showDateAndHideTime}
        showCalendarIcon={!showDateAndHideTime}
      />
      <ModAnchorTag linkObject={link}>
        <h3>{title}</h3>
        <div className="small-text">
          <ModRichText richText={richText} />
        </div>
        <div>
          <p className="small-text">{text}</p>
        </div>
      </ModAnchorTag>
    </div>
  )
}
