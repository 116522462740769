import * as React from 'react'
import { BlockCourseSignUp } from 'interfaces/InterfacesBlocks'
import { ModInput } from 'components/ui/ModInput'
import { ModGridRow } from 'components/layout/ModGridRow'
import { ModCheckbox } from 'components/ui/ModCheckbox'
import { ModPrimaryButton } from 'components/ui/ModPrimaryButton'
import { FormattedMessage } from 'react-intl'


// TODO Remove!!
const defaultFormState = {
  timestamp: new Date(),

  name: '',
  surname: '',
  street: '',
  postalCode: '',
  city: '',
  canton: '',
  phone: '',
  birthDate: '',
  email: ''
}

export interface ModBlockCourseSignUpProps {
  content: BlockCourseSignUp
}

export function ModBlockCourseSignUp({ content }: ModBlockCourseSignUpProps) {
  if (!content._i18nCL) {
    return null
  }

  const i18n = content._i18nCL

  const [formState, setFormState] = React.useState(defaultFormState)

  function onFormChange(key: string, value: string) {
    let newState = { ...formState }
    newState[key] = value
    setFormState(newState)
  }

  return (
    <div className="block form signup">
      <h3>{i18n.formTitle}</h3>
      <form>
        <fieldset>
          {/* <ModFormCredentials
            formLabels
            name={formState.name}
            surname={formState.surname}
            street={formState.street}
            postalCode={formState.postalCode}
            city={formState.city}
            canton={formState.canton}
            phone={formState.phone}
            birthDate={formState.birthDate}
            email={formState.email}
            onFormChange={onFormChange}
          /> */}
          <ModInput label={i18n.labelCourse} />
          <ModGridRow>
            {[
              {
                columnWidth: 1,
                component: (
                  <div key={i18n.labelMember}>
                    <ModCheckbox label={i18n.labelMember} />
                    <ModCheckbox label={i18n.labelOther} />
                  </div>
                )
              },
              {
                columnWidth: 1,
                component: (
                  <div key={i18n.labelStudent}>
                    <ModCheckbox label={i18n.labelStudent} />
                    <ModCheckbox label={i18n.labelMemberSignUp} />
                  </div>
                )
              }
            ]}
          </ModGridRow>

          <ModPrimaryButton type="submit">
            <FormattedMessage id="form.confirm" defaultMessage="Anmelden" />
          </ModPrimaryButton>
        </fieldset>
      </form>
    </div>
  )
}
