import * as React from 'react';
import {useSelect, UseSelectProps, UseSelectState} from 'downshift'
import { ModIcon, IconType, IconSize } from 'components/common/ModIcon';
import { ModIconButton } from './ModIconButton';

export interface ModDynamicSwitchProps{
  items: Map<string, React.ReactNode>
  selected?: string
}


function filterNotSelected(items: Map<string, React.ReactNode>, selected: string): string[] {
  return Array.from(items.keys()).filter(item => { if (item != selected) return item })
}


export function ModDynamicSwitch({ items, selected }: ModDynamicSwitchProps) {

  const defaultKeys = filterNotSelected(items, selected)
  const [isOpen, setOpen] = React.useState(false)

  function optionForKey(key: string) {
    return items.get(key)
  }

  return (
    <>
      <div className="current-lang">{selected}</div>
      {isOpen && (
        defaultKeys.map(key => {
          return <div key={key} onClick={e => setOpen(false)}>{optionForKey(key)}</div>;
        })
      )}
      <ModIconButton type="button" onClick={e => setOpen(!isOpen)} iconType={isOpen ? IconType.Chevron_Up : IconType.Chevron_Down}/>
    </>
  );
}