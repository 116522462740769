import * as React from "react";
import { isSafari } from 'react-device-detect';

export interface ModInputProps {
  readonly type?: string;
  readonly label?: string;
  readonly error?: string;
  readonly value?: string;
  readonly required?: boolean
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function ModInput({
  type,
  label,
  error,
  value,
  required,
  onChange
}: ModInputProps) {

  function input() {
    if (isSafari && type == 'date') {
      return required ?
        <input
          type={type}
          className="text-input"
          placeholder={`${label} dd.mm.yyyy`}
          value={value}
          onChange={onChange}
          pattern='^\d{1,2}\.\d{1,2}\.\d{4}$'
          required
        /> : <input
          type={type}
          className="text-input"
          placeholder={`${label} dd.mm.yyyy`}
          value={value}
          onChange={onChange}
          pattern='^\d{1,2}\.\d{1,2}\.\d{4}$'
        />
    }

    return required ?
      <input
        type={type}
        className="text-input"
        placeholder={label}
        value={value}
        onChange={onChange}
        required
      /> : <input
        type={type}
        className="text-input"
        placeholder={label}
        value={value}
        onChange={onChange}
      />
  }

  return (
    <div className="input-wrapper">
      {input()}
      <label>{label}</label>
    </div>
  );
}
