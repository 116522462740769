export const supportedLanguages = ['en', 'de', 'fr', 'it']
export enum SupportedLangauges {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  IT = 'it'
}

export function getCurrentLanguageByPath(path: string): string {
  const regex = new RegExp(`^\/?(${supportedLanguages.join('|')})`, 'i')
  const match = regex.exec(path)
  if (!(match && match.length > 0)) {
    return "unknown"
  }
  return match[1]
}

/**
 * Fall Back Language is en
 * @param path
 */
export function getCurrentLanguageOrFallBackByPath (path: string): string {
  let currentLanguage = getCurrentLanguageByPath(path)
  return currentLanguage === 'unknown' ? 'en' : currentLanguage
}

export function getCurrentLanguageEnumOrFallBackByPath(path: string): SupportedLangauges {
  // TODO
  // let currentLanguage = getCurrentLanguageByPath(path)
  // for (const lang in SupportedLangauges) {
  //   console.log(lang)
  //   //if(currentLanguage == SupportedLangauges[lang]) return SupportedLangauges[lang]
  // }
  // console.log('language not defined in enum SupportedLanguages', currentLanguage)
  // return SupportedLangauges.EN
  let currentLanguage = getCurrentLanguageByPath(path)
  switch (currentLanguage) {
    case SupportedLangauges.DE:
      return SupportedLangauges.DE
    case SupportedLangauges.EN:
      return SupportedLangauges.EN
    case SupportedLangauges.FR:
      return SupportedLangauges.FR
    case SupportedLangauges.IT:
      return SupportedLangauges.IT
    default:
      console.log('language not defined in enum SupportedLanguages', currentLanguage)
      return SupportedLangauges.EN
  }
}