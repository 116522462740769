import * as React from 'react'
import {ModGridRow} from 'components/layout/ModGridRow'

interface Option {
  id: string
  label: string
}

export interface ModRadioGroupProps {
  readonly groupName: string
  readonly label?: string
  readonly options: Option[]
  readonly checked?: string // id of checked option
  readonly isInline?: boolean
  readonly required?: boolean
  onCheck?(checked: Option): void
  className?: string
}

export function ModRadioGroup({
  groupName,
  label,
  options,
  checked,
  isInline = false,
  onCheck,
  required = false,
  className = ''
}: ModRadioGroupProps) {
  return (
    <div className={isInline ? `radio-group inline ${className}` : `radio-group ${className}`}>
      {label && <p>{label}</p>}
      {options.map(option => {
        return (
          <label
            key={option.label}
            className="radio-option"
            onClick={e => {
              onCheck && onCheck(option)
            }}>
            <div className="radio">
              <input
                key={option.id}
                type="radio"
                value={option.label}
                name={groupName}
                checked={option.id == checked}
                onChange={() => {}} // we get a console error if we dont use onChange. we cannot use readOnly does not work with required
                required={required}
              />
              <span className="checkmark"></span>
            </div>
            {option.label}
          </label>
        )
      })}
    </div>
  )
}
