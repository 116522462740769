import { DancerEducation } from '../../../interfaces/InterfacesEntities'
import Notifications, { notify } from 'react-notify-toast'

export function ProfessionalsAdapter(headerData, data) {

  function testXls(name: string, position: number): boolean {
    if (headerData[position] != name) {
      error(name, headerData[position], position)
      return false
    }
    return true
  }

  let result: DancerEducation = {
    name: testXls("Name", 0) && data[0] ? data[0] : '',
    surname: testXls("Vorname", 1) && data[1] ? data[1] : '',
    postalCode: testXls("PLZ", 2) && data[2] ? parseInt(data[2]) : 0,
    city: testXls("Ort", 3) && data[3] ? data[3] : '',
    canton: testXls("Kanton", 4) && data[4] ? data[4] : '',
    //phone: testXls("Telefon_G", 5) && data[5] ? data[5] : '',
    email: testXls("E-Mail_g", 5) && data[5] ? data[5] : '',
    website: testXls("Webseite", 6) && data[6] ? data[6] : '',

    styles: {
      arealAcrobaticPole: testXls("Aerial_Akrobatik_Pole", 7) && data[7] ? data[7] : false,
      afroAfroBeats: testXls("Afro_Afro-Beats", 8) && data[8] ? data[8] : false,
      expressiveTheater: testXls("Ausdruckstanz_Tanztheater", 9) && data[9] ? data[9] : false,
      ballettClassic: testXls("Ballett_klassisch", 10) && data[10] ? data[10] : false,
      balletContemporary: testXls("Ballett_zeitgenössisch", 11) && data[11] ? data[11] : false,
      breaking: testXls("Breaking", 12) && data[12] ? data[12] : false,
      character: testXls("Charaktertanz", 14) && data[14] ? data[14] : false,
      contactImprovisation: testXls("Contact_Improvisation", 13) && data[13] ? data[13] : false,
      danceHall: testXls("Dance_Hall", 15) && data[15] ? data[15] : false,
      flamenco: testXls("Flamenco", 16) && data[16] ? data[16] : false,
      flow: testXls("Flow", 17) && data[17] ? data[17] : false,
      heelsJazzFunk: testXls("Heels_Jazz-Funk", 18) && data[18] ? data[18] : false,
      hipHopUrban: testXls("Hip-Hop_Urban-Styles", 19) && data[19] ? data[19] : false,
      jazz: testXls("Jazz", 20) && data[20] ? data[20] : false,
      latin: testXls("Lateinamerikanische_Taenze", 21) && data[21] ? data[21] : false,
      modern: testXls("Modern", 22) && data[22] ? data[22] : false,
      musicalShowDance: testXls("Musical_Show-Dance", 23) && data[23] ? data[23] : false,
      oriental: testXls("Oriental", 24) && data[24] ? data[24] : false,
      tapdance: testXls("Stepptanz", 25) && data[25] ? data[25] : false,
      waackingVoguing: testXls("Waacking_Voguing", 26) && data[26] ? data[26] : false,
      world: testXls("World", 27) && data[27] ? data[27] : false,
      contemporary: testXls("zeitgenoessischer_Tanz", 28) && data[28] ? data[28] : false,
      creativeChild: testXls("kreativer_Kindertanz", 29) && data[29] ? data[29] : false,
      improComposition: testXls("Improvisation_Komposition", 30) && data[30] ? data[30] : false,
      inclusiveDanceAbility: testXls("inklusiv_DanceAbility", 31) && data[31] ? data[31] : false,
      somatic: testXls("somatische_Techniken", 32) && data[32] ? data[32] : false,
      comment: testXls("BR_Bemerkungen_Tanztechniken", 33) && data[33] ? data[33] : '',
    },

    level: {
      comment: testXls("BR_Bemerkungen_Zielgruppen", 34) && data[34] ? data[34] : '',
      children: testXls("Kinder", 35) && data[35] ? data[35] : false,
      youngAdults: testXls("Jugendliche", 36) && data[36] ? data[36] : false,
      adults: testXls("Erwachsene", 37) && data[37] ? data[37] : false,
      seniors: testXls("SeniorInnen", 38) && data[38] ? data[38] : false,
      ksStudents: testXls("Vorprof/KS-SchuelerInnen", 39) && data[39] ? data[39] : false,
      professional: testXls("Prof_TaenzerInnen", 40) && data[40] ? data[40] : false,
    },

    isSenior: testXls("Junior Stufe", 42) && data[42] ? !data[42] : false
  }

  return result
}

export function error(shouldBe: string, is: string, position: number) {
  const errorLog = `error parsing xls. Spalte ${position} sollte heissen: ${shouldBe} 'heisst aber: ${is}`
  notify.show(errorLog, "error", 8000);
  console.error(errorLog)
}