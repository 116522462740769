import * as React from 'react'
import {Ref} from "react";

export interface ModLazyLoaderProps {
  rRef?: Ref<any>
}

export default class ModLazyLoadView extends React.Component<ModLazyLoaderProps, {}> {

  render() {
    return (
      <div ref={this.props.rRef} className="lazy-load">
        <p>loading
          <noscript>In order to display this content, java script must be enabled</noscript>
        </p>
      </div>
    )
  }
}
