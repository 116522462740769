import * as React from 'react'
import {useFormatTime, useFormatDate, useFormatDay} from 'components/common/utils/DateTimeFormatter'
import {ModIcon, IconType} from 'components/common/ModIcon'

export interface ModDateTimeProps {
  readonly date: string | Date
  readonly end?: string | Date
  readonly isTimeFirst?: boolean
  readonly hideTime?: boolean
  readonly showDay?: boolean
  readonly showCalendarIcon?: boolean
  readonly showOnlyTime?: boolean
  readonly city?: string
}

export function ModDateTime({
  date: stringOrDateDate,
  end: stringOrDateEnd,
  isTimeFirst = false,
  showDay = false,
  hideTime = false,
  showCalendarIcon = false,
  showOnlyTime = false,
  city
}: ModDateTimeProps) {
  let date = null
  if (stringOrDateDate instanceof Date) {
    date = stringOrDateDate
  } else {
    date = new Date(stringOrDateDate)
  }

  let endDate = null
  if (stringOrDateEnd instanceof Date) {
    endDate = stringOrDateEnd
  } else {
    endDate = new Date(stringOrDateEnd)
  }

  const FormattedTime = useFormatTime(date)

  const FormattedDate = useFormatDate(date)

  const FormattedDay = useFormatDay(date)

  return (
    <>
      {showOnlyTime ? (
        <span className="pulish-date">
          {city && city} {FormattedTime}
          {stringOrDateEnd && ' - '}
          {stringOrDateEnd && useFormatTime(endDate)}
        </span>
      ) : (
        <span className="pulish-date">
          {showCalendarIcon && <ModIcon type={IconType.Calendar} />}
          {isTimeFirst ? (
            <>
              {!hideTime && `${FormattedTime} |`} {showDay && FormattedDay} {FormattedDate}
            </>
          ) : (
            <>
              {showDay && FormattedDay} {FormattedDate} {!hideTime && `| ${FormattedTime}`}
            </>
          )}
        </span>
      )}
    </>
  )
}
