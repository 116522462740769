import * as React from 'react'

import RootState from "../../vo/RootState";
import { BlocksRenderer } from 'components/blocks/BlocksRenderer';

// ***** DEPRECATED use components > blocks > BlocksRenderer.tsx instead **********
export interface ModTemplateBlockListProps {
  rootState: RootState
  templateData: any[]
}

export default class ModTemplateBlockList extends React.Component<ModTemplateBlockListProps, {}> {

  render () {

    return (
      <BlocksRenderer rootState={this.props.rootState} blockListData={this.props.templateData} />
    )
  }
}
