import * as React from 'react'
import ModImgTag from 'components/common/ModImgTag'
import {Media} from '../../interfaces/InterfacesEntities'
import ModPhotoSwipe from 'components/common/ModPhotoSwipe'

export interface ModBlockImageProps {
  content: {
    image: Media
    _i18nCL?: {text: string}
  }
}

export interface ModBlockImageState {
  isFullScreen: boolean
}

export default class ModBlockImage extends React.Component<ModBlockImageProps, ModBlockImageState> {
  constructor(props) {
    super(props)

    this.state = {
      isFullScreen: false
    }
    this.onFullScreen = this.onFullScreen.bind(this)
    this.didCloseFullScreen = this.didCloseFullScreen.bind(this)
  }

  onFullScreen(event) {
    this.setState({
      isFullScreen: true
    })
  }

  didCloseFullScreen() {
    this.setState({
      isFullScreen: false
    })
  }

  render() {
    if (!this.props.content.image) {
      // console.warn('no image defined in block image')
      return null
    }

    return (
      <>
        {this.state.isFullScreen && (
          <ModPhotoSwipe
            index={0}
            sliderIndex={0}
            content={{
              _id: '',
              internalDescription: '',
              list: [
                {
                  _i18nCL: this.props.content._i18nCL,
                  image: this.props.content.image
                }
              ]
            }}
            onClose={this.didCloseFullScreen}
          />
        )}
        <div className="block-image" onClick={this.onFullScreen}>
          <ModImgTag imgObject={this.props.content.image} />
        </div>
      </>
    )
  }
}
