import {useEffect, useState} from 'react'

// See gist for usage Examples: https://gist.github.com/puck3000/daca124ed25e50350765e58f78b0b86a

export enum ConsentType {
  Necessary = 'necessary',
  Preferences = 'preferences',
  Statistics = 'statistics',
  marketing = 'marketing',
  Unclassified = 'unclassified'
}

declare const Cookiebot: CookiebotType

type CookiebotType = {
  consent: {
    [key in ConsentType]: boolean
  }
  cookiebot: any
}

export default function useCookiebot() {
  const [cookiebot, setCookiebot] = useState<CookiebotType>({
    consent: {
      necessary: false,
      preferences: false,
      statistics: false,
      marketing: false,
      unclassified: false
    },
    cookiebot: undefined
  })

  useEffect(() => {
    const cookiebotHandler = () => {
      if (window.hasOwnProperty('Cookiebot') && Cookiebot.consent) {
        setCookiebot({
          consent: {
            necessary: Cookiebot.consent.necessary,
            preferences: Cookiebot.consent.preferences,
            statistics: Cookiebot.consent.statistics,
            marketing: Cookiebot.consent.marketing,
            unclassified: Cookiebot.consent.unclassified
          },
          cookiebot: Cookiebot
        })
      }
    }
    ;['CookiebotOnAccept', 'CookiebotOnDecline', 'CookiebotOnLoad'].forEach(function (e) {
      window.addEventListener(e, cookiebotHandler)
    })

    cookiebotHandler()

    return () => {
      ;['CookiebotOnAccept', 'CookiebotOnDecline', 'CookiebotOnLoad'].forEach(function (e) {
        window.removeEventListener(e, cookiebotHandler)
      })
    }
  }, [])

  return cookiebot
}
