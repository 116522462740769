import * as React from 'react';
import { BlockQuicklinks } from 'interfaces/InterfacesBlocks';
import ModAnchorTag from 'components/common/ModAnchorTag';
import { ModIcon, IconType, IconSize } from 'components/common/ModIcon';
import { Link } from 'interfaces/InterfacesEntities';
import { ModGridRow } from 'components/layout/ModGridRow';
import { FormattedMessage } from 'react-intl'

export interface ModBlockQuicklinksProps{
  readonly content: BlockQuicklinks
  readonly isInline?: boolean
}

// ** ViewModel **
export function ModBlockQuicklinks({ content, isInline = false }: ModBlockQuicklinksProps) {
  const links = content.links.reduce((acc, currLInkItem) => {
    if (currLInkItem._i18nCL) {
      acc.push({ label: currLInkItem._i18nCL.label, link: currLInkItem._i18nCL.link })
    }

    return acc
  }, [])

  if (links.length > 0) {
    return <ViewBlockQuicklinks isInline={isInline} links={links} />
  } else {
    return null
  }
}


// ** View **
export interface ViewBlockQuicklinksProps {
  readonly links: ModLinkProps[]
  readonly isInline: boolean
}

export function ViewBlockQuicklinks({
  links,
  isInline
}: ViewBlockQuicklinksProps) {
  return (
    <>
      {isInline ? (
        <div className="block-quicklinks inline">
          <ModGridRow>
            {links.map(link => {
              return {
                columnWidth: 1,
                component: (
                  <ModLink key={link.label} label={link.label} link={link.link} />
                )
              };
            })}
          </ModGridRow>
        </div>
      ) : (
          <div className="block-quicklinks">
            <h3><FormattedMessage id="title.quicklinks" defaultMessage="Quicklinks"/></h3>
          {links.map(link => {
            return (
              <ModLink key={link.label} label={link.label} link={link.link} />
            );
          })}
        </div>
      )}
    </>
  );
}

export interface ModLinkProps{
  readonly label: string
  readonly link: Link
}

export function ModLink({ label, link }: ModLinkProps) {
  return <ModAnchorTag linkObject={link}>{label} <ModIcon type={IconType.Arrow_Right} size={IconSize.Equal} /></ModAnchorTag>
}