import * as React from 'react'

import {PageTemplateProps} from './IPageTemplateProps'
import ModBreadCrumb from '../ModBreadCrumb'
import {ModGridRow} from 'components/layout/ModGridRow'
import {ProfessionalsTemplate} from 'interfaces/Interfaces'
import {ModFilter, FilterType} from 'components/ui/filter/ModFilter'
import {DancerMediation} from 'interfaces/InterfacesEntities'
import {FormattedMessage} from 'react-intl'
import ModIntlWrapper from 'components/common/utils/ModIntlWrapper'
import {ModPageHeader} from './DetailPageTemplate'
import {BlocksRenderer} from 'components/blocks/BlocksRenderer'

const targetGroupsSortOrder = {
  children: 0,
  adolescents: 1,
  adults: 2,
  seniors: 3,
  specialNeeds: 4,
  others: 5
}

const mediationFieldsSortOrder = {
  danceHealth: 0,
  danceResearch: 1,
  danceSchool: 2,
  mediationDanceWork: 3,
  danceContext: 4,
  artificialCommunityDance: 5
}

export interface ProfessionalsMediationPageTemplateProps
  extends PageTemplateProps,
    ProfessionalsTemplate<DancerMediation> {}

export function ProfessionalsMediationPageTemplate({
  navigationTree,
  currentLanguage,
  rootState,
  pathname,
  dancers,
  header,
  sidePanelContent
}: ProfessionalsMediationPageTemplateProps) {
  if (dancers == undefined || dancers == null || dancers.length === 0) {
    console.warn('no dancers defined')
    return null
  }

  const [filteredDancers, setFilteredDancers] = React.useState(
    dancers.sort((a, b) => a.name.localeCompare(b.name))
  )

  const filterGroup = {
    TargetGroupId: 'TargetGroupId',
    LocationLang: 'LocationLang',
    LocationId: 'LocationId',
    MediationFieldId: 'MediationFieldId'
  }

  function sortAlphabetically(arrayToSort) {
    return arrayToSort.sort((a, b) => a.label.localeCompare(b.label))
  }

  function sort(tg1: string, tg2: string, sortMap: {[key: string]: number}) {
    if (!(tg1 in sortMap)) {
      console.warn(`missing sort order key ${tg1} in `, sortMap)
      return 0
    }
    if (!(tg2 in sortMap)) {
      console.warn(`missing sort order key ${tg2} in `, sortMap)
      return 0
    }
    return sortMap[tg1] - sortMap[tg2]
  }

  // Target Group Filter Options
  const danceTargetGroupOptions = formatMessageCallback =>
    Object.keys(dancers[0].targetGroups)
      .filter(tg => tg !== 'comment')
      .sort((tg1, tg2) => sort(tg1, tg2, targetGroupsSortOrder))
      .map(tg => ({
        id: tg,
        label: formatMessageCallback({id: `dancerTargetGroup.${tg}`})
      }))

  // Mediation Fields Filter Options
  const mediationFieldsOptions = formatMessageCallback =>
    Object.keys(dancers[0].mediationFields)
      .filter(tg => tg !== 'comment')
      .sort((tg1, tg2) => sort(tg1, tg2, mediationFieldsSortOrder))
      .map(tg => ({
        id: tg,
        label: formatMessageCallback({id: `dancerMediationField.${tg}`})
      }))

  // Working Location Options
  const workingLocationOptions = formatMessageCallback =>
    Object.keys(
      dancers.reduce((accu, dancer) => {
        for (const dancerLocation of dancer.workLocations) {
          accu[dancerLocation] = dancerLocation
        }
        return accu
      }, {})
    )
      .map(tg => ({
        id: tg,
        label: formatMessageCallback({id: `dancerWorkingLocation.${tg}`})
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

  // Working Language Options
  const workingLanguageOptions = formatMessageCallback =>
    Object.keys(
      dancers.reduce((accu, dancer) => {
        for (const dancerLang of dancer.mediationLanguages) {
          accu[dancerLang] = dancerLang
        }
        return accu
      }, {})
    )
      .map(tg => ({
        id: tg,
        label: formatMessageCallback({id: `mediationLanguage.${tg}`})
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

  /**
   * on Filter Change
   * @param filters
   */
  function onFilterChange(filters: {key: string; selection: any}[]) {
    let filtered = dancers

    for (let fil of filters) {
      filtered = filtered.filter(dancer => {
        if (fil.key === filterGroup.LocationLang) {
          for (let selected of fil.selection) {
            for (let workLang of dancer.mediationLanguages) {
              if (workLang === selected.id) {
                return true
              }
            }
          }
          return false
        }

        if (fil.key === filterGroup.LocationId) {
          for (let selected of fil.selection) {
            for (let workLocation of dancer.workLocations) {
              if (workLocation === selected.id) {
                return true
              }
            }
          }
          return false
        }

        if (fil.key === filterGroup.MediationFieldId) {
          for (let selected of fil.selection) {
            for (let mediationField in dancer.mediationFields) {
              if (dancer.mediationFields[mediationField] && mediationField === selected.id) {
                return true
              }
            }
          }
        }

        if (fil.key === filterGroup.TargetGroupId) {
          for (let selected of fil.selection) {
            for (let targetGroup in dancer.targetGroups) {
              if (dancer.targetGroups[targetGroup] && targetGroup === selected.id) {
                return true
              }
            }
          }
        }
      })
    }

    filtered.sort((a, b) => a.name.localeCompare(b.name))
    setFilteredDancers(filtered)
  }

  function resetFilters() {
    setFilteredDancers(dancers)
  }

  /**
   * get Mediation Languages
   * @param dancer
   */
  function getMediationLanguages(dancer: DancerMediation, formatMessage) {
    let languages = []
    for (let language of dancer.mediationLanguages) {
      if (language) {
        languages.push(
          formatMessage({id: `mediationLanguage.${language}`}, {defaultMessage: language})
        )
      }
    }
    languages.sort((a, b) => a.localeCompare(b))
    return languages
  }

  /**
   * get Mediation Fields
   * @param dancer
   */
  function getMediationFields(dancer: DancerMediation, formatMessage) {
    const rsultMediationFields = []
    for (let mediationField in dancer.mediationFields) {
      if (dancer.mediationFields[mediationField] && mediationField != 'comment') {
        rsultMediationFields.push({
          sort: mediationFieldsSortOrder[mediationField],
          msg: formatMessage(
            {id: `dancerMediationField.${mediationField}`},
            {defaultMessage: mediationField}
          )
        })
      }
    }
    return rsultMediationFields.sort((a, b) => a.sort - b.sort).map(a => a.msg)
  }

  /**
   * get Target Groups
   * @param dancer
   */
  function getTargetGroups(dancer: DancerMediation, formatMessage) {
    const resultTargetGroups = []

    for (let targetGroup in dancer.targetGroups) {
      if (dancer.targetGroups[targetGroup] && targetGroup != 'comment') {
        resultTargetGroups.push({
          sort: targetGroupsSortOrder[targetGroup],
          msg: formatMessage(
            {id: `dancerTargetGroup.${targetGroup}`},
            {defaultMessage: targetGroup}
          )
        })
      }
    }

    return resultTargetGroups.sort((a, b) => a.sort - b.sort).map(a => a.msg)
  }

  return (
    <ModIntlWrapper>
      {({formatMessage}) => (
        <ModGridRow>
          {[
            {
              columnWidth: 2,
              component: (
                <div key="content" className="main-content">
                  {header && header._i18nCL && (
                    <ModPageHeader title={header._i18nCL.title} lead={header._i18nCL.lead} />
                  )}
                  <p className="small-text filter-title">
                    <FormattedMessage
                      id={'filter.filterTitleProfessionals'}
                      defaultMessage={'Filter setzen'}
                    />
                  </p>
                  <ModFilter
                    onFilterChange={onFilterChange}
                    currentlanguage={currentLanguage}
                    filterDataChangeIndicator={currentLanguage}
                    filters={[
                      {
                        id: filterGroup.LocationLang,
                        type: FilterType.String,
                        label: formatMessage({
                          id: 'filter.workLanguage',
                          defaultMessage: 'Arbeitssprache'
                        }),
                        options: workingLanguageOptions(formatMessage)
                      },
                      {
                        id: filterGroup.LocationId,
                        type: FilterType.String,
                        label: formatMessage({
                          id: 'filter.workPlace',
                          defaultMessage: 'Arbeitsort'
                        }),
                        options: workingLocationOptions(formatMessage)
                      },
                      {
                        id: filterGroup.TargetGroupId,
                        type: FilterType.String,
                        label: formatMessage({
                          id: 'dancerLevel.label',
                          defaultMessage: 'Zielgruppe'
                        }),
                        options: danceTargetGroupOptions(formatMessage)
                      },
                      {
                        id: filterGroup.MediationFieldId,
                        type: FilterType.String,
                        label: formatMessage({
                          id: 'dancerMediationField.label',
                          defaultMessage: 'Vermittlungsfeld'
                        }),
                        options: mediationFieldsOptions(formatMessage)
                      }
                    ]}
                  />

                  {filteredDancers.length > 0 ? (
                    filteredDancers.map((dancer, index) => {
                      return (
                        <div className="reg-entry" key={`${dancer.surname},${dancer.name}`}>
                          <h4 className="reg-entry__name">
                            {dancer.name}, {dancer.surname}
                          </h4>
                          <div className="reg-entry__credentials grid pattern-50-50">
                            <div className="reg-entry__address">
                              <p>
                                {dancer.workLocations
                                  .sort()
                                  .map(canton =>
                                    formatMessage({id: `dancerWorkingLocation.${canton}`})
                                  )
                                  .join(', ')}
                              </p>
                            </div>
                            <div className="reg-entry__contact">
                              {dancer.websites &&
                                dancer.websites.map(website => {
                                  return (
                                    <p className="reg-entry__website small-text">
                                      <a
                                        href={website.startsWith('http') ? website : `//${website}`}
                                        target="_blank">
                                        {website}
                                      </a>
                                    </p>
                                  )
                                })}
                            </div>
                          </div>
                          <div className="grid pattern-50-50">
                            <div className="reg-entry__offer">
                              <h5>
                                <FormattedMessage
                                  id={'dancerMediationField.label'}
                                  defaultMessage={'Vermittlungsfeld'}
                                />
                              </h5>
                              {getMediationFields(dancer, formatMessage).map(item => {
                                return <p className="reg-entry__type">{item}</p>
                              })}
                              <p className="reg-entry__type">
                                <i>{dancer.mediationFields.comment}</i>
                              </p>
                            </div>

                            <div className="reg-entry__levels">
                              <h5>
                                <FormattedMessage
                                  id={'dancerLevel.label'}
                                  defaultMessage={'Zielgruppe'}
                                />
                              </h5>
                              {getTargetGroups(dancer, formatMessage).map(item => {
                                return <p className="reg-entry__level">{item}</p>
                              })}
                              <p className="reg-entry__level">
                                <i>{dancer.targetGroups.comment}</i>
                              </p>
                            </div>
                          </div>

                          <div className="grid pattern-50-50">
                            <div className="reg-entry__offer">
                              <h5>
                                <FormattedMessage
                                  id={'dancerMediationLanguage.label'}
                                  defaultMessage={'Vermittlungssprachen'}
                                />
                              </h5>
                              <div className="s2columns">
                                {getMediationLanguages(dancer, formatMessage).map(item => {
                                  return <p className="reg-entry__level">{item}</p>
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="no-event">
                      <FormattedMessage id="noEntries" defaultMessage="keine Einträge" />
                    </div>
                  )}

                  <ModBreadCrumb navigationTree={navigationTree} pathName={pathname} />
                </div>
              )
            },

            {
              columnWidth: 1,
              component: (
                <BlocksRenderer
                  key={'side'}
                  rootState={rootState}
                  blockListData={sidePanelContent}
                />
              )
            }
          ]}
        </ModGridRow>
      )}
    </ModIntlWrapper>
  )
}
