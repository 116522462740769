import * as React from 'react'

import {BlockSlider} from "../../interfaces/InterfacesBlocks";
import ModImgTag from "./ModImgTag";

const MAX_IMG_SIZE = 1000

export interface ModPhotoSwipeProps {
  content: BlockSlider
  sliderIndex: number
  index: number
  onClose: () => void
}

interface ModPhotoSwipeState {
  fullScreen: boolean
  sliderIndex: number
}

export default class ModPhotoSwipe extends React.Component<ModPhotoSwipeProps, ModPhotoSwipeState> {

  photoSwipe: any

  constructor(props) {
    super(props)

    this.photoSwipe = null
    this.getFullscreenSliderId = this.getFullscreenSliderId.bind(this)
    this.destroySwiperComponents = this.destroySwiperComponents.bind(this)
    this.createSwiperComponents = this.createSwiperComponents.bind(this)
  }

  componentWillUnmount() {
    this.destroySwiperComponents()
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.createSwiperComponents(this.props.sliderIndex).catch(console.error)
    }
  }

  componentDidMount() {
    this.createSwiperComponents(this.props.sliderIndex).catch(console.error)
  }


  getFullscreenSliderId() {
    return 'fullscreen-slider-' + this.props.index
  }

  async createSwiperComponents(index = 0) {
    this.destroySwiperComponents()

    const PhotoSwipe = await import('photoswipe')
    const PhotoSwipeUI_Default = await import('photoswipe/dist/photoswipe-ui-default')

    const {content} = this.props
    const items = content.list.map(function (item) {
      let media = item.image.media
      if (!media.mediaType.image) {
        return null
      }
      let w = MAX_IMG_SIZE / media.mediaType.image.width
      let h = MAX_IMG_SIZE / media.mediaType.image.height
      let zoomFactor = Math.min(Math.min(w, h), 1)

      if (!item._i18nCL) {
        return null
      }
      const i18n = item._i18nCL

      return {
        src: ModImgTag.addCloudinaryParamsToUrl(media.url, ['w_' + MAX_IMG_SIZE, 'h_' + MAX_IMG_SIZE, 'c_limit']),
        w: media.mediaType.image.width * zoomFactor,
        h: media.mediaType.image.height * zoomFactor,
        title: i18n.text
      }
    })

    let pswpElement = document.getElementById(this.getFullscreenSliderId())

    let options = {
      index: Math.min(index, items.length - 1),
      history: false,
      focus: false,
      shareEl: false,
      loop: false,
      getThumbBoundsFn: function (index) {
        let rect = {x: 0, y: 0, w: 0}
        let templateBounds = pswpElement.parentElement.getBoundingClientRect()
        rect.x -= templateBounds.left
        rect.y -= templateBounds.top
        return rect
      }
    }

    this.photoSwipe = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options)
    this.photoSwipe.listen('updateScrollOffset', function (_offset) {
      let r = pswpElement.getBoundingClientRect()
      _offset.x += r.left;
      _offset.y += r.top;
    })
    this.photoSwipe.listen('close', () => {
      setTimeout(() => {
        this.destroySwiperComponents()
        if (this.props.onClose) {
          this.props.onClose()
        }
      }, 1000)
      this.setState({
        fullScreen: false,
        sliderIndex: 0
      })
    })
    this.photoSwipe.init()

  }

  destroySwiperComponents() {
    if (this.photoSwipe) {
      try {
        this.photoSwipe.destroy()
      } catch (e) {
      }
      this.photoSwipe = null
    }
  }

  render() {
    return (
      <div id={this.getFullscreenSliderId()} className="pswp" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="pswp__bg"/>
        <div className="pswp__scroll-wrap">
          <div className="pswp__container">
            <div className="pswp__item"/>
            <div className="pswp__item"/>
            <div className="pswp__item"/>
          </div>
          <div className="pswp__ui pswp__ui--hidden">
            <div className="pswp__top-bar">
              <div className="pswp__counter"/>
              <button aria-label="close gallery" className="pswp__button pswp__button--close" title="Close (Esc)"/>
              <button aria-label="toggle full screen" className="pswp__button pswp__button--fs"
                      title="Toggle fullscreen"/>
              <button aria-label="zoom in and out" className="pswp__button pswp__button--zoom" title="Zoom in/out"/>
              <div className="pswp__preloader">
                <div className="pswp__preloader__icn">
                  <div className="pswp__preloader__cut">
                    <div className="pswp__preloader__donut"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
              <div className="pswp__share-tooltip"/>
            </div>
            <div className="pswp__ icon left"/>
            <button aria-label="previous image" className="pswp__button pswp__button--arrow--left"
                    title="Previous (arrow left)">
            </button>
            <div className="pswp__ icon right"/>
            <button aria-label="next image" className="pswp__button pswp__button--arrow--right"
                    title="Next (arrow right)">
            </button>
            <div className="pswp__caption">
              <div className="pswp__caption__center"/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


