export interface FormInputCredentials {
  name: string,
  surname: string,
  street: string,
  postalCode: string,
  city: string,
  canton: string,
  phone: string,
  emergencyPhone: string,
  birthDate: string,
  email: string
  email2: string
}

export enum FormEducationCat {
  BALLETT,
  MODERN,
  URBAN,
  BREAKING,
  JAZZ,
  WORLD,
  ADDITON,
  MORE
}

export interface FormEducationSubject {
  category: FormEducationCat | null,
  subject: string,
  lectures: string,
  years: string
}
export interface FormInputEducation {
  currentSchool: string,
  currentTeacher: string,
  oldSchool: string,

  education: FormEducationSubject[],
}

export enum AuditionType {
  BALLET = 'auditionBallet',
  HIPHOP = 'auditionHipHop',
}

export interface FormTalentscoutExtras {
  preferredCareer: string,
  auditionFor: string
}

export interface FormInputBallettEducation {
  ballettEducationMethod: string,
  ballettEducationLevel: string
}

export interface CredentialsEntity {
  name: string
  surname: string
  street: string
  postalCode: number
  city: string
  canton: string
  phone: string
  birthDate: Date
  email: string
  age: number

  isLangGerman: boolean
  isLangFrench: boolean
  isLangItalian: boolean
}

export interface EducationEducation {
  currentSchool: string
  currentTeacher: string
  oldSchool: string
  preferredCareer: string

  ballett: string
  ballettMethod: string
  ballettLecturesWeek: string
  ballettYears: string

  modern: string
  modernLecturesWeek: string
  modernYears: string

  urban: string
  urbanLecturesWeek: string
  urbanYears: string

  addition: string
  additionLecturesWeek: string
  additionYears: string

  more: string
  moreLecturesWeek: string
  moreYears: string
}

export interface TalentscoutNewRegistryEntity extends CredentialsEntity, EducationEducation {
  timestamp: Date,
  // isOnlyTalent: boolean,
  // isNewKS: boolean,
  // newKSSchoolName: string,
  // newKSSchoolType: string,
  // isOldKS: boolean,
  // oldKSStart: Date,
  // oldKSSchoolName: string,
  // oldKSSchoolType: string,
  // oldKSSchoolTeacher: string,
  // oldKSSchoolRector: string,
  // changeKS: string,
}