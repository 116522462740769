import * as React from 'react'

export enum FormResponseType {
    Success,
    Error,
    Info
}

export interface ModFormResponseProps {
    message: string
    type: FormResponseType
}

export function ModFormResponse({ message, type }: ModFormResponseProps) {

    function typeClass() {
        switch (type) {
            case FormResponseType.Success:
                return 'success';
            case FormResponseType.Error:
                return 'error';
            default:
            case FormResponseType.Info:
                return 'info';
        }
    }
    return <div className={`form response ${typeClass()}`}><p>{message}</p></div>;
}