import * as React from 'react'
import {NavigationNode} from 'interfaces/Interfaces'
import ModAnchorTag from './common/ModAnchorTag'

export interface ModHeaderProps {
  readonly navigationTree: NavigationNode
}

export function ModHeader({navigationTree}: ModHeaderProps) {
  return <header></header>
}
