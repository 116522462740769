import * as React from 'react'
import {BlockElfsight} from 'interfaces/InterfacesBlocks'
import {FormattedMessage} from 'react-intl'
import useCookiebot, {ConsentType} from 'components/common/utils/useCookiebot'

export interface ModBlockElfsightProps {
  content: BlockElfsight
}

export default function ModBlockElfsight({content}: ModBlockElfsightProps) {
  const cookiebot = useCookiebot()

  // elfsight data of type elfsight-app-07afe592-e08e-42fa-9311-f125ad2902f1
  if (!(content._i18nCL && content.elfsightData)) {
    return null
  }

  React.useEffect(() => {
    // @ts-ignore
    if (Cookiebot.consent.marketing) {
      ;(function () {
        // needed a delay hack to make it work
        setTimeout(function () {
          const script = document.createElement('script')
          script.src = 'https://static.elfsight.com/platform/platform.js'
          script.defer = true
          script.setAttribute('data-use-service-core', '')
          document.body.appendChild(script)
        }, 500)
      })()
    }
  }, [cookiebot.consent[ConsentType.marketing]])

  return (
    <div className="front-teaser">
      <div className="frontteaser-title">{content._i18nCL.title}</div>
      <div className="cookieconsent-optout-marketing">
        <div className="cookieconsent-optout-marketing-custom">
          <p>
            <FormattedMessage id={'elfsightCookie.acceptCookiesText1'} defaultMessage={'Bitte'} />{' '}
            <a href="javascript:Cookiebot.renew()" className="underlined">
              <FormattedMessage
                id={'elfsightCookie.acceptCookiesLink'}
                defaultMessage={'akzeptieren Sie die Marketing Cookies'}
              />
            </a>{' '}
            <FormattedMessage
              id={'elfsightCookie.acceptCookiesText2'}
              defaultMessage={'um den Instagram Feed zu sehen.'}
            />
          </p>
        </div>
      </div>
      <div id="elfsight" />
      {cookiebot.consent[ConsentType.marketing] && (
        <div className={content.elfsightData} data-elfsight-app-lazy></div>
      )}
    </div>
  )
}
