import * as React from 'react'
import { IconType, IconSize, ModIcon } from 'components/common/ModIcon'

export interface ModIconButtonProps {
  readonly iconType: IconType
  onClick?(e: MouseEvent): void
  className?: string
  readonly buttonProps?: any
  readonly type: 'button' | 'submit' | 'reset'
  readonly disabled?: boolean
}

export function ModIconButton({ iconType, buttonProps = {}, onClick, className, type, disabled = false }: ModIconButtonProps) {
  if (onClick) buttonProps.onClick = onClick
  return (
    <button type={type} {...buttonProps} className={`icon_button ${className ? className : ''}`} disabled={disabled}>
      <ModIcon type={iconType} size={IconSize.Equal} />
    </button>
  )
}
