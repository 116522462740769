import * as React from "react";
import { TimePeriod } from "./ModFilter";
import { ModIconButton } from "../ModIconButton";
import { IconType } from "components/common/ModIcon";
import { ModDatepicker } from "../ModDatepicker/ModDatepicker";

/**
 *
 */
export interface ModFilterDatepickerProps {
  readonly label: string;
  onSelectionChange?(selected: TimePeriod): void;
}

export function ModFilterDatepicker({
  label,
  onSelectionChange
}: ModFilterDatepickerProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [pickerState, setPickerState] = React.useState({
    startDate: null,
    endDate: null
  });

  function updateStartDate(date: Date) {
    setPickerState({ startDate: date, endDate: pickerState.endDate });
  }

  function updateEndDate(date: Date) {
    setPickerState({ startDate: pickerState.startDate, endDate: date });

    if (onSelectionChange) {
      onSelectionChange({
        startDate: pickerState.startDate,
        endDate: date
      });
    }
  }

  return (
    <div className="datepicker-container">
      <label>{label}</label>
      <ModIconButton
        type="button"
        iconType={isOpen ? IconType.Chevron_Up : IconType.Chevron_Down}
        onClick={e => setIsOpen(!isOpen)}
      />
      <ModDatepicker
        className={isOpen ? "show" : "hide"}
        onStartDateChange={updateStartDate}
        onEndDateChange={date => {
          updateEndDate(date);
          setIsOpen(false);
        }}
      />
      {/* <div className="selected-value">{label}</div> */}
    </div>
  );
}
