import * as React from 'react'
import {IsomorphicURLSearchParams} from '../../common/IsomorphicURL'

import {NavigationNode, PageType} from '../../interfaces/Interfaces'
import ModSvg from './ModSvg'
import NavLink from './NavLink'
import {NavigationNodeContext} from '../ModApp'
import {getCurrentLanguageOrFallBackByPath} from '../../common/Languages'
import {findNavigationNodeById} from '../../common/CmsUtils'
import {Link} from 'interfaces/InterfacesEntities'
import {labelToAnchor} from './utils/DDUtil'

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export interface ModAnchorTagProps {
  linkObject: Link
  hasIcon?: boolean
  className?: string
}

export default class ModAnchorTag extends React.Component<ModAnchorTagProps, {}> {
  static getLinkIconType(linkObject, hasIcon) {
    if (!hasIcon) return null

    let iconType = null
    if (linkObject.hasOwnProperty('url')) {
      if (!linkObject.url._i18nCL || linkObject.url._i18nCL === '') {
        iconType = <ModSvg url={require('static/img/svg/but_link_doc.svg')} id="icon" />
      }
      if (linkObject.url._i18nCL.startsWith('mailto:')) {
        iconType = <ModSvg url={require('static/img/svg/but_link_filled.svg')} id="icon" />
      } else {
        iconType = <ModSvg url={require('static/img/svg/but_link_external_filled.svg')} id="icon" />
      }
    } else if (linkObject.hasOwnProperty('navigationNode')) {
      iconType = <ModSvg url={require('static/img/svg/but_link_filled.svg')} id="icon" />
    } else if (linkObject.hasOwnProperty('overlay')) {
      iconType = <ModSvg url={require('static/img/svg/but_link_filled.svg')} id="icon" />
    } else if (linkObject.hasOwnProperty('document')) {
      if (IMAGE_TYPES.indexOf(linkObject.document.type) > -1) {
        iconType = <ModSvg url={require('static/img/svg/but_link_img.svg')} id="icon" />
      } else {
        iconType = <ModSvg url={require('static/img/svg/but_link_doc.svg')} id="icon" />
      }
    }
    return iconType
  }

  static invalidateChildren(children) {
    if (typeof children === 'string' || children instanceof Array) {
      return <span>{children}</span>
    } else if (typeof children === 'undefined') {
      return null
    }
    return children
  }

  static pathExists(url: string) {
    if (url) return true
    // else console.log('The language url is undefined in ModAnchorTag')
  }

  render() {
    let children = ModAnchorTag.invalidateChildren(this.props.children)

    if (!(typeof this.props.linkObject === 'object')) {
      return children
    }

    const {linkObject, hasIcon} = this.props

    let className = ''
    if (this.props.className) {
      className = this.props.className
    }

    try {
      const linkIcon = ModAnchorTag.getLinkIconType(linkObject, hasIcon)

      if (linkObject.hasOwnProperty('url')) {
        if (!linkObject.url._i18nCL || linkObject.url._i18nCL === '') return children
        const i18nCL = linkObject.url._i18nCL

        if (i18nCL.startsWith('mailto:')) {
          return (
            <a className={'a-mail ' + className} href={i18nCL}>
              {linkIcon}
              {children}
            </a>
          )
        }
        if (i18nCL.includes('http')) {
          return (
            <a className={'a-external ' + className} href={i18nCL} target="_blank" rel="noopener">
              {linkIcon}
              {children}
            </a>
          )
        }
        return (
          <a
            className={'a-external ' + className}
            href={`//${i18nCL}`}
            target="_blank"
            rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      } else if (linkObject.hasOwnProperty('overlay') && typeof linkObject.overlay === 'string') {
        return (
          <NavigationNodeContext.Consumer>
            {({location, rootNavigationNode}) => {
              let urlSearchParams = new IsomorphicURLSearchParams(`overlay=${linkObject.overlay}`)
              return (
                <NavLink
                  className={'a-overlay ' + className}
                  to={{
                    pathname: location.pathname,
                    query: urlSearchParams
                  }}>
                  {children}
                </NavLink>
              )
            }}
          </NavigationNodeContext.Consumer>
        )
      } else if (linkObject.hasOwnProperty('internalLink')) {
        const internalLink = linkObject.internalLink

        return (
          <NavigationNodeContext.Consumer>
            {({location, rootNavigationNode}) => {
              let navigationNode: NavigationNode
              if (typeof internalLink.node === 'string') {
                navigationNode = findNavigationNodeById(internalLink.node, rootNavigationNode)
              } else {
                navigationNode = findNavigationNodeById(internalLink.node._id, rootNavigationNode)
              }

              if (!navigationNode) {
                // console.warn(`navigationNode ${internalLink.node} not found`)
                return children
              }

              if (!navigationNode._i18nCL) {
                // console.warn(
                //   `navigation node ${navigationNode.internalDescription} is missing language`
                // )
                return (
                  <p className="small-text">
                    navigation node <strong>{navigationNode.internalDescription}</strong> is missing
                    language
                  </p>
                )
              }
              let url = ModAnchorTag.pathExists(navigationNode._i18nCL.relativeUrl)
                ? navigationNode._i18nCL.relativeUrl
                : ''
              if (
                internalLink.language &&
                navigationNode.i18n &&
                navigationNode.i18n.hasOwnProperty(internalLink.language)
              ) {
                url = ModAnchorTag.pathExists(
                  navigationNode.i18n[internalLink.language].relativeUrl
                )
                  ? navigationNode.i18n[internalLink.language].relativeUrl
                  : ''
              }

              const currentLanguage = getCurrentLanguageOrFallBackByPath(location.pathname)

              if (internalLink.anchor) {
                if (internalLink.anchor.blockTitle) {
                  const blockTitle = internalLink.anchor.blockTitle
                  if (blockTitle.i18n && blockTitle.i18n.hasOwnProperty(currentLanguage)) {
                    const anchor = labelToAnchor(
                      internalLink.anchor.blockTitle.i18n[currentLanguage].anchorLabel
                    )
                    return (
                      <NavLink
                        className={'a-internal ' + className}
                        to={{
                          pathname: url,
                          hash: '#' + anchor.toLowerCase()
                        }}>
                        {linkIcon}
                        {children}
                      </NavLink>
                    )
                  }
                } else if (internalLink.anchor.blockRichText) {
                  const blockRichText = internalLink.anchor.blockRichText
                  if (blockRichText.i18n && blockRichText.i18n.hasOwnProperty(currentLanguage)) {
                    const anchor = labelToAnchor(blockRichText.i18n[currentLanguage].anchor)
                    return (
                      <NavLink
                        className={'a-internal ' + className}
                        to={{
                          pathname: url,
                          hash: '#' + anchor.toLowerCase()
                        }}>
                        {linkIcon}
                        {children}
                      </NavLink>
                    )
                  }
                }
              }
              return (
                <NavLink
                  className={'a-internal ' + className}
                  to={{
                    pathname: url
                  }}>
                  {linkIcon}
                  {children}
                </NavLink>
              )
            }}
          </NavigationNodeContext.Consumer>
        )
      } else if (linkObject.hasOwnProperty('staticPage')) {
        const page = linkObject.staticPage
        let relativeUrl
        let type

        if (page.hasOwnProperty(PageType.NewsDetail)) {
          type = PageType.NewsDetail
        } else {
          // console.warn(`page ${linkObject.staticPage} not found`)
          return children
        }

        relativeUrl = page[type]._i18nCL.relativeUrl
        if (page.language && page[type].i18n && page[type].i18n.hasOwnProperty(page.language)) {
          relativeUrl = page[type].i18n[page.language].relativeUrl
        }

        return (
          <NavLink
            className={'a-internal ' + className}
            to={{
              pathname: relativeUrl
            }}>
            {linkIcon}
            {children}
          </NavLink>
        )
      } else if (
        linkObject.hasOwnProperty('document') &&
        typeof this.props.linkObject.document === 'object'
      ) {
        let mediaUrl = linkObject.document.media.backend.cloudinary.secure_url
        if (linkObject.document._i18nCL && linkObject.document._i18nCL.media) {
          // use i18 media if present
          mediaUrl = linkObject.document._i18nCL.media.backend.cloudinary.secure_url
        }
        return (
          <a className={'a-document ' + className} href={mediaUrl} target="_blank" rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      }
    } catch (e) {
      console.warn(e)
    }

    return children
  }
}
