export const tags = {
  website: 'website',
  domain: 'domain',
  page: 'page',
  location: 'location',

  // navi
  navigationNode: 'navigationNode',
  footer: 'footer',

  // blocks
  blockRichText: 'blockRichText',
  blockTitle: 'blockTitle',
  //blockSlider: 'blockSlider',
  blockSlider: 'blockPictures',
  blockImageText: 'blockImageText',
  blockAccordion: 'blockAccordion',
  blockAnchors: 'blockAnchors',
  blockQuicklinks: 'blockQuicklinks',
  blockForm: 'blockForm',
  blockAdvertisment: 'blockAdvertisment',
  blockCourseSignUp: 'blockCourseSignUp',
  blockFormSummerdance: 'blockFormSummerdance',
  blockFormTalentscout: 'blockFormTalentscout',
  blockFormTalentscoutRecurring: 'blockFormTalentscoutRecurring',
  blockLink: 'blockLink',
  blockNewsletter: 'blockNewsletter',
  blockSpacer: 'blockSpacer',
  blockCookieDisclaimer: 'CookieDisclaimer',

  // entities
  news: 'news',
  newsAuthor: 'newsAuthor',
  events: 'events',
  venue: 'venue',
  eventType: 'eventType',
  registeredDancer: 'registeredDancer',
  danceMediation: 'danceMediation',
  talentscoutRegistry: 'talentscoutingRegistry',
  formEducation: 'formEducation',
  formCredentials: 'formCredentials',
  summerdanceRegistry: 'summerdanceRegistry',
  formAttachment: 'formAttachment',

  media: 'media',

  translations: 'translations',
  product: 'product',
  overlay: 'overlay'
}
