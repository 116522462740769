import * as React from 'react'
import {BlockAnchors} from 'interfaces/InterfacesBlocks'
import {NavigationNodeContext} from '../ModApp'
import NavLink from 'components/common/NavLink'
import {ModIcon, IconType, IconSize} from 'components/common/ModIcon'
import {tags} from 'vo/Tags'
import {labelToAnchor} from './utils/DDUtil'

export interface ModAnchorsProps {
  // anchors are generated from BlockTitle
  blocksToGenerateAnchorsFrom: any[]
}

export function ModAnchors({blocksToGenerateAnchorsFrom}: ModAnchorsProps) {
  if (blocksToGenerateAnchorsFrom && blocksToGenerateAnchorsFrom.length == 0) {
    // console.warn("cannot generate anchors from empty blocklist");
    return null
  }

  const naviContext = React.useContext(NavigationNodeContext)

  blocksToGenerateAnchorsFrom = blocksToGenerateAnchorsFrom.map((_item, index) => {
    const typeKey = Object.keys(_item)[0]
    const content = _item[typeKey]
    return {typeKey, content}
  })

  let html = blocksToGenerateAnchorsFrom.reduce((anchorsList, item, index) => {
    const {typeKey, content} = item

    if (!content.block._i18nCL) {
      return anchorsList
    }

    const i18n = content.block._i18nCL

    let anchorMarkup = null
    switch (typeKey) {
      case tags.blockTitle:
        const anchor = labelToAnchor(i18n.anchorLabel)
        anchorMarkup = (
          <NavLink
            key={anchor}
            className={'a-internal anchor-jump '}
            to={{
              pathname: naviContext.location.pathname,
              hash: '#' + anchor
            }}>
            <ModIcon type={IconType.Chevron_Right} size={IconSize.Smaller} />
            {i18n.anchorLabel}
          </NavLink>
        )
        break
      default:
        anchorMarkup = null
    }

    anchorsList.push(anchorMarkup)
    return anchorsList
  }, [])

  return <div className="block-anchors">{html}</div>
}
