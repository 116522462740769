import * as React from 'react'

import {PageTemplateProps} from './IPageTemplateProps'
import ModBreadCrumb from '../ModBreadCrumb'
import {ModGridRow} from 'components/layout/ModGridRow'
import {BlocksRenderer} from 'components/blocks/BlocksRenderer'
import {ModAnchors} from 'components/common/ModAnchors'
import {PageTemplate} from 'interfaces/Interfaces'

export interface DetailPageTemplateProps extends PageTemplateProps, PageTemplate {}

export function DetailPageTemplate({
  navigationTree,
  currentLanguage,
  rootState,
  header,
  blocks,
  sidePanelContent,
  pathname
}: DetailPageTemplateProps) {
  if (!header._i18nCL) {
    return null
  }

  return (
    <ModGridRow>
      {[
        {
          columnWidth: 2,
          component: (
            <div key={'content'} className="main-content">
              <ModPageHeader title={header._i18nCL.title} lead={header._i18nCL.lead} />
              {header.anchors && <ModAnchors blocksToGenerateAnchorsFrom={blocks} />}
              <BlocksRenderer
                blockListData={blocks}
                rootState={rootState}
                currentLanguage={currentLanguage}
              />
              <ModBreadCrumb navigationTree={navigationTree} pathName={pathname} />
            </div>
          )
        },
        {
          columnWidth: 1,
          component: (
            <BlocksRenderer
              key={'side'}
              rootState={rootState}
              blockListData={sidePanelContent}
              currentLanguage={currentLanguage}
            />
          )
        }
      ]}
    </ModGridRow>
  )
}

export interface ModPageHeaderProps {
  readonly title?: string
  readonly lead?: string
}

export function ModPageHeader({title, lead}: ModPageHeaderProps) {
  return (
    <div className="title">
      {title && <h1>{title}</h1>}
      {lead && <p className="lead">{lead}</p>}
    </div>
  )
}
