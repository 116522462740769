import * as React from 'react'
import {BlockTitle} from '../../interfaces/InterfacesBlocks'
import {labelToAnchor} from 'components/common/utils/DDUtil'

export interface ModBlockTitleProps {
  content: BlockTitle
}

export default function ModBlockTitle({content}: ModBlockTitleProps) {
  if (!(content._i18nCL && content._i18nCL.title)) {
    return null
  }
  const i18n = content._i18nCL

  const a = i18n.anchorLabel ? <a id={`anchor_${labelToAnchor(i18n.anchorLabel)}`} /> : null

  return (
    <div className={'block-title'}>
      {a}
      <h2>{i18n.title}</h2>
    </div>
  )
}
