import { PageTemplateProps } from "../IPageTemplateProps";
import React = require("react");
import { ModGridRow } from "components/layout/ModGridRow";
import { ModNewsDetail } from "./ModNewsList";
import ModBreadCrumb, { BreadCrumbsExtras } from "components/ModBreadCrumb";
import { NewsDetailPage } from "interfaces/InterfacesEntities";


export interface NewsDetailPageTemplateProps extends PageTemplateProps{
  content: NewsDetailPage
  breadcrumbsExtras: BreadCrumbsExtras
}

export function NewsDetailPageTemplate({
  navigationTree,
  currentLanguage,
  rootState,
  pathname,
  content,
  breadcrumbsExtras
}: NewsDetailPageTemplateProps) {

  return (
    <main>
      <ModGridRow>
        {[
          {
            columnWidth: 2,
            component: (
              <div key="content" className="news-detail main-content">

                <ModNewsDetail content={content} isCollapsed={false} />

                <ModBreadCrumb
                  navigationTree={navigationTree}
                  pathName={pathname}
                  pageExtras={breadcrumbsExtras}
                />
              </div>
            )
          },

          {
            columnWidth: 1,
            component: (
              <div></div>
            )
          }
        ]}
      </ModGridRow>
    </main>
  );
}
