import { StringFilter, TimeFilter } from './ClassFilter'
import { Filter, TimePicker, FilterType, FilterOption, TimePeriod } from './ModFilter'

export interface FilterSelection<V> {
  key: string
  selection: V
}

export interface StringFilterSelection extends FilterSelection<{ id: string; label: string }[]> { }
export interface TimeFilterSelection extends FilterSelection<{ startDate: Date; endDate: Date }> { }

// Better naming for react probably: FilterStateModel
export class FilterViewModel {
  filterMap: Map<string, StringFilter | TimeFilter>

  constructor(filters?: Array<Filter | TimePicker>) {
    this.filterMap = new Map()
    if (filters) {
      this.initFilterMap(filters)
    }
  }

  initFilterMap(filters: Array<Filter | TimePicker>) {
    for (let filter of filters) {
      if (filter.type == FilterType.String) {
        this.filterMap.set(filter.id, new StringFilter(filter.id, filter.label, filter.options, null))
      } else {
        this.filterMap.set(filter.id, new TimeFilter(filter.id, filter.label, null))
      }
    }

  }

  private isTimePeriod(selection: FilterOption | TimePeriod): selection is TimePeriod {
    return (selection as TimePeriod).startDate !== undefined
  }

  updateActiveFilter(filterKey: string, selection: FilterOption | TimePeriod | null) {
    let filterGroup = this.filterMap.get(filterKey)

    if (selection == null) {
      filterGroup.value == null
    } else {
      if (this.isTimePeriod(selection)) {
        if (filterGroup.type == FilterType.TimePicker) filterGroup.value = selection
        else console.warn('invalid type match. Cannot add StringSelection to TimeFilter')
      } else {
        if ((filterGroup.type = FilterType.String)) {
          if (filterGroup.value == null) filterGroup.value = []
          filterGroup.value.push(selection)
        } else console.warn('invalid type match. Cannot add TimeSelection to StringFilter')
      }
    }
  }

  deleteActiveFilter(filterKey: string, itemId: string) {
    let filterGroup = this.filterMap.get(filterKey)
    if (filterGroup.value) {
      if (filterGroup.type == FilterType.TimePicker) {
        filterGroup.value = null
      } else {
        filterGroup.value = filterGroup.value.filter(filterString => filterString.id != itemId)
        if (filterGroup.value.length == 0) filterGroup.value = null
      }
    }
  }

  deleteAllActiveFilter(filterKey: string) {
    let filterGroup = this.filterMap.get(filterKey)
    if (filterGroup.value) {
      filterGroup.value = null
    }
  }

  getFilterMap(): Map<string, StringFilter | TimeFilter> {
    return this.filterMap
  }

  getFilters(): Array<StringFilter | TimeFilter> {
    return [...this.filterMap.values()]
  }

  getSimpleSelection(): Array<StringFilterSelection | TimeFilterSelection> {
    let simpleSelection = []
    let filters = [...this.filterMap.values()]

    for (let filter of filters) {
      if (filter.value != null) {
        simpleSelection.push({ key: filter.key, selection: filter.value })
      }
    }
    return simpleSelection
  }

  clone(): FilterViewModel {
    let clone = new FilterViewModel()
    clone.filterMap = this.filterMap
    return clone
  }
}
