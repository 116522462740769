import * as React from 'react'
import {NewsDetailPage, NewsAuthor} from 'interfaces/InterfacesEntities'
import ModRichText from 'components/common/ModRichText'
import {FormattedMessage} from 'react-intl'
import {ModDateTime} from '../../ui/ModDateTime'
import {ModShareButtons} from 'components/common/ModShareButtons'
import ModAnchorTag from 'components/common/ModAnchorTag'
import {createStaticPageLinkObject} from 'common/CmsUtils'
import {PageType} from 'interfaces/Interfaces'
import ModBlockImage from 'components/blocks/ModBlockImage'
import {ModPageHeader} from '../DetailPageTemplate'
import {IconSize, IconType, ModIcon} from 'components/common/ModIcon'

export interface ModNewsListProps {
  news: NewsDetailPage[]
  isCollapsed: boolean
}

export function ModNewsList({news, isCollapsed}: ModNewsListProps) {
  return (
    <div className="news-list">
      {news.length == 0 ? (
        <div className="no-news">
          <p>
            <FormattedMessage id="noEntries" defaultMessage="keine Einträge" />
          </p>
        </div>
      ) : (
        news.map(newsitem => {
          return (
            <React.Fragment key={newsitem._i18nCL.title}>
              {newsitem._i18nCL ? (
                <ModNewsDetail content={newsitem} isCollapsed={isCollapsed} />
              ) : null}
            </React.Fragment>
          )
        })
      )}
    </div>
  )
}

/**
 * news detail
 */
export interface ModNewsDetailProps {
  content: NewsDetailPage
  isCollapsed: boolean
}

export function ModNewsDetail({content, isCollapsed}: ModNewsDetailProps) {
  if (!content._i18nCL) {
    return null
  }

  return (
    <div className="news-entry">
      <h3>{content._i18nCL.title}</h3>
      <div className="published">
        <ModDateTime date={content.publishDate} hideTime={true} showDay={true} />
        <Authors names={content.authors} />
      </div>
      <div className={isCollapsed ? 'news-lead news-collapsed' : 'news-lead'}>
        {!isCollapsed && content.image && <ModBlockImage content={{image: content.image}} />}
        {!isCollapsed && <ModPageHeader lead={content._i18nCL.abstract}></ModPageHeader>}
        {isCollapsed ? (
          <div className="news-detail-lead">{content._i18nCL.abstract}</div>
        ) : (
          <ModRichText richText={content._i18nCL.text} />
        )}
        {isCollapsed && (
          <ModAnchorTag linkObject={createStaticPageLinkObject(content, PageType.NewsDetail)}>
            <FormattedMessage id="more" defaultMessage="mehr" />
            <ModIcon type={IconType.Arrow_Right} />
          </ModAnchorTag>
        )}
      </div>
    </div>
  )
}

/**
 * news authors
 */
interface AuthorsProps {
  names: NewsAuthor[]
}
function Authors({names}: AuthorsProps) {
  return (
    <div className="authors">
      <FormattedMessage id="news.fromAuthor" defaultMessage="Von" />
      {': '}
      {names.reduce((acc, name, index) => {
        let newAuthor = index > 0 ? ' / ' + name.name : name.name
        return acc + newAuthor
      }, '')}
    </div>
  )
}
