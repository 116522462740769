import * as React from 'react'
import { injectIntl } from 'react-intl'

import { NavigationNode, Page, PageType, StaticPage } from "../interfaces/Interfaces";
import { isArticleActiveNow, findNavigationNodeById, createNodeLinkObject, createStaticPageLinkObject } from "../common/CmsUtils";
import RootState from "../vo/RootState";

import { supportedLanguages } from "../common/Languages";
import ModAnchorTag from './common/ModAnchorTag';
import { ModDynamicSwitch } from './ui/ModDynamicSwitch';

export interface ModLanguageSwitcherProps {
  navigationTree: NavigationNode
  rootState: RootState
  currentLanguage: string
}

function ModLanguageSwitcher({ navigationTree, rootState, currentLanguage }: ModLanguageSwitcherProps) {
  let languageObjects = {}

  if (rootState.currentPage) {
    let currentPage
    let pageType
    try {
      try {
        currentPage = rootState.pages[rootState.currentPage.type][rootState.currentPage.id]
        pageType = rootState.currentPage.type
      } catch (error) {
        //default
      }

      if (pageType == PageType.Page) {
        const page = currentPage as Page

        //get navigation node form tree, if it's missing on page
        let navigationNode
        if (typeof page.navigationNode === 'string') {
          navigationNode = findNavigationNodeById(page.navigationNode, navigationTree)
        } else {
          navigationNode = page.navigationNode
        }

        //create link objects for each active language of this page
        supportedLanguages.forEach((lang) => {
          if (navigationNode.i18n.hasOwnProperty(lang) && isArticleActiveNow(page, lang)) {
            languageObjects[lang] = createNodeLinkObject(navigationNode, lang)
          }
        })
      } else {
        let staticPage = currentPage as StaticPage
        supportedLanguages.forEach((lang) => {
          if (staticPage.i18n && staticPage.i18n.hasOwnProperty(lang) && staticPage.i18n[lang].relativeUrl) {
            languageObjects[lang] = createStaticPageLinkObject(staticPage, pageType, lang)
          }
        })
      }
    } catch (error) { }
  }

  //fallback to the root page if a language is missing
  supportedLanguages.forEach((lang) => {
    if (!languageObjects.hasOwnProperty(lang) && navigationTree.i18n.hasOwnProperty(lang)) {
      languageObjects[lang] = createNodeLinkObject(navigationTree, lang)
    }
  })

  function getLanguageNode(key) {
    return (
      <ModAnchorTag key={key} linkObject={languageObjects[key]}>
        {key}
      </ModAnchorTag>
    );
  }

  // create language key to language link map
  let languageItems = Object.keys(languageObjects).reduce((acc, key) => {
    return acc.set(key, getLanguageNode(key));
  }, new Map<string, React.ReactNode>());


  return (
    <div className="navigation-meta">
      <div className="navigation-languages">
        <ModDynamicSwitch items={languageItems} selected={currentLanguage} />
      </div>
    </div>
  );
}

//export default injectIntl(ModLanguageSwitcher)
export default ModLanguageSwitcher