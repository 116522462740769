import * as React from 'react'
import {ModInput} from 'components/ui/ModInput'
import {ModGridRow} from 'components/layout/ModGridRow'
import {ModDropDown} from 'components/ui/ModDropDown'
import {FormEducationCat, FormEducationSubject} from './FormInterfaces'

export interface ModEducationInputsProps {
  readonly labels: {mainSubject: string; lectures: string; years: string; addMore: string}
  readonly education: FormEducationSubject[]
  readonly options: {label: string; cat: FormEducationCat; catLabel: string}[]
  readonly required?: boolean
  onAddSubject(): void
  onSubjectChange(values: {idx: number; key: string; value: string | FormEducationCat}[]): void
}

export function ModEducationInputs({
  labels,
  options,
  education,
  onSubjectChange,
  onAddSubject,
  required = false
}: ModEducationInputsProps) {
  options.sort((a, b) => a.label.localeCompare(b.label)) // sort alphabetically

  const [dropdownHasValueForIndex, setDropdownHasValueForIndex] = React.useState(
    education.map(() => false)
  )

  return (
    <>
      {education.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <ModDropDown
              required={index == 0}
              label={index == 0 ? labels.mainSubject : 'weitere Ausbildungen'} // TODO add label to block entity
              options={options}
              itemToString={i => {
                return i.label
              }}
              onSelectionChange={item => {
                // TODO can item be null / none ? -> can not be delted so no
                if (item != null) {
                  var oldState = dropdownHasValueForIndex
                  oldState[index] = true
                  setDropdownHasValueForIndex(oldState)
                } else {
                  var oldState = dropdownHasValueForIndex
                  oldState[index] = false
                  setDropdownHasValueForIndex(oldState)
                }
                onSubjectChange([
                  {idx: index, key: 'category', value: item.cat},
                  {idx: index, key: 'subject', value: item.label}
                ])
              }}
            />
            <ModGridRow>
              {[
                {
                  columnWidth: 1,
                  component: (
                    <div>
                      <ModInput
                        type={'number'}
                        label={labels.lectures}
                        value={item.lectures}
                        onChange={e =>
                          onSubjectChange([{idx: index, key: 'lectures', value: e.target.value}])
                        }
                        // TODO diese Felder müssen nur true sein, wenn etwas im dropdown ausgewählt wurde.
                        // -> was passiert wenn nebenfach wieder weggenommen wird? werden dann diese felder trotzdem ins excel geschrieben? -> can not be deleted?
                        required={index == 0 || dropdownHasValueForIndex[index]}
                      />
                    </div>
                  )
                },
                {
                  columnWidth: 1,
                  component: (
                    <div>
                      <ModInput
                        type={'number'}
                        label={labels.years}
                        value={item.years}
                        onChange={e =>
                          onSubjectChange([{idx: index, key: 'years', value: e.target.value}])
                        }
                        required={index == 0 || dropdownHasValueForIndex[index]}
                      />
                    </div>
                  )
                }
              ]}
            </ModGridRow>
          </React.Fragment>
        )
      })}
      <button
        className="add-more"
        onClick={e => {
          e.preventDefault()
          onAddSubject()
        }}>
        {labels.addMore}
      </button>
    </>
  )
  // const [countEducationInputs, setCountEducationInputs] = React.useState(1)
  // const [educationInputs, setEducationInputs] = React.useState(education)

  // function onSelectionChange(item: string, index: number) {
  //   if (onSubject) {
  //     onSubject({ category: FormEducationCat.BALLETT, subject: item, years: '1', lectures: '1' })
  //   }
  // }

  // function createEducationInputs() {

  //   const EducationInputs = <>
  //     <ModDropDown label={labels.mainSubject} options={ballettOptions} itemToString={i => { return i }} onSelectionChange={onSelectionChange} />
  //     {/* <ModInput type={"text"} label={mainSubject} /> */}
  //     <ModGridRow>
  //       {[
  //         {
  //           columnWidth: 1,
  //           component: (
  //             <div>
  //               <ModInput type={"number"} label={labels.lectures} />
  //             </div>
  //           )
  //         },
  //         {
  //           columnWidth: 1,
  //           component: (
  //             <div>
  //               <ModInput type={"number"} label={labels.years} />
  //             </div>
  //           )
  //         }
  //       ]}
  //     </ModGridRow></>

  //   let allInputs = []
  //   for (let i = 0; i < countEducationInputs; i++) {
  //     allInputs.push(EducationInputs)
  //   }

  //   return allInputs
  // }

  // return (
  //   <>
  //     {createEducationInputs()}
  //     <button onClick={e => { e.preventDefault(); setCountEducationInputs(countEducationInputs + 1) }}> weitere Ausbildungen</button>
  //   </>
  // )
}
