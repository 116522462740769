import * as React from 'react'
import {IntlProvider, addLocaleData} from 'react-intl'
import * as isoFetch from 'isomorphic-fetch'
import ModContent from './ModContent'
import ModCookiePolicy from './ModCookiePolicy'
import {createMetaFields} from 'common/MetaFields'
import {NavigationNode} from '../interfaces/Interfaces'
import ModFooter from './ModFooter'

import {
  getCurrentLanguageOrFallBackByPath,
  SupportedLangauges,
  getCurrentLanguageEnumOrFallBackByPath
} from 'common/Languages'
import {addClassToElement, removeClassFromElement, addCurrentLangRecursive} from 'common/Utils'

import * as en from 'react-intl/locale-data/en'
import * as de from 'react-intl/locale-data/de'
import * as fr from 'react-intl/locale-data/fr'
import * as it from 'react-intl/locale-data/it'
import RootState, {LoadingState} from '../vo/RootState'
import CmsRouter, {RouterLocation, RouterLocationExtended} from '../control/CmsRouter'
import {searchNavigationNodeByUrl, createNodeLinkObject} from '../common/CmsUtils'
import ModLanguageSwitcher from './ModLanguageSwitcher'
import {ModHeader} from './ModHeader'
import {ModIcon, IconType} from './common/ModIcon'
import ModAnchorTag from './common/ModAnchorTag'

addLocaleData([...en, ...de, ...fr, ...it])
export interface ModAppProps {
  location: RouterLocationExtended
  router: CmsRouter
  APP_PROPS?: RootState
}

const defaultContext: {
  location: RouterLocation
  router: CmsRouter
  rootNavigationNode: NavigationNode
  currentLanguage: SupportedLangauges
  settings: {
    staticNewsNode: NavigationNode
  }
} = {
  router: null,
  location: {
    pathname: '',
    query: null
  },
  rootNavigationNode: null,
  currentLanguage: SupportedLangauges.EN,
  settings: {
    staticNewsNode: null
  }
}

const defaultNavigation: NavigationNode = null

export const NavigationNodeContext = React.createContext(defaultContext)

export default class ModApp extends React.Component<ModAppProps, RootState> {
  constructor(props) {
    super(props)

    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)

    if (typeof window !== 'undefined') {
      // runs in frontend
      if (window.hasOwnProperty('APP_PROPS')) {
        const rootState: RootState = Object.assign(new RootState(), window['APP_PROPS'])
        this.state = rootState
      }
    } else if (this.props.APP_PROPS) {
      // runs in backend
      this.state = this.props.APP_PROPS
    }

    this.setStateAndCurrentLang = this.setStateAndCurrentLang.bind(this)
    this.setStateBy = this.setStateBy.bind(this)
    this.setHeadMetaInformation = this.setHeadMetaInformation.bind(this)
    this.hasUpdatedLocation = this.hasUpdatedLocation.bind(this)
    this.onCloseModalView = this.onCloseModalView.bind(this)
    this.setBodyState = this.setBodyState.bind(this)
  }

  onCloseModalView() {
    this.props.router.push({
      hash: '',
      pathname: this.props.location.pathname,
      query: null
    })
  }

  setStateAndCurrentLang(newState) {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    newState = Object.assign(JSON.parse(JSON.stringify(this.state)), newState)
    addCurrentLangRecursive(newState, currentLanguage)
    this.setState(newState)
  }

  setHeadMetaInformation() {
    const metaFields = createMetaFields(this.state)
    document.title = metaFields.title
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))
  }

  hasUpdatedLocation(lastProps, nextProps) {
    try {
      if (lastProps.location.href !== nextProps.location.href) {
        return true
      }
    } catch (e) {}
    return false
  }

  isUpToDate(dateTime: string): boolean {
    return new Date().getTime() - 1000 * 60 < new Date(dateTime).getTime()
  }

  async componentWillUpdate(nextProps: ModAppProps, nextState: RootState) {
    if (this.hasUpdatedLocation(this.props, nextProps)) {
      const prevLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
      const currentLanguage = getCurrentLanguageOrFallBackByPath(nextProps.location.pathname)

      const nextNode = searchNavigationNodeByUrl(
        this.state.websiteSettings.rootNavigationNode,
        nextProps.location.pathname,
        currentLanguage
      )

      let isUpToDate = false
      /*if (
        nextNode &&
        this.state.pages.hasOwnProperty(nextNode.pageId) &&
        this.state.pages[nextNode.pageId].loadedDateTime
      ) {
        isUpToDate = this.isUpToDate(this.state.pages[nextNode.pageId].loadedDateTime)
      }*/

      // if (isUpToDate) {
      //   this.setState({
      //     currentPageId: nextNode.pageId
      //   })
      //   return
      // } else {
      this.setState({
        loadingState: LoadingState.loading
      })
      // }

      let fetchParams = new URL(nextProps.location.href).searchParams
      fetchParams.append('path', nextProps.location.pathname)
      const paramsString = fetchParams.toString()

      try {
        const responde = await isoFetch('/api/content?' + paramsString)

        if (responde.status === 404) {
          console.error('server responded with 404')
          this.setState({
            loadingState: LoadingState.idle,
            currentPage: null
          })
        } else {
          const page = await responde.json()

          let pages = Object.assign({}, this.state.pages)
          if (!pages[page._pageType]) pages[page._pageType] = {}
          pages[page._pageType][page._id] = page

          const newSate = {
            loadingState: LoadingState.idle,
            currentPage: {
              type: page._pageType,
              id: page._id
            },
            pages
          }
          if (currentLanguage != prevLanguage) {
            this.setStateAndCurrentLang(newSate)
          } else {
            this.setState(newSate)
          }
        }
      } catch (error) {
        console.error(error)
        this.setState({
          loadingState: LoadingState.offline,
          currentPage: null
        })
      }
    }
  }

  componentDidMount() {
    this.toggleEditMode()
    this.setBodyState()

    if (typeof window !== 'undefined') {
      if (this.props.location.hash && this.props.location.hash.length > 0) {
        // TODO experimental internal link with anchor support
        setTimeout(() => {
          const divBlock = document.getElementById(
            'anchor_' + this.props.location.hash.substring(1)
          )
          if (divBlock) {
            divBlock.scrollIntoView()
          }
        }, 400)
      }
    }
  }

  /**
   * activates edit mode which displays direct link to the karma editor
   */
  toggleEditMode() {
    if (typeof window !== 'undefined') {
      let keysDown = {}
      let switchMode = false

      function isValidKey(key) {
        return key === 'Control' || key === 'Shift' || key === 'E'
      }

      document.addEventListener(
        'keydown',
        event => {
          if (isValidKey(event.key)) {
            keysDown[event.key] = true
            if (Object.keys(keysDown).length === 3) {
              switchMode = true
            }
          }
        },
        false
      )
      document.addEventListener(
        'keyup',
        event => {
          if (isValidKey(event.key)) {
            delete keysDown[event.key]
            if (switchMode) {
              switchMode = false
              this.setState({editMode: !this.state.editMode})
            }
          }
        },
        false
      )
    }
  }

  setBodyState() {
    if (typeof window !== 'undefined') {
      const body = document.getElementsByTagName('BODY')[0]
      if (this.props.location.query && this.props.location.query.has('overlay')) {
        addClassToElement(body, 'overflow-hidden')
      } else {
        removeClassFromElement(body, 'overflow-hidden')
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {location} = this.props
    this.setHeadMetaInformation()

    //TODO: (!this.state.currentPage || ( prevState.curttenPage && prevState.currentPage.id !== this.state.currentPage.id)) == undefined?
    // if (typeof window !== 'undefined' && this.state && this.state.loadingState !== LoadingState.loading && (!this.state.currentPage || ( prevState.curttenPage && prevState.currentPage.id !== this.state.currentPage.id))) {
    //   if (location.pageYOffset >= 0) {
    //     window.scrollTo(0, location.pageYOffset)
    //   }
    // }
    if (this.state.currentPage) {
      if (
        typeof window !== 'undefined' &&
        this.state &&
        prevState.currentContentId !== this.state.currentPage.id
      ) {
        if (location.pageYOffset >= 0) {
          window.scrollTo(0, location.pageYOffset)
        }
      }
    }

    if (typeof window !== 'undefined') {
      if (this.props.location.hash && this.props.location.hash.length > 0) {
        // TODO experimental internal link with anchor support
        setTimeout(() => {
          const divBlock = document.getElementById(
            'anchor_' + this.props.location.hash.substring(1)
          )
          if (divBlock) {
            divBlock.scrollIntoView()
          }
        }, 400)
      }
    }
    this.setBodyState()
  }

  render() {
    if (!this.state) {
      return (
        <div id="app">
          <p className="loading">loading</p>
        </div>
      )
    }

    const {websiteSettings} = this.state
    const {rootNavigationNode} = websiteSettings
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    const translations = websiteSettings.translations.translations[currentLanguage]

    return (
      <IntlProvider locale={currentLanguage} messages={translations}>
        <NavigationNodeContext.Provider
          value={{
            location: this.props.location,
            router: this.props.router,
            rootNavigationNode: rootNavigationNode,
            currentLanguage: getCurrentLanguageEnumOrFallBackByPath(this.props.location.pathname),
            settings: {staticNewsNode: websiteSettings.newsNavigationNode}
          }}>
          <div id="app">
            <div className="site-container">
              <section className="sidebar left">
                <ModAnchorTag linkObject={{internalLink: {node: rootNavigationNode}}}>
                  <img className="logo" src={require('static/img/svg/logo.svg')} alt="logo" />
                </ModAnchorTag>
                {/* <div className="logo" /> */}
                <ModLanguageSwitchAndSocialIcons
                  rootNavigationNode={rootNavigationNode}
                  currentLanguage={currentLanguage}
                  rootState={this.state}
                />
              </section>

              <section className="site">
                <ModHeader navigationTree={rootNavigationNode} />
                <ModContent
                  searchNavigationNode={websiteSettings.searchNavigationNode}
                  rootState={this.state}
                  currentLanguage={currentLanguage}
                  navigationTree={rootNavigationNode}
                  pathname={this.props.location.pathname}
                />
                <ModFooter
                  content={this.state.websiteSettings.footer}
                  currentLanguage={currentLanguage}
                />
              </section>

              <section className="sidebar right">
                <ModSearch
                  searchNavigationNode={websiteSettings.searchNavigationNode}
                  currentLanguage={currentLanguage}
                />
                <div
                  className="back2top"
                  onClick={e => {
                    if (window) window.scrollTo(0, 0)
                  }}>
                  <a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.306"
                      height="40.873"
                      viewBox="0 0 13.306 40.873">
                      <g
                        id="Up_Arrow"
                        data-name="Up Arrow"
                        transform="translate(1869.209 628.373) rotate(180)">
                        <path
                          id="Pfad_19"
                          data-name="Pfad 19"
                          className="cls-1"
                          d="M61.1,314.6V355"
                          transform="translate(1801.457 272.9)"
                        />
                        <path
                          id="Pfad_20"
                          data-name="Pfad 20"
                          className="cls-1"
                          d="M0,0H8.908V8.908"
                          transform="translate(1868.856 621.367) rotate(135)"
                        />
                      </g>
                    </svg>
                    <span>UP</span>
                  </a>
                </div>
              </section>
            </div>

            <ModCookiePolicy websiteSettings={this.state.websiteSettings} />
            {/* this sometimes shows in the cookie-provider container and then maybe get cached and it gives the stranges effect that it looks like there is always a cookie consent pop up that cannot be dismissed */}
            {/* <ModModalView
              onClose={this.onCloseModalView}
              location={this.props.location}
              overlay={this.state.overlay}
            /> */}
          </div>
        </NavigationNodeContext.Provider>
      </IntlProvider>
    )
  }
}

export interface ModLanguageSwitchAndSocialIconsProps {
  rootNavigationNode: NavigationNode
  currentLanguage: string
  rootState: RootState
}

export function ModLanguageSwitchAndSocialIcons({
  rootNavigationNode,
  currentLanguage,
  rootState
}: ModLanguageSwitchAndSocialIconsProps) {
  return (
    <>
      <ModLanguageSwitcher
        navigationTree={rootNavigationNode}
        rootState={rootState}
        currentLanguage={currentLanguage}
      />

      <div className="social-icons">
        <a href="https://www.instagram.com/dansesuisse/" target="blank">
          <ModIcon type={IconType.Instagram} />
        </a>
        <a href="https://www.facebook.com/dansesuisse/" target="blank">
          <ModIcon type={IconType.Facebook} />
        </a>
        <a href="https://www.linkedin.com/company/danse-suisse/" target="blank">
          <ModIcon type={IconType.Linkedin} />
        </a>
      </div>
    </>
  )
}

export interface ModSearchProps {
  searchNavigationNode: NavigationNode
  currentLanguage: string
}
export function ModSearch({searchNavigationNode, currentLanguage}: ModSearchProps) {
  const link = createNodeLinkObject(searchNavigationNode, currentLanguage)
  return (
    <ModAnchorTag className="search-icon" linkObject={link}>
      {' '}
      <ModIcon type={IconType.Search} />
    </ModAnchorTag>
  )
}
