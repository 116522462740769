import * as XLSX from 'xlsx'
import * as React from 'react'
import {ModPrimaryButton} from '../../ui/ModPrimaryButton'

/* xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com */
/* Notes:
   - usage: `ReactDOM.render( <SheetJSApp />, document.getElementById('app') );`
   - xlsx.full.min.js is loaded in the head of the HTML page
   - this script should be referenced with type="text/babel"
   - babel.js in-browser transpiler should be loaded before this script
*/
export interface ModXLSUpload {
  onUpload(data): void
  buttonLabel: string
  buttonId: string
  label: string
}

export function ModXLSUpload({onUpload, buttonId, label, buttonLabel}: ModXLSUpload) {
  const [state, setState] = React.useState({
    fileName: '',
    data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
    cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */
  })

  function handleFile(file: File) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = e => {
      /* Parse data */
      const bstr = reader.result
      const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'})
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {header: 1})
      /* Update state */
      setState({data: data, cols: make_cols(ws['!ref']), fileName: file.name})
      onUpload(data)
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  }

  // exportFile() {
  // 	/* convert state to workbook */
  // 	const ws = XLSX.utils.aoa_to_sheet(this.state.data);
  // 	const wb = XLSX.utils.book_new();
  // 	XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  // 	/* generate XLSX file and send to client */
  // 	XLSX.writeFile(wb, "sheetjs.xlsx")
  // };

  return (
    <DragDropFile handleFile={handleFile}>
      <div className="row">
        <div className="col-xs-12">
          <DataInput handleFile={handleFile} buttonId={buttonId} buttonLabel={buttonLabel} />
          {state.fileName.length > 0 && (
            <p>
              {label} {state.fileName}
            </p>
          )}
        </div>
      </div>
      {/* <div className="row"><div className="col-xs-12">
		<button disabled={!this.state.data.length} className="btn btn-success" onClick={this.exportFile}>Export</button>
	</div></div> */}
      {/* <div className="row"><div className="col-xs-12">
		<OutTable data={this.state.data} cols={this.state.cols} />
	</div></div> */}
    </DragDropFile>
  )
}

/* -------------------------------------------------------------------------- */

export interface DragDropFileProps {
  handleFile(file): void
}
/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
export class DragDropFile extends React.Component<DragDropFileProps> {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
  }
  suppress(evt) {
    evt.stopPropagation()
    evt.preventDefault()
  }
  onDrop(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    const files = evt.dataTransfer.files
    if (files && files[0]) this.props.handleFile(files[0])
  }
  render() {
    return (
      <div onDrop={this.onDrop} onDragEnter={this.suppress} onDragOver={this.suppress}>
        {this.props.children}
      </div>
    )
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
export interface DataInputProps {
  handleFile(file): void
  buttonLabel: string
  buttonId: string
}
export class DataInput extends React.Component<DataInputProps> {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    const files = e.target.files
    if (files && files[0]) this.props.handleFile(files[0])
  }
  render() {
    return (
      <form className="drop-form">
        <div className="drop-form-group">
          <ModPrimaryButton type={'button'}>
            <label htmlFor={this.props.buttonId}>{this.props.buttonLabel}</label>
          </ModPrimaryButton>
          <input
            style={{opacity: 0}}
            type="file"
            className="file-import-button"
            id={this.props.buttonId}
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    )
  }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
// class OutTable extends React.Component {
// 	constructor(props) { super(props); };
// 	render() { return (
// <div className="table-responsive">
// 	<table className="table table-striped">
// 		<thead>
// 			<tr>{this.props.cols.map((c) => <th key={c.key}>{c.name}</th>)}</tr>
// 		</thead>
// 		<tbody>
// 			{this.props.data.map((r,i) => <tr key={i}>
// 				{this.props.cols.map(c => <td key={c.key}>{ r[c.key] }</td>)}
// 			</tr>)}
// 		</tbody>
// 	</table>
// </div>
// 	); };
// };

/* list of supported file types */
const SheetJSFT = ['xlsx', 'xls']
  .map(function (x) {
    return '.' + x
  })
  .join(',')

/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1
  for (var i = 0; i < C; ++i) o[i] = {name: XLSX.utils.encode_col(i), key: i}
  return o
}
