import { FilterType, FilterOption, TimePeriod } from "./ModFilter";

export type Emtpy = {
  type: "empty";
};

export class BaseFilter<T extends FilterType, V> {
  type: T;
  key: string;
  label: string;
  value: V;

  constructor(type: T, key: string, label: string, value: V) {
    this.type = type;
    this.key = key;
    this.value = value;
    this.label = label;
  }

  getValues(): V {
    return this.value;
  }

  setValue(value: V) {
    this.value = value;
  }

  getType(): T {
    return this.type;
  }

  getKey(): string {
    return this.key;
  }
}

export class StringFilter extends BaseFilter<FilterType.String, Array<FilterOption> | null> {
  options: FilterOption[];

  constructor(
    key: string,
    label: string,
    options: FilterOption[],
    value: Array<FilterOption> | null
  ) {
    super(FilterType.String, key, label, value);
    this.options = options;
  }
}

export class TimeFilter extends BaseFilter<FilterType.TimePicker, TimePeriod | null> {
    constructor(
      key: string,
      label: string,
      value: TimePeriod | null
    ) {
      super(FilterType.TimePicker, key, label, value);
    }
}
