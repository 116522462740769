import * as React from 'react'

import {BlockCookieDisclaimer} from 'interfaces/InterfacesBlocks'
import {hasContent} from './utils/DDUtil'

export interface ModBlockCookieDeclarationProps {
  currentLanguage: string
  content?: BlockCookieDisclaimer
}

export function ModBlockCookieDeclaration({
  currentLanguage,
  content
}: ModBlockCookieDeclarationProps) {
  React.useEffect(() => {
    const script = document.createElement('script')
    const scriptId = document.getElementById('cookie')

    // readd script tag for language change
    if (scriptId.childNodes.length > 0) {
      scriptId.removeChild(scriptId.firstChild)
      scriptId.removeChild(scriptId.lastChild)
    }

    script.src = 'https://consent.cookiebot.com/fcaf4c00-dd54-4a75-aa46-954ea0c12486/cd.js'
    script.id = 'CookieDeclaration'
    script.type = 'text/javascript'
    script.async = true
    script.async = true
    script.setAttribute('data-culture', currentLanguage)
    scriptId.appendChild(script)
  }, [currentLanguage])

  const i18n = content._i18nCL
  if (!i18n || !i18n.title) {
    return null
  }

  let title = hasContent(i18n.title) ? <h2 className="t-h2">{i18n.title}</h2> : null

  return (
    <div>
      <div className="block-title">{title}</div>
      <div className="rich-text">
        <p id="cookie"></p>
      </div>
    </div>
  )
}
