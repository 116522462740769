import * as React from 'react'

export interface ModGridUnitProps {
  /**
   * number of column spaces on element should take.
   *
   * f.e. numbers possible for a grid with 3 columns:
   *  -----------
   * | 1 | 1 | 1 |
   *  -----------
   * | 1 |   2   |
   *  -----------
   * |     3     |
   *  -----------
   */
  readonly columnWidth: number
  readonly maxWidth?: number
  // each component has to have a react key!
  component: JSX.Element
}

export interface ModGridProps {
  readonly children: ModGridUnitProps[] | ModGridUnitProps
}

export function ModGridRow({ children }: ModGridProps) {
  let units: ModGridUnitProps[] = []
  if (Array.isArray(children)) {
    units = children
  } else {
    units.push(children)
  }

  const cssClass = units.reduce((prev, curr, index) => {
    if (curr.maxWidth) {
      if (curr.maxWidth <= index) {
        return prev + `-${curr.columnWidth}`
      } 
    } else {
      return prev + `-${curr.columnWidth}`
    }
    return prev
  }, 'pattern')

  return (
    // IMPORTANT each component has to have a key
    <div className={`grid grid-gap ${cssClass}`}>
      {units.map((unit, index) => {
        return unit.component
      })}
    </div>
  )
}
