import { RichText, Link, Overlay, Media, NewsDetailPage, DancerEducation, Event, EventVenue, InternalLink, TrainingEvent } from './InterfacesEntities';
import { BlockQuicklinks, BlockAdvertisment, BlockElfsight } from './InterfacesBlocks';

export interface IStateController {
  publicConfig: IPublicSettings
  editMode: boolean
  cookies: {
    cookie_consent_dismissed: boolean
  }
  domain: Domain
  websiteSettings: Website
  pages: {
    [type: string]: {
      [key: string]: IPage
    }
  }
  currentPage?: {
    type: string
    id: string

  }
}

export interface Website {
  internalDescription: string
  _i18nCL: {
    title: string
    siteName: string
    description: string
    cookiePolicy: string
  }
  googleAnalyticsProperty: string
  googleTagManagerKey: string
  footer: Footer
  rootNavigationNode?: NavigationNode
  searchNavigationNode?: NavigationNode
  newsNavigationNode?: NavigationNode
  translations: Translations
  shareImage?: Media
  blocks404: any[]
}

export interface Translations {
  internalDescription: string
  projectId: string
  translations: {
    [key: string]: {
      [key: string]: string
    }
  }
}

export interface Footer {
  _i18nCL: {
    links: { label: string, link: Link }[]
  }
}



export interface NavigationNode {
  _id: string
  internalDescription?: string
  i18n: {
    [key: string]: {
      label: string
      showInMenu: boolean
      slug: string
      relativeUrl: string
    }
  }
  _i18nCL: {
    label: string
    showInMenu: boolean
    slug: string
    relativeUrl: string
  }
  children: NavigationNode[]
  pageId: string
  page?: Page
}

export enum TemplateType {
  Blocklist = "templateBlockList",
  NewsPage = "templateNewsPage",
  Page = "templatePage",
  Front = 'templateFrontPage',
  Professionals = 'templateProfessionalsPage',
  Events = 'templateEventsPage',
  ExpertTraining = 'templateExpertTrainingPage',
  Search = 'templateSearch',
  ImportExport = 'templateImportExport'
}

export interface IPagination {
  entryCount: number
  pageCount: number
}

interface TemplateWithHeader {
  header: { _i18nCL: { title: string, lead?: string }, anchors?: boolean }
}

export interface EventsTemplate extends TemplateWithHeader {
  events: Event[]
  venues: EventVenue[]
  types: any[]
  sidePanelContent: any[]
}

export interface TrainingEventsTemplate extends TemplateWithHeader {
  events: TrainingEvent[]
  venues: EventVenue[]
  types: any[]
  sidePanelContent: any[]
}

export interface NewsTemplate extends IPagination, TemplateWithHeader {
  news: NewsDetailPage[]
  sidePanelContent: any[]
}

export enum ProfessionalsType {
  mediation = 'mediation',
  education = 'education'
}

export interface ProfessionalsTemplate<T> extends TemplateWithHeader {
  dancers: T[]
  type: ProfessionalsType
  sidePanelContent: any[]
}

export interface PageTemplate extends TemplateWithHeader {
  blocks: any[]
  sidePanelContent: any[]
}

export interface FrontTemplate {
  breaking: {
    _i18nCL: { link: { news?: NewsDetailPage, navigationNode?: NavigationNode }, title: string, lead: string, text: string }[]
  }
  quicklinks: BlockQuicklinks
  advertisment?: BlockAdvertisment
  eventsPreview: { _i18nCL: { eventsTitle: string, linkEvents: InternalLink } }
  events: any
  newsPreview: { _i18nCL: { newsTitle: string, linkNews: InternalLink } }
  news: NewsDetailPage[]
  elfsight?: BlockElfsight
}

export enum PageType {
  Page = "page",
  NewsDetail = "newsDetail"
}
export interface IPage {
  _id: string
  _type: string
  _pageType: PageType
  loadedDateTime: string
  content: {
    overlay?: Overlay
  }
}

export interface Page extends IPage {
  internalDescription: string
  colorCat: string
  i18n: {
    [key: string]: {
      title: string
      description: string
      isLive: boolean
    }
  }
  _i18nCL: {
    title: string
    description: string
    isLive: boolean
  }
  publishDate?: string
  expiryDate?: string
  navigationNode?: NavigationNode | string
  shareImage?: Media
  content: {
    [TemplateType.Blocklist]: any[]
    [TemplateType.NewsPage]: NewsTemplate,
    [TemplateType.Events]: EventsTemplate,
    [TemplateType.ExpertTraining]: TrainingEventsTemplate,
    [TemplateType.Front]: FrontTemplate,
    [TemplateType.Page]: PageTemplate,
    [TemplateType.Professionals]: ProfessionalsTemplate<unknown>
    overlay?: Overlay
  }
  meta: {
    news: any
  }
}

export interface StaticPage extends IPage {
  i18n: {
    [key: string]: {
      slug: string
      relativeUrl: string
      isLive: boolean
    }
  }
  _i18nCL: {
    slug: string
    relativeUrl: string
    isLive: boolean
  }
}

export interface IPublicSettings {
  cloudinaryCloudName: string
  mediaProxy: string
  caasEditorUrl: string
}

export interface Domain {
  internalDescription: string,
  domains: string[],
  forwards: {
    pattern: string,
    regex: boolean,
    target: {
      navigationNode: {
        node: string
      },
      url: string
    }
  }[],
  serve?: Website | string
}

export interface SitemapEntry {
  url: string
  lastUpdated: string
  altUrls?: {
    url: string
    lang: string
  }[]
}