import * as React from 'react'

import {PageTemplateProps} from './IPageTemplateProps'
import ModBreadCrumb from '../ModBreadCrumb'
import {ModGridRow} from 'components/layout/ModGridRow'
import {ProfessionalsTemplate} from 'interfaces/Interfaces'
import {ModFilter, FilterType} from 'components/ui/filter/ModFilter'
import {DancerEducation} from 'interfaces/InterfacesEntities'
import {FormattedMessage} from 'react-intl'
import ModIntlWrapper from 'components/common/utils/ModIntlWrapper'
import {ModSimplePagination} from 'components/ui/ModSimplePagination'
import {ModPageHeader} from './DetailPageTemplate'
import {BlocksRenderer} from 'components/blocks/BlocksRenderer'

export interface ProfessionalsEducationPageTemplateProps
  extends PageTemplateProps,
    ProfessionalsTemplate<DancerEducation> {}

export function ProfessionalsEducationPageTemplate({
  navigationTree,
  currentLanguage,
  rootState,
  pathname,
  dancers,
  header,
  sidePanelContent
}: ProfessionalsEducationPageTemplateProps) {
  if (dancers == undefined || dancers == null) {
    // console.warn('dancers are undefined or null')
  }

  const [filteredDancers, setFilteredDancers] = React.useState(
    dancers.sort((a, b) => a.name.localeCompare(b.name))
  )

  const filterGroup = {
    LevelId: 'levelFilterGroup',
    CityId: 'cityFilterGroup',
    StyleId: 'styleFilterGroup'
  }

  function sortAlphabetically(arrayToSort) {
    return arrayToSort.sort((a, b) => a.label.localeCompare(b.label))
  }

  // Level Filter Options
  const danceLevelOptions = formatMessageCallback => {
    let levels = []
    for (let level in dancers[0].level) {
      if (level != 'comment') {
        levels.push({id: level, label: formatMessageCallback({id: `dancerLevel.${level}`})})
      }
    }
    sortAlphabetically(levels)
    return levels
  }

  // Style Filter Options
  const danceStyleOptions = formatMessageCallback => {
    let styles = []
    for (let style in dancers[0].styles) {
      if (style != 'comment') {
        styles.push({id: style, label: formatMessageCallback({id: `dancerStyle.${style}`})})
      }
    }
    sortAlphabetically(styles)
    return styles
  }

  // City Filter Options
  const cityOptions = dancers.reduce((cities, currentDancer) => {
    for (let cityOption of cities) {
      if (cityOption.label == currentDancer.city) return cities
    }
    cities.push({id: currentDancer.city, label: currentDancer.city})
    return cities
  }, [])
  sortAlphabetically(cityOptions)

  /**
   * on Filter Change
   * @param filters
   */
  function onFilterChange(filters: {key: string; selection: any}[]) {
    let filtered = dancers

    for (let fil of filters) {
      filtered = filtered.filter(dancer => {
        if (fil.key == filterGroup.CityId) {
          for (let selected of fil.selection) {
            if (dancer.city == selected.id) {
              return true
            }
          }
          return false
        }

        if (fil.key == filterGroup.StyleId) {
          for (let selected of fil.selection) {
            for (let style in dancer.styles) {
              if (dancer.styles[style] && style == selected.id) {
                return true
              }
            }
          }
        }

        if (fil.key == filterGroup.LevelId) {
          for (let selected of fil.selection) {
            for (let level in dancer.level) {
              if (dancer.level[level] && level == selected.id) {
                return true
              }
            }
          }
        }
      })
    }

    filtered.sort((a, b) => a.name.localeCompare(b.name))
    setFilteredDancers(filtered)
  }

  function resetFilters() {
    setFilteredDancers(dancers)
  }

  /**
   * get Dancer Styles
   * @param dancer
   */
  function getDancerStyles(dancer: DancerEducation, formatMessage) {
    let styles = []
    for (let styleName in dancer.styles) {
      if (dancer.styles[styleName] && styleName != 'comment') {
        styles.push(formatMessage({id: `dancerStyle.${styleName}`}, {defaultMessage: styleName}))
      }
    }
    styles.sort((a, b) => a.localeCompare(b))
    return styles
  }

  /**
   * get Dancer Levels
   * @param dancer
   */
  function getDancerLevels(dancer: DancerEducation, formatMessage) {
    let levels = []
    for (let level in dancer.level) {
      if (dancer.level[level] && level != 'comment') {
        levels.push(formatMessage({id: `dancerLevel.${level}`}, {defaultMessage: level}))
      }
    }
    levels.sort((a, b) => a.localeCompare(b))
    return levels
  }

  return (
    <ModIntlWrapper>
      {({formatMessage}) => (
        <ModGridRow>
          {[
            {
              columnWidth: 2,
              component: (
                <div key="content" className="main-content">
                  {header && header._i18nCL && (
                    <ModPageHeader title={header._i18nCL.title} lead={header._i18nCL.lead} />
                  )}
                  <p className="small-text filter-title">
                    <FormattedMessage
                      id={'filter.filterTitleProfessionals'}
                      defaultMessage={'Filter setzen'}
                    />
                  </p>
                  <ModFilter
                    onFilterChange={onFilterChange}
                    currentlanguage={currentLanguage}
                    filterDataChangeIndicator={currentLanguage}
                    filters={[
                      {
                        id: filterGroup.CityId,
                        type: FilterType.String,
                        label: formatMessage({id: 'filter.place', defaultMessage: 'Ort'}),
                        options: cityOptions
                      },
                      {
                        id: filterGroup.LevelId,
                        type: FilterType.String,
                        label: formatMessage({
                          id: 'dancerLevel.label',
                          defaultMessage: 'Zielgruppe'
                        }),
                        options: danceLevelOptions(formatMessage)
                      },
                      {
                        id: filterGroup.StyleId,
                        type: FilterType.String,
                        label: formatMessage({
                          id: 'dancerStyle.label',
                          defaultMessage: 'Tanztechnik'
                        }),
                        options: danceStyleOptions(formatMessage)
                      }
                    ]}
                  />

                  {filteredDancers.length > 0 ? (
                    filteredDancers.map((dancer, index) => {
                      return (
                        <div
                          className="reg-entry"
                          key={`${dancer.surname},${dancer.name},${dancer.city}`}>
                          <h4 className="reg-entry__name">
                            {dancer.name}, {dancer.surname}
                          </h4>
                          <div className="reg-entry__credentials grid pattern-50-50">
                            <div className="reg-entry__address">
                              <p>
                                {dancer.postalCode} {dancer.city}
                              </p>
                            </div>
                            <div className="reg-entry__contact">
                              <p className="small-text">
                                <a href={`mailto:${dancer.email}`}>{dancer.email}</a>
                              </p>
                              {dancer.website && (
                                <p className="reg-entry__website small-text">
                                  <a
                                    href={
                                      dancer.website.startsWith('http')
                                        ? dancer.website
                                        : `//${dancer.website}`
                                    }
                                    target="_blank">
                                    {dancer.website}
                                  </a>
                                </p>
                              )}
                              {dancer.phone && (
                                <p className="reg-entry__phone small-text">
                                  <a href={`tel:${dancer.phone}`}>{dancer.phone}</a>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="grid pattern-50-50">
                            <div className="reg-entry__offer">
                              <h5>
                                <FormattedMessage
                                  id={'dancerStyle.label'}
                                  defaultMessage={'Tanztechnik'}
                                />
                              </h5>
                              {getDancerStyles(dancer, formatMessage).map(item => {
                                return <p className="reg-entry__type">{item}</p>
                              })}
                              <p className="reg-entry__type">
                                <i>{dancer.styles.comment}</i>
                              </p>
                            </div>

                            <div className="reg-entry__levels">
                              <h5>
                                <FormattedMessage
                                  id={'dancerLevel.label'}
                                  defaultMessage={'Zielgruppe'}
                                />
                              </h5>
                              {getDancerLevels(dancer, formatMessage).map(item => {
                                return <p className="reg-entry__level">{item}</p>
                              })}
                              <p className="reg-entry__level">
                                <i>{dancer.level.comment}</i>
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="no-event">
                      <FormattedMessage id="noEntries" defaultMessage="keine Einträge" />
                    </div>
                  )}

                  <ModBreadCrumb navigationTree={navigationTree} pathName={pathname} />
                </div>
              )
            },

            {
              columnWidth: 1,
              component: (
                <BlocksRenderer
                  key={'side'}
                  rootState={rootState}
                  blockListData={sidePanelContent}
                />
              )
            }
          ]}
        </ModGridRow>
      )}
    </ModIntlWrapper>
  )
}
