import * as isoFetch from 'isomorphic-fetch'
import { SupportedLangauges } from 'common/Languages'

import { FormInputCredentials, FormInputEducation, FormInputBallettEducation, FormTalentscoutExtras, AuditionType } from './FormInterfaces'
import { credentialsAdapter, educationAdapter, cloudinaryFileUpload } from './FormHelpers'
import { FormInputTalentscoutRecurring, SchoolType } from './ModBlockFormTalentscoutRecurring'
import { FormInputTalentscout } from './ModBlockFormTalentscout'

export interface FormInputTalentscoutNewRegistry
  extends FormInputCredentials,
  FormInputEducation,
  FormInputBallettEducation,
  FormTalentscoutExtras {
  hasAlreadyAttended: boolean
  yearAlreadyAttended: string

  isOnlyTalent: boolean
  kSSchoolName: string
  kSTypeGymLong: boolean
  kSTypeGymShort: boolean
  kSTypeSek: boolean
  kSTypeOther: string
}

export async function isoPostNewTalentscoutRegistry(
  file: File,
  talentscoutRegistry: FormInputTalentscout,
  currentLanguage: SupportedLangauges,
  eventDate: Date
) {
  let newTalentscout = newTalentscoutRegistryAdapter(talentscoutRegistry, eventDate)
  let credentials = credentialsAdapter(talentscoutRegistry, currentLanguage, eventDate)
  let education = educationAdapter(talentscoutRegistry, true)
  let uploadedFile = await cloudinaryFileUpload(file)

  const response = await isoFetch('/api/uploadPdf', {
    method: 'POST',
    headers: {
      Accept: 'application/json, application/xml, text/play, text/html, *.*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...credentials, ...uploadedFile }),
  })

  console.log('isoFetch uploadPdf', response)

  if (!response.ok) {
    return response
  }

  const json = await response.json()
  console.log('isoFetch uploadPdf json', json)

  const pdfId = { pdf: json }

  return await isoFetch('/api/talentscout', {
    method: 'POST',
    headers: {
      Accept: 'application/json, application/xml, text/play, text/html, *.*',
      'Content-Type': 'application/json',
      'Current-Languag': currentLanguage
    },
    body: JSON.stringify({ ...credentials, ...newTalentscout, ...education, ...pdfId }),
  })
}

function newTalentscoutRegistryAdapter(inputs: FormInputTalentscout, eventDate: Date) {
  function getSchoolType(inputs: FormInputTalentscout): string {
    var result = ''

    if (inputs.newFor[SchoolType.SEK]) {
      result += 'Sek; '
    }

    if (inputs.newFor[SchoolType.GYM_LONG]) {
      result += 'Gym lang; '
    }

    if (inputs.newFor[SchoolType.GYM_SHORT]) {
      result += 'Gym kurz; '
    }

    if (inputs.newFor[SchoolType.DIFF]) {
      result += `anderes K&S Programm: ${inputs.kSTypeOther}`
    }

    return result
  }

  return {
    eventYear: eventDate.getFullYear(),
    yearAlreadyAttended: inputs.hasAlreadyAttended ? inputs.yearAlreadyAttended : 'nein',

    isOnlyTalent: inputs.isOnlyTalent,
    isLetterKS: !inputs.isOnlyTalent,

    isNewKS: true,
    newKSSchoolName: inputs.kSSchoolName,
    newKSSchoolType: getSchoolType(inputs),

    ballettMethod: `${inputs.ballettEducationMethod && `${inputs.ballettEducationMethod}`} ${inputs.ballettEducationLevel && `/ ${inputs.ballettEducationLevel}`
      }`,
    preferredCareer: inputs.preferredCareer,

    auditionFor: inputs.auditionFor == AuditionType.BALLET ? "Ballet" : "Hip Hop"
  }
}

export async function isoPostRecurringTalentscoutRegistry(
  file: File,
  talentscoutRegistry: FormInputTalentscoutRecurring,
  currentLanguage: SupportedLangauges,
  eventDate: Date
) {
  let recurringTalentscout = recurringTalentscoutRegistryAdapter(talentscoutRegistry, eventDate)
  let credentials = credentialsAdapter(talentscoutRegistry, currentLanguage, eventDate)
  let education = educationAdapter(talentscoutRegistry, true)
  let uploadedFile = await cloudinaryFileUpload(file)

  const response = await isoFetch('/api/uploadPdf', {
    method: 'POST',
    headers: {
      Accept: 'application/json, application/xml, text/play, text/html, *.*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...credentials, ...uploadedFile }),
  })

  const pdfId = { pdf: await response.json() }

  return await isoFetch('/api/talentscout', {
    method: 'POST',
    headers: {
      Accept: 'application/json, application/xml, text/play, text/html, *.*',
      'Content-Type': 'application/json',
      'Current-Languag': currentLanguage
    },
    body: JSON.stringify({ ...credentials, ...recurringTalentscout, ...education, ...pdfId }),
  })
}

function recurringTalentscoutRegistryAdapter(inputs: FormInputTalentscoutRecurring, eventDate: Date) {
  function getRecurringType(inputs): string {
    switch (inputs.recurringFor) {
      case SchoolType.SEK:
        return 'Sek'
      case SchoolType.GYM_LONG:
        return 'Gym lang'
      case SchoolType.GYM_SHORT:
        return 'Gym kurz'
      case SchoolType.DIFF:
        return 'anderes K&S Programm'
      default:
        return ''
    }
  }

  function getChangeType(inputs): string {
    switch (inputs.recurringFor) {
      case SchoolType.SEK_TO_SHORT:
        return 'Sek zu Gym kurz'
      case SchoolType.SEK_TO_LONG:
        return 'Sek zu Gym lang'
      case SchoolType.TO_DIFF:
        return inputs.kSTypeOther
      default:
        return ''
    }
  }

  return {
    eventYear: eventDate.getFullYear(),
    yearAlreadyAttended: inputs.yearAlreadyAttended,

    isOnlyTalent: false,
    isLetterKS: true,

    isOldKS: true,

    oldKSStart: inputs.ksSinceCurrentSchool,
    oldKSSchoolName: inputs.ksCurrentSchool,
    oldKSSchoolTeacher: inputs.currentKsTeacher,
    oldKSSchoolRector: inputs.currentKsPrincipal,
    oldKSSchoolType: getRecurringType(inputs),
    changeKS: getChangeType(inputs),

    ballettMethod: `${inputs.ballettEducationMethod && `${inputs.ballettEducationMethod}`} ${inputs.ballettEducationLevel && `/ ${inputs.ballettEducationLevel}`
      }`,
    preferredCareer: inputs.preferredCareer,

    auditionFor: inputs.auditionFor == AuditionType.BALLET ? "Ballet" : "Hip Hop"
  }
}
