import * as React from 'react'
import ModImgTag from 'components/common/ModImgTag'
import ModPhotoSwipe from 'components/common/ModPhotoSwipe'
import ModIdangerousSwiper from 'components/common/ModIdangerousSwiper'
import ModLazyLoad from 'components/common/ModLazyLoad'
import {hasContent} from 'common/Utils'
import {ModLazyLoadState} from "../common/ModLazyLoad";
import {BlockSlider} from "../../interfaces/InterfacesBlocks";
import ModLazyLoadView from "../common/ModLazyLoadView";
import ModSvg from "../common/ModSvg";
import {RefObject} from "react";

const MAX_IMG_SIZE = 1000

export interface ModBlockSliderProps {
  content: BlockSlider
  index: number
}

interface ModBlockSliderState extends ModLazyLoadState {
  fullScreenSliderIndex: number
}

export default class ModBlockSlider extends ModLazyLoad<ModBlockSliderProps, ModBlockSliderState> {

  myRef: RefObject<ModIdangerousSwiper>

  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      fullScreenSliderIndex: -1,
      visible: false
    }
    this.onFullScreen = this.onFullScreen.bind(this)
    this.didCloseFullScreenSlider = this.didCloseFullScreenSlider.bind(this)
  }

  onFullScreen(event) {
    this.setState({
      fullScreenSliderIndex: this.myRef.current.state.activeSlide
    })
  }

  didCloseFullScreenSlider() {
    this.setState({
      fullScreenSliderIndex: -1
    })
  }

  render() {
    const {content, index} = this.props
    if (!hasContent(content.list)) {
      return null
    }

    let inlineSlider = null
    if (this.state.visible) {
      inlineSlider = content.list.map((item, index) => {
        if (!item._i18nCL) {
          return null
        }
        const i18n = item._i18nCL
        return (
          <div className="swiper-slide" key={index}>
            <div className="block-slider-img-container">
              <ModImgTag imgObject={item.image} width={1024} height={768} swiperLazy={true}/>
              <div className="swiper-lazy-preloader"/>
            </div>
            <p className="caption">{item._i18nCL.text}</p>
          </div>)
      })
      inlineSlider = (
        <ModIdangerousSwiper id={"block_slider_" + content._id}
                             ref={this.myRef}
                             hasNavigation={true}
                             hasPagination={true}
                             maxPaginationCount={10}
                             hasSlideNumber={true}>
          {inlineSlider}
        </ModIdangerousSwiper>
      )
    }
    else {
      inlineSlider = <div className="aspect-ratio">
        <ModLazyLoadView rRef={(input) => {
          this.lazyComponent = input
        }}/>
      </div>
    }

    const fullScreenSlider = this.state.fullScreenSliderIndex >= 0 ? (
      <ModPhotoSwipe index={index}
                     sliderIndex={this.state.fullScreenSliderIndex}
                     content={content}
                     onClose={this.didCloseFullScreenSlider}/>
    ) : null

    return (
      <div className="block-slider">
        {inlineSlider}
        <button className="swiper-button-fullscreen" aria-label="full screen" onClick={this.onFullScreen}>
          <ModSvg url={require("static/img/svg/but_double_arrow.svg")} id="icon"/>
        </button>
        {fullScreenSlider}
      </div>
    )
  }
}
