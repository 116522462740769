import * as React from 'react'

import {BlockImageText} from "../../interfaces/InterfacesBlocks";
import {draftJsHasContent} from "../../common/Utils";
import ModRichText from "../common/ModRichText";
import ModImgTag from "../common/ModImgTag";
import ModAnchorTag from "../common/ModAnchorTag";

export interface ModBlockImageTextProps {
  content: BlockImageText
}

export default class ModBlockImageText extends React.Component<ModBlockImageTextProps, {}> {
  render() {
    const {content} = this.props

    if (!(content._i18nCL && draftJsHasContent(content._i18nCL.text))) {
      return null
    }
    const i18n = content._i18nCL

    const width = 512

    let link = null
    if (content.link) {
      link = (
        <ModAnchorTag className="button" linkObject={content.link}>
          <span>discover</span>
        </ModAnchorTag>
      )
    }

    return (
      <div className="block-image-text centered">
        <div className="image">
          <ModImgTag imgObject={content.image} />
        </div>
        <div className="text">
          <ModRichText richText={i18n.text}/>
        </div>
      </div>
    )
  }
}