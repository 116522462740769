import * as React from 'react'
import {injectIntl} from 'react-intl'

export interface ModIntWrapperProps {
  children: any
  intl: any
}

// helper component because injectIntl() cant be used on function components.
// newer versions of intl would have a hook to easily use the formatMessage functionality, but it means to update typescript and babel and everything
class ModIntWrapper extends React.Component<ModIntWrapperProps, {}> {
  constructor(props: ModIntWrapperProps) {
    super(props)
    this.formatMessage = this.formatMessage.bind(this)
  }

  formatMessage(value: any) {
    const {formatMessage} = this.props.intl
    return formatMessage(value)
  }

  getStateWithGetters = () => ({
    formatMessage: this.formatMessage
  })

  render() {
    return this.props.children(this.getStateWithGetters())
  }
}

export default injectIntl(ModIntWrapper)
