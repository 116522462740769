import * as React from 'react';
import { ModIconButton } from './ModIconButton';
import { IconType } from 'components/common/ModIcon';

// Taglist: flex or list

export type Tag = {id: string, label: string}

export interface ModTagsProps {
  readonly items: Tag[]
  onDeleteItem?(id: string): void
}

/**
 * 
 * @param param0 
 */
export function ModTags({ items, onDeleteItem }: ModTagsProps) {

  return (
    <div className="filter-items">
      {items && items.map(item => {
        return (
          <div className="active-filter" key={item.id}>
            <ModIconButton type="button" iconType={IconType.Close_Filled} onClick={e => { if (onDeleteItem) onDeleteItem(item.id) } } />
            <span className="acitve-filter-label">{item.label}</span>
          </div>
        );
      })}
    </div>
  );
}