import * as React from 'react'

import {BlockRichText} from '../../interfaces/InterfacesBlocks'
import {draftJsHasContent} from '../../common/Utils'
import ModRichText from '../common/ModRichText'
import {labelToAnchor} from 'components/common/utils/DDUtil'

export interface ModBlockRichTextProps {
  content: BlockRichText
}

export default class ModBlockRichText extends React.Component<ModBlockRichTextProps, {}> {
  render() {
    const {content} = this.props

    if (!(content._i18nCL && draftJsHasContent(content._i18nCL.text))) {
      return null
    }

    const a = content._i18nCL.anchor ? (
      <a id={`anchor_${labelToAnchor(content._i18nCL.anchor)}`} />
    ) : null

    return (
      <div className="block-rich-text centered">
        {a}
        <ModRichText richText={content._i18nCL.text} />
      </div>
    )
  }
}
