import * as React from 'react'

export interface ModCheckboxProps {
  readonly label?: string
  readonly isChecked?: boolean
  readonly required?: boolean
  readonly groupName?: string
  onChange?(isChecked: boolean): void
}

export function ModCheckbox({
  label,
  isChecked,
  onChange,
  required = false,
  groupName
}: ModCheckboxProps) {
  return (
    <div className="checkbox-container">
      <div className="checkbox">
        {isChecked != undefined ? (
          <input
            type="checkbox"
            className="text-input"
            name={groupName}
            id={label}
            checked={isChecked}
            onClick={e => onChange(!isChecked)}
            required={required}
          />
        ) : (
          <input
            type="checkbox"
            className="text-input"
            name={groupName}
            id={label}
            required={required}
          />
        )}
        <span className="checkmark" onClick={e => onChange(!isChecked)}></span>
      </div>
      <label htmlFor={label} onClick={e => onChange(!isChecked)}>
        {label}
      </label>
    </div>
  )
}
