import * as React from 'react'

import {
  START_DATE,
  useDatepicker,
  useMonth,
  useDay,
  OnDatesChangeProps,
  END_DATE
} from '@datepicker-react/hooks'
import DatepickerContext from './DatepickerContext'
import {useSelect} from 'downshift'
import {ModIconButton} from '../ModIconButton'
import {IconType} from 'components/common/ModIcon'
import {FormattedMessage} from 'react-intl'
import {ModDropDownList} from '../ModDropDown'

export interface ModDatepickerProps {
  className?: string
  readonly startDate?: Date
  readonly endDate?: Date
  readonly showYearSelect?: boolean
  onStartDateChange?(newStart: Date): void
  onEndDateChange?(newEnd: Date): void
}

export function ModDatepicker({
  className,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  showYearSelect = false
}: ModDatepickerProps) {
  const [state, setState] = React.useState<OnDatesChangeProps>({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE // startDate -> endDate -> null
  })

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange
  })

  function yearOptions(yearsCount) {
    let now = new Date()
    let years = []
    for (let i = now.getFullYear() - yearsCount; i <= now.getFullYear(); i++) {
      years.push(i)
    }
    return years
  }

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: yearOptions(50),
    onSelectedItemChange: change => {}
  })

  function handleDateChange(data) {
    if (data.focusedInput == END_DATE) {
      if (onStartDateChange) onStartDateChange(data.startDate)
    }
    if (data.focusedInput == null) {
      if (onEndDateChange) onEndDateChange(data.endDate)
    }
    if (!data.focusedInput) {
      setState({...data, focusedInput: START_DATE})
    } else {
      setState(data)
    }
  }

  function getFormatedDate(date) {
    let dd = date.getDate()
    let mm = date.getMonth() + 1
    let yyyy = date.getFullYear()

    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    return dd + '.' + mm + '.' + yyyy
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover
      }}>
      <div className={`datepicker ${className}`}>
        {/* <div >
          <strong>Focused input: </strong>
          {state.focusedInput}
        </div> */}
        {/* <div>
          <strong>Start date: </strong>
          {state.startDate && state.startDate.toLocaleString()}
        </div>
        <div>
          <strong>End date: </strong>
          {state.endDate && state.endDate.toLocaleString()}
        </div> */}

        {showYearSelect && (
          <>
            <div></div>
            <ModIconButton
              type="button"
              buttonProps={getToggleButtonProps()}
              iconType={isOpen ? IconType.Chevron_Up : IconType.Chevron_Down}
            />
            <ModDropDownList
              options={yearOptions(50)}
              getMenuProps={getMenuProps}
              isOpen={isOpen}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              itemToString={i => i}
            />
          </>
        )}

        <div className="cal-buttons">
          <NavButton onClick={goToPreviousMonths}>
            <FormattedMessage id="previous" defaultMessage="Zurück" />
          </NavButton>
          <NavButton onClick={goToNextMonths}>
            <FormattedMessage id="next" defaultMessage="Weiter" />
          </NavButton>
        </div>

        <div className="cal-date-wrapper">
          <div className="">
            <strong>
              <FormattedMessage id="startDate" defaultMessage="Startdatum" />
            </strong>
            {state.startDate && getFormatedDate(state.startDate)}
          </div>
          <div className="">
            <strong>
              <FormattedMessage id="endDate" defaultMessage="Enddatum" />
            </strong>
            {state.endDate && getFormatedDate(state.endDate)}
          </div>
        </div>

        {activeMonths.map(month => {
          return (
            <Month
              key={`${month.year}-${month.month}`}
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
            />
          )
        })}
      </div>
    </DatepickerContext.Provider>
  )
}

/// NAV BUTTON
export function NavButton({children, onClick}) {
  return (
    <button type="button" onClick={onClick}>
      {children}
    </button>
  )
}

/// MONTH
function Month({year, month, firstDayOfWeek}) {
  const {days, weekdayLabels, monthLabel} = useMonth({
    year,
    month,
    firstDayOfWeek
  })

  return (
    <div className="calendar-container">
      <div className="cal-month">
        <strong>{monthLabel}</strong>
      </div>
      <div className="cal-week">
        {weekdayLabels.map(dayLabel => {
          return (
            <span className="cal-weekday" key={dayLabel}>
              {dayLabel}
            </span>
          )
        })}
      </div>
      <div className="cal-date">
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return <Day date={day.date} key={day.date.toString()} dayLabel={day.dayLabel} />
          }

          return EmptyDay(index)
        })}
      </div>
    </div>
  )
}

function EmptyDay(index) {
  return (
    <button className={'empty-button'} key={index}>
      00
    </button>
  )
}

/// DAY
function Day({dayLabel, date}) {
  const dayRef = React.useRef(null)
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover
  } = React.useContext(DatepickerContext)
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef
  })

  if (!dayLabel) {
    return <div />
  }

  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      style={{
        color: isSelected || isSelectedStartOrEnd ? '#FFF' : '#000',
        background: isSelected || isSelectedStartOrEnd ? '#5a646e' : '#FFF'
      }}>
      {dayLabel}
    </button>
  )
  // return <div></div>
}
