import * as React from 'react'
import {ModInput} from '../../ui/ModInput'
import {ModGridRow} from '../../layout/ModGridRow'
import {FormCredentials} from 'interfaces/InterfacesBlocks'

export interface ModFormCredentialsProps {
  showEmergencyNumber?: boolean
  showSecondMailAdress?: boolean

  // labels
  formLabels: FormCredentials

  // values
  name: string
  surname: string
  street: string
  postalCode: string
  city: string
  canton: string
  phone: string
  emergencyPhone: string
  birthDate: string
  email: string
  email2: string

  // on value change
  onFormChange(key: string, value: string): void
}

export function ModFormCredentials({
  showEmergencyNumber = false,
  showSecondMailAdress = false,
  name,
  surname,
  street,
  postalCode,
  city,
  canton,
  phone,
  emergencyPhone,
  birthDate,
  email,
  email2,
  onFormChange,
  formLabels
}: ModFormCredentialsProps) {
  const i18n = formLabels.credentials._i18nCL

  return (
    <>
      <h3 className="form-first-header">{i18n.subTitleCredentials}</h3>
      <ModGridRow>
        {[
          {
            columnWidth: 1,
            component: (
              <React.Fragment key={i18n.subTitleCredentials}>
                <ModInput
                  type={'text'}
                  value={surname}
                  onChange={e => onFormChange('surname', e.target.value)}
                  key={i18n.labelSurname}
                  label={i18n.labelSurname}
                  required={true}
                />
                <ModInput
                  type={'text'}
                  value={name}
                  onChange={e => onFormChange('name', e.target.value)}
                  label={i18n.labelName}
                  key={i18n.labelName}
                  required={true}
                />
                <ModInput
                  type={'text'}
                  value={street}
                  onChange={e => onFormChange('street', e.target.value)}
                  label={i18n.labelStreet}
                  key={i18n.labelStreet}
                  required={true}
                />
                <ModInput
                  type={'text'}
                  value={city}
                  onChange={e => onFormChange('city', e.target.value)}
                  label={i18n.labelCity}
                  key={i18n.labelCity}
                  required={true}
                />
                <ModGridRow>
                  {[
                    {
                      columnWidth: 1,
                      component: (
                        <ModInput
                          type={'number'}
                          value={postalCode}
                          onChange={e => onFormChange('postalCode', e.target.value)}
                          label={i18n.labelPostalCode}
                          key={i18n.labelPostalCode}
                          required={true}
                        />
                      )
                    },
                    {
                      columnWidth: 1,
                      component: (
                        <ModInput
                          type={'text'}
                          value={canton}
                          onChange={e => onFormChange('canton', e.target.value)}
                          label={i18n.labelCanton}
                          key={i18n.labelCanton}
                          required={true}
                        />
                      )
                    }
                  ]}
                </ModGridRow>
                <ModInput
                  type={'email'}
                  value={email}
                  onChange={e => onFormChange('email', e.target.value)}
                  label={i18n.labelMail}
                  key={i18n.labelMail}
                  required={true}
                />
                {showSecondMailAdress && (
                  <ModInput
                    type={'email'}
                    value={email2}
                    onChange={e => onFormChange('email2', e.target.value)}
                    label={i18n.labelSecondMail}
                    key={i18n.labelSecondMail}
                  />
                )}
                <ModInput
                  type={'number'}
                  value={phone}
                  onChange={e => onFormChange('phone', e.target.value)}
                  label={i18n.labelPhone}
                  key={i18n.labelPhone}
                  required={true}
                />
                {showEmergencyNumber && (
                  <ModInput
                    type={'number'}
                    value={emergencyPhone}
                    onChange={e => onFormChange('emergencyPhone', e.target.value)}
                    label={i18n.labelEmergencyPhone}
                    key={i18n.labelEmergencyPhone}
                    required={true}
                  />
                )}
                <ModInput
                  type={'date'}
                  value={birthDate}
                  onChange={e => onFormChange('birthDate', e.target.value)}
                  label={i18n.labelBirthdate}
                  key={i18n.labelBirthdate}
                  required={true}
                />
              </React.Fragment>
            )
          },
          {
            columnWidth: 1,
            component: <div></div>
          }
          // {
          //   columnWidth: 1,
          //   component: (
          //     <ModInput
          //       type={'date'}
          //       value={birthDate}
          //       onChange={e => onFormChange('birthDate', e.target.value)}
          //       label={i18n.labelBirthdate}
          //       key={i18n.labelBirthdate}
          //       required={true}
          //     />
          //   )
          // }
        ]}
      </ModGridRow>
    </>
  )
}
