import * as React from 'react'
import {BlockAdvertisment} from 'interfaces/InterfacesBlocks'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModImgTag from 'components/common/ModImgTag'
import {isBlockActiveNow} from 'common/CmsUtils'

export interface ModBlockAdvertismentProps {
  content: BlockAdvertisment
}

export function ModBlockAdvertisment({content}: ModBlockAdvertismentProps) {
  if (!content.image) {
    // console.warn('no image defined in block advertisment')
    return null
  }

  if (!isBlockActiveNow(content)) {
    return null
  }

  return (
    <div className="block-advertisment">
      {content.link ? (
        <ModAnchorTag linkObject={content.link}>
          <ModImgTag
            imgObject={content.image}
            transformation={'c_crop,r_max'} //?
            fileFormat={'png'}
          />
        </ModAnchorTag>
      ) : (
        <div>
          <ModImgTag
            imgObject={content.image}
            transformation={'c_crop,r_max'} //?
            fileFormat={'png'}
          />
        </div>
      )}
    </div>
  )
}
