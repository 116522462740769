import React = require('react')
import {ModGridRow} from 'components/layout/ModGridRow'
import {FormattedMessage} from 'react-intl'

export interface SearchTemplateProps {
  currentLanguage?: string
}

export function SearchTemplate({currentLanguage}: SearchTemplateProps) {
  const [recheckCookie, setRecheckCookie] = React.useState(false)

  window.addEventListener('CookiebotOnLoad', function (e) {
    setRecheckCookie(true)
  })

  React.useEffect(() => {
    // @ts-ignore
    if (Cookiebot.consent.marketing) {
      ;(function () {
        var cx = '402d2cd20ab5944c8'
        var gcse = document.createElement('script')
        gcse.type = 'text/javascript'
        gcse.async = true
        gcse.src = 'https://cse.google.com/cse.js?cx=' + cx
        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(gcse, s)
      })()
    }
  }, [recheckCookie])

  return (
    <main>
      <ModGridRow>
        {[
          {
            columnWidth: 2,
            component: (
              <div key="content" className="search-content">
                <div className="cookieconsent-optout-marketing">
                  <div className="cookieconsent-optout-marketing-custom">
                    <p>
                      <FormattedMessage
                        id={'searchCookie.acceptCookiesText1'}
                        defaultMessage={'Bitte'}
                      />{' '}
                      <a href="javascript:Cookiebot.renew()">
                        <FormattedMessage
                          id={'searchCookie.acceptCookiesLink'}
                          defaultMessage={'akzeptieren Sie die Marketing Cookies'}
                        />
                      </a>{' '}
                      <FormattedMessage
                        id={'searchCookie.acceptCookiesText2'}
                        defaultMessage={'um die Suche zu benutzen.'}
                      />
                    </p>
                  </div>
                </div>
                <div className="gcse-search"></div>
              </div>
            )
          },

          {
            columnWidth: 1,
            component: <div></div>
          }
        ]}
      </ModGridRow>
    </main>
  )
}
