import * as React from 'react'
import * as isoFetch from 'isomorphic-fetch'
import {ModInput} from 'components/ui/ModInput'
import {ModPrimaryButton} from 'components/ui/ModPrimaryButton'
import {ModXLSUpload} from 'components/templates/importExport/ModXLSUpload'
import {ProfessionalsAdapter} from './ProfessionalsAdapter'
import Notifications, {notify} from 'react-notify-toast'
import Loader from 'react-loader-spinner'
import {DanceMediationAdapter} from './DanceMediationAdapter'

export function ImportExportTemplate() {
  const [auth, setAuth] = React.useState({user: '', pw: '', isLoggedIn: false})
  const [isImportingRegisteredDancer, setIsImportingRegisteredDancer] = React.useState(false)
  const [isImportingDanceMediation, setIsImportingDanceMediation] = React.useState(false)
  const [isExportingTSD, setIsExportingTSD] = React.useState(false)
  const [isExportingSD, setIsExportingSD] = React.useState(false)
  const [importingLabelRegisteredDancer, setImportingLabelRegisteredDancer] =
    React.useState('wird geladen')
  const [importingLabelDanceMediation, setImportingLabelDanceMediation] =
    React.useState('wird geladen')

  function onLogin() {
    isoFetch('/api/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/play, text/html, *.*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user: auth.user, pw: auth.pw})
    }).then(result => {
      if (result.ok) {
        let newAuth = {...auth}
        newAuth.isLoggedIn = true
        setAuth(newAuth)
      } else {
        notify.show('passwort oder benutzername falsch', 'error', 4000)
      }
    })
  }

  function handleTSDExport() {
    console.log('importexport: tsd export')
    isoFetch('/api/download-tsd', {
      method: 'GET',
      headers: {
        Accept: 'application/zip, application/json, application/xml, text/play, text/html, *.*',
        'Content-Type': 'application/zip'
      }
    }).then(result => {
      if (result.status == 500) {
        notify.show('Fehler', 'error', 4000)
        setIsExportingTSD(false)
      } else {
        result.blob().then(blob => {
          hackToDownload(blob, 'Talentfoerderung_TSD.zip')
          setIsExportingTSD(false)
        })
      }
    })
  }

  function handleSDExport() {
    console.log('importexport: sd export')
    isoFetch('/api/download-sd', {
      method: 'GET',
      headers: {
        Accept: 'application/json, application/xml, text/play, text/html, *.*',
        'Content-Type': 'application/json'
      }
    }).then(result => {
      if (result.status == 204) notify.show('Keine neuen Anmeldungen', 'info', 3000)
      else if (result.status == 500) {
        notify.show('Fehler', 'error', 4000)
        setIsExportingSD(false)
      } else {
        result.blob().then(blob => {
          hackToDownload(blob, 'Talentfoerderung_SD.xls')
          setIsExportingSD(false)
        })
      }
    })
  }

  function handleImportRegisteredDancer(data) {
    console.log('importexport: berufsregister import')
    setIsImportingRegisteredDancer(true)

    let insertableData = []
    for (let i = 1; i < data.length; i++) {
      insertableData.push(ProfessionalsAdapter(data[0], data[i]))
    }
    ///api/import
    isoFetch('/api/import', {
      method: 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/play, text/html, *.*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(insertableData)
    }).then(response => {
      if (response.status == 200) {
        setIsImportingRegisteredDancer(false)
        setImportingLabelRegisteredDancer('fertig importiert')
      }
      if (response.status == 500) {
        notify.show('Fehler', 'error', 4000)
        setIsImportingRegisteredDancer(false)
        setImportingLabelRegisteredDancer('fehler beim importieren von')
      }
    })
  }

  function handleImportDanceMediation(data) {
    console.log('importexport: tanzvermittlung import')
    console.log(data)

    setIsImportingDanceMediation(true)

    let insertableData = []
    for (let i = 1; i < data.length; i++) {
      const insertData = DanceMediationAdapter(data[0], data[i])
      if (insertData) {
        insertableData.push(insertData)
      }
    }
    ///api/import_dance_mediation
    isoFetch('/api/import_dance_mediation', {
      method: 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/play, text/html, *.*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(insertableData)
    }).then(response => {
      if (response.status == 200) {
        setIsImportingDanceMediation(false)
        setImportingLabelDanceMediation('fertig importiert')
      }
      if (response.status == 500) {
        notify.show('Fehler', 'error', 4000)
        setIsImportingDanceMediation(false)
        setImportingLabelDanceMediation('fehler beim importieren von')
      }
    })
  }

  function hackToDownload(blob, fileName: string) {
    if (blob) {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}`)
      // 3. Append to html page
      document.body.appendChild(link)
      // 4. Force download
      link.click()
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link)
    }
  }

  return (
    <div className="importexport">
      <Notifications />
      {!auth.isLoggedIn ? (
        <div>
          <p>
            Logge dich ein um in den Import / Export Bereich zu kommen <br />
            <br />
          </p>
          <ModInput
            label="Benutzername"
            onChange={e => {
              let newAuth = {...auth}
              newAuth.user = e.target.value
              setAuth(newAuth)
            }}
            required={true}
          />
          <ModInput
            label="Passwort"
            onChange={e => {
              let newAuth = {...auth}
              newAuth.pw = e.target.value
              setAuth(newAuth)
            }}
            required={true}
          />
          <ModPrimaryButton
            label={'login'}
            type={'button'}
            onClick={e => {
              onLogin()
            }}
          />
        </div>
      ) : (
        <div>
          {isExportingSD && <Loader type="ThreeDots" color="##ec371e" height={50} width={50} />}
          <ModPrimaryButton
            label={'export SD'}
            type={'button'}
            onClick={e => {
              setIsExportingSD(true)
              handleSDExport()
            }}
          />
          <br />
          <br />
          <br />
          {isExportingTSD && <Loader type="ThreeDots" color="##ec371e" height={50} width={50} />}
          <ModPrimaryButton
            label={'export TSD'}
            type={'button'}
            onClick={e => {
              setIsExportingTSD(true)
              handleTSDExport()
            }}
          />
          <br />
          <br />
          <br />
          {isImportingRegisteredDancer && (
            <Loader type="ThreeDots" color="##ec371e" height={50} width={50} />
          )}
          <ModXLSUpload
            buttonId="btnImportBerufsregister"
            buttonLabel="Import Berufsregister"
            label={importingLabelRegisteredDancer}
            onUpload={handleImportRegisteredDancer}
          />
          <br />
          {isImportingDanceMediation && (
            <Loader type="ThreeDots" color="##ec371e" height={50} width={50} />
          )}
          <ModXLSUpload
            buttonId="btnImportTanzvermittlung"
            buttonLabel="Import Tanzvermittlung"
            label={importingLabelDanceMediation}
            onUpload={handleImportDanceMediation}
          />
        </div>
      )}
    </div>
  )
}
