import * as React from 'react'
import {ModInput} from '../../ui/ModInput'
import {ModGridRow} from '../../layout/ModGridRow'
import {ModPrimaryButton} from '../../ui/ModPrimaryButton'
import {FormattedMessage} from 'react-intl'
import {BlockFormSummerdance} from '../../../interfaces/InterfacesBlocks'
import {ModRadioGroup} from 'components/ui/ModRadioGroup'
import {ModFormCredentials} from './ModFormCredentials'
import ModIntlWrapper from 'components/common/utils/ModIntlWrapper'
import {FormInputEducation, FormInputCredentials, FormEducationCat} from './FormInterfaces'
import {isCheckedYes, getCheckedId, getEducationSubjectOptions} from './FormHelpers'
import {ModEducationInputs} from './ModEducationInputs'
import {NavigationNodeContext} from 'components/ModApp'
import {isoPostSummerdanceRegistry} from './ClassFormSummerdanceViewModel'
import {FormResponseType, ModFormResponse} from './ModFormResponseMessage'

export interface FormInputSummerdanceRegistry extends FormInputCredentials, FormInputEducation {
  travel: string
  courseFees: string
  isProfEducation: boolean
  profEducation: string
  isPointDance: boolean
  food: string
  hasAlreadyAttended: boolean
  yearAlreadyAttended: string
  healthInsurance: string
  insurancePolice: string
  medicalComments: string
  comments: string
}

const emptySubject = {category: null, subject: '', lectures: '', years: ''}
const defaultFormState: FormInputSummerdanceRegistry = {
  name: '',
  surname: '',
  street: '',
  postalCode: '',
  city: '',
  canton: '',
  phone: '',
  birthDate: '',
  email: '',
  email2: '',
  emergencyPhone: '',
  healthInsurance: '',
  insurancePolice: '',

  hasAlreadyAttended: false,
  yearAlreadyAttended: '',

  currentSchool: '',
  currentTeacher: '',
  oldSchool: '',

  education: [{...emptySubject}],

  isProfEducation: false,
  profEducation: '',
  isPointDance: false,

  travel: '',
  courseFees: '',

  food: '',
  // vegetarian: false,
  // vegan: false,

  medicalComments: '',
  comments: ''
}

export interface ModBlockFormSummerdanceProps {
  content: BlockFormSummerdance
}

export function ModBlockFormSummerdance({content}: ModBlockFormSummerdanceProps) {
  if (!content._i18nCL) {
    return null
  }

  const i18n = content._i18nCL
  const educationi18n = content.education._i18nCL

  if (!educationi18n) {
    return null
  }

  const [formState, setFormState] = React.useState(defaultFormState)
  const [responseState, setResponseState] = React.useState(<div></div>)
  const currentLanguage = React.useContext(NavigationNodeContext).currentLanguage

  const radioGroupOptions = formatMessageCallback => {
    return [
      {id: 'yes', label: formatMessageCallback({id: 'yes', defaultMessage: 'ja'})},
      {id: 'no', label: formatMessageCallback({id: 'no', defaultMessage: 'Nein'})}
    ]
  }

  function onFormChange(key: string, value: string | boolean) {
    let newState = {...formState}
    newState[key] = value
    setFormState(newState)
  }

  function onEducationChange(
    values: {idx: number; key: string; value: string | FormEducationCat}[]
  ) {
    let newState = {...formState}
    for (let val of values) {
      newState.education[val.idx][val.key] = val.value
    }
    setFormState(newState)
  }

  function onAddSubject() {
    let newState = {...formState}
    newState.education.push({...emptySubject}) // clone object
    setFormState(newState)
  }

  function onSave(e, formatMessage) {
    e.preventDefault()
    isoPostSummerdanceRegistry(formState, currentLanguage, content).then(response => {
      if (response.ok) {
        let message: string = formatMessage({
          id: 'form.successSD',
          defaultMessage: 'Erfolgreiche Anmeldung'
        })
        setResponseState(<ModFormResponse message={message} type={FormResponseType.Success} />)
        setFormState(defaultFormState)
      } else {
        setResponseState(
          <ModFormResponse
            message={`Server Error ${response.status} ${response.statusText}`}
            type={FormResponseType.Error}
          />
        )
      }
    })
  }

  function travelLabel(label: string, costs: number) {
    if (costs >= 0) {
      return `${label} (CHF ${costs}.-)`
    } else return label
  }

  return (
    <div className="block-form-summerdance">
      <h3>{i18n.formTitle}</h3>

      <ModIntlWrapper>
        {({formatMessage}) => (
          <form onSubmit={e => onSave(e, formatMessage)}>
            <fieldset>
              <ModIntlWrapper>
                {({formatMessage}) => (
                  <>
                    <ModFormCredentials
                      showEmergencyNumber={true}
                      showSecondMailAdress={true}
                      formLabels={content}
                      name={formState.name}
                      surname={formState.surname}
                      street={formState.street}
                      postalCode={formState.postalCode}
                      city={formState.city}
                      canton={formState.canton}
                      phone={formState.phone}
                      emergencyPhone={formState.emergencyPhone}
                      birthDate={formState.birthDate}
                      email={formState.email}
                      email2={formState.email2}
                      onFormChange={onFormChange}
                    />
                    <ModGridRow>
                      {[
                        {
                          columnWidth: 1,
                          component: (
                            <ModInput
                              key={i18n.labelHealthInsurance}
                              type={'text'}
                              label={i18n.labelHealthInsurance}
                              value={formState.healthInsurance}
                              onChange={e => onFormChange('healthInsurance', e.target.value)}
                              required={true}
                            />
                          )
                        },
                        {
                          columnWidth: 1,
                          component: (
                            <ModInput
                              key={i18n.labelInsurancePolice}
                              type={'text'}
                              label={i18n.labelInsurancePolice}
                              value={formState.insurancePolice}
                              onChange={e => onFormChange('insurancePolice', e.target.value)}
                              required={true}
                            />
                          )
                        }
                      ]}
                    </ModGridRow>

                    <ModGridRow>
                      {[
                        {
                          columnWidth: 1,
                          component: (
                            <ModRadioGroup
                              key={i18n.labelSD}
                              groupName={'attended'}
                              label={i18n.labelSD}
                              options={radioGroupOptions(formatMessage)}
                              checked={getCheckedId(formState.hasAlreadyAttended)}
                              isInline={true}
                              onCheck={option =>
                                onFormChange('hasAlreadyAttended', isCheckedYes(option.id))
                              }
                            />
                          )
                        },
                        {
                          columnWidth: 1,
                          component: formState.hasAlreadyAttended ? (
                            <ModInput
                              key={i18n.labelAttendenceYear}
                              type={'text'}
                              label={i18n.labelAttendenceYear}
                              value={formState.yearAlreadyAttended}
                              onChange={e => onFormChange('yearAlreadyAttended', e.target.value)}
                            />
                          ) : (
                            <></>
                          )
                        }
                      ]}
                    </ModGridRow>

                    {/* school */}
                    <h3>{educationi18n.titleEducation}</h3>
                    <h4>{educationi18n.subtitleSchool}</h4>
                    <ModInput
                      type={'text'}
                      label={educationi18n.labelNameSchool}
                      value={formState.currentSchool}
                      onChange={e => onFormChange('currentSchool', e.target.value)}
                      required={true}
                    />
                    <ModInput
                      type={'text'}
                      label={educationi18n.labelNameTeacher}
                      value={formState.currentTeacher}
                      onChange={e => onFormChange('currentTeacher', e.target.value)}
                      required={true}
                    />
                    <ModInput
                      type={'text'}
                      label={educationi18n.labelOldSchool}
                      value={formState.oldSchool}
                      onChange={e => onFormChange('oldSchool', e.target.value)}
                    />

                    <ModGridRow>
                      {[
                        {
                          columnWidth: 1,
                          component: (
                            <ModRadioGroup
                              groupName={'profEducation'}
                              key={i18n.labelIsProfEducation}
                              label={i18n.labelIsProfEducation}
                              options={[
                                {
                                  id: 'yes',
                                  label: formatMessage({id: 'yes', defaultMessage: 'ja'})
                                },
                                {
                                  id: 'no',
                                  label: i18n.labelNoMind10hoursTraining
                                }
                              ]}
                              checked={getCheckedId(formState.isProfEducation)}
                              isInline={true}
                              required={true}
                              onCheck={option =>
                                onFormChange('isProfEducation', isCheckedYes(option.id))
                              }
                            />
                          )
                        }
                      ]}
                    </ModGridRow>
                    <ModGridRow>
                      {[
                        {
                          columnWidth: 1,
                          component: (
                            <React.Fragment key={i18n.labelProf}>
                              {formState.isProfEducation && (
                                <ModInput
                                  type={'text'}
                                  label={i18n.labelProf}
                                  value={formState.profEducation}
                                  required={formState.isProfEducation}
                                  onChange={e => onFormChange('profEducation', e.target.value)}
                                />
                              )}
                            </React.Fragment>
                          )
                        }
                      ]}
                    </ModGridRow>

                    <ModGridRow>
                      {[
                        {
                          columnWidth: 1,
                          component: (
                            <ModRadioGroup
                              groupName={'examen'}
                              key={i18n.labelIsExamen}
                              label={i18n.labelIsExamen}
                              options={radioGroupOptions(formatMessage)}
                              checked={getCheckedId(formState.isPointDance)}
                              isInline={true}
                              onCheck={option =>
                                onFormChange('isPointDance', isCheckedYes(option.id))
                              }
                            />
                          )
                        }
                        // {
                        //   columnWidth: 1,
                        //   component: (
                        //     <React.Fragment key={i18n.labelExamen}>
                        //       {formState.isExamen && (
                        //         <ModInput
                        //           type={'text'}
                        //           label={i18n.labelExamen}
                        //           value={formState.examen}
                        //           onChange={e => onFormChange('examen', e.target.value)}
                        //         />
                        //       )}
                        //     </React.Fragment>
                        //   )
                        // }
                      ]}
                    </ModGridRow>

                    {/*  TODO addable inputs */}
                    <h4>{educationi18n.subtitleEducation}</h4>
                    <ModEducationInputs
                      required
                      labels={{
                        mainSubject: educationi18n.labelMainSubject,
                        lectures: educationi18n.labelLectures,
                        years: educationi18n.labelEducationYears,
                        addMore: educationi18n.labelAddMore
                      }}
                      options={getEducationSubjectOptions(educationi18n)}
                      education={formState.education}
                      onAddSubject={onAddSubject}
                      onSubjectChange={onEducationChange}
                    />

                    {/* travel was temporary deleted but they wantet it back so we uncomment this option and keep this note as hint if they like to hide it again */}
                    {/* travel */}
                    <h3>{i18n.titleTravel}</h3>
                    <ModRadioGroup
                      groupName={'travel'}
                      required={true}
                      options={[
                        {id: 'Individual', label: i18n.labelSingle},
                        {
                          id: 'CarFromSolothurn',
                          label: travelLabel(i18n.labelCarSolothurn, content.costsCarSolothurn)
                        },
                        {
                          id: 'CarFromZuerich',
                          label: travelLabel(i18n.labelCarZurich, content.costsCarZurich)
                        }
                      ]}
                      checked={getCheckedId(formState.travel)}
                      isInline={true}
                      onCheck={option => onFormChange('travel', isCheckedYes(option.id, true))}
                    />

                    {/* costs */}
                    <h3>{i18n.titleCosts}</h3>
                    <ModRadioGroup
                      className={'radio-fees'}
                      required={true}
                      groupName={'courseFees'}
                      options={[
                        {
                          id: 'inclHosting',
                          label: `${i18n.labelInclHosting} (CHF ${content.costsInclHosting}.-)`
                        },
                        {
                          id: 'inclFood',
                          label: `${i18n.labelInclFood} (CHF ${content.costsInclFood}.-)`
                        }
                      ]}
                      checked={getCheckedId(formState.courseFees)}
                      isInline={true}
                      onCheck={option => onFormChange('courseFees', isCheckedYes(option.id, true))}
                    />

                    {/* allergies */}
                    <h3>{i18n.titleAllergies}</h3>
                    <ModRadioGroup
                      groupName={'food'}
                      options={[
                        {id: 'vegetarian', label: `${i18n.labelVegetarian}`},
                        {id: 'vegan', label: `${i18n.labelVegan}`},
                        {id: 'none', label: `${i18n.labelNoSpecialFood}`}
                      ]}
                      checked={getCheckedId(formState.food)}
                      isInline={true}
                      onCheck={option => onFormChange('food', isCheckedYes(option.id, true))}
                    />

                    <p className="small-text">{i18n.infoAllergies}</p>
                    <ModInput
                      type={'text'}
                      label={i18n.labelMessageAllergies}
                      value={formState.medicalComments}
                      onChange={e => onFormChange('medicalComments', e.target.value)}
                    />

                    <ModInput
                      type={'text'}
                      label={i18n.labelMore}
                      value={formState.comments}
                      onChange={e => onFormChange('comments', e.target.value)}
                    />

                    {responseState}

                    <ModPrimaryButton type="submit">
                      <FormattedMessage id="form.confirm" defaultMessage="Anmelden" />
                    </ModPrimaryButton>
                  </>
                )}
              </ModIntlWrapper>
            </fieldset>
          </form>
        )}
      </ModIntlWrapper>
    </div>
  )
}
