import * as React from 'react'

import {PageTemplateProps} from '../IPageTemplateProps'
import ModBreadCrumb from '../../ModBreadCrumb'
import {ModGridRow} from 'components/layout/ModGridRow'
import {ModNewsList} from './ModNewsList'
import {NewsTemplate} from 'interfaces/Interfaces'
import {BlocksRenderer} from 'components/blocks/BlocksRenderer'
import {ModSimplePagination} from 'components/ui/ModSimplePagination'
import {ModPageHeader} from '../DetailPageTemplate'

export interface NewsPageTemplateProps extends PageTemplateProps, NewsTemplate {}

export function NewsPageTemplate({
  navigationTree,
  currentLanguage,
  rootState,
  pathname,
  entryCount,
  pageCount,
  news,
  header,
  sidePanelContent: sidePanelBlockListData
}: NewsPageTemplateProps) {
  if (news == undefined || news == null) {
    // console.warn('news are undefined or null')
    return null
  }

  return (
    <main>
      <ModGridRow>
        {[
          {
            columnWidth: 2,
            component: (
              <div key="content" className="main-content">
                <ModPageHeader title={header._i18nCL.title} lead={header._i18nCL.lead} />
                <ModNewsList news={news} isCollapsed={true} />

                <ModSimplePagination entryCount={entryCount} pageCount={pageCount} />

                <ModBreadCrumb navigationTree={navigationTree} pathName={pathname} />
              </div>
            )
          },

          {
            columnWidth: 1,
            component: (
              <BlocksRenderer
                key={'side'}
                rootState={rootState}
                blockListData={sidePanelBlockListData}
              />
            )
          }
        ]}
      </ModGridRow>
    </main>
  )
}
