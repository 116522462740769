import * as React from 'react'
import NavLink from './common/NavLink'

import { NavigationNode, PageType } from "../interfaces/Interfaces";
import { hasContent } from "../common/Utils";
import { NavigationNodeContext } from './ModApp';
import { ModIcon, IconType } from './common/ModIcon';

export interface BreadCrumbsExtras { type: PageType, title: string }

export interface ModBreadCrumbProps {
  navigationTree: NavigationNode
  pathName: string
  pageExtras?: BreadCrumbsExtras
}

interface BreadCrumbItem {
  breadCrumbNode: {
    relativeUrl: string
    label: string
  }
  navigationNode: NavigationNode | null
}

export default function ModBreadCrumb({ navigationTree, pathName, pageExtras }: ModBreadCrumbProps) {
  function findActiveNodes(navigationTree: NavigationNode[], slugs: string[]): BreadCrumbItem[] {
    let activeNodes: BreadCrumbItem[] = []
    if (navigationTree && slugs.length > 0) {
      let slug = slugs.shift()

      for (let i = 0; i < navigationTree.length; i++) {
        const item = navigationTree[i]
        if (item._i18nCL && item._i18nCL.slug === slug) {
          activeNodes.push({ breadCrumbNode: { relativeUrl: item._i18nCL.relativeUrl, label: item._i18nCL.label }, navigationNode: item })
          return activeNodes.concat(findActiveNodes(item.children, slugs))
        }
      }
    }
    return []
  }

  let visitedNodes = new Map<string, boolean>()

  function findActiveNodesForStaticPage(navigationTree: NavigationNode[], endNode: NavigationNode, staticBreadcrumbLabel: string): BreadCrumbItem[] {

    let result = []
    for (let navigationNode of navigationTree) {
      let nodeResult = findStaticBreadcrumPath(navigationNode, endNode, [])
      if (nodeResult) {
        result = nodeResult
        result.push({ breadCrumbNode: { relativeUrl: '', label: staticBreadcrumbLabel }, navigationNode: null })
      }
    }

    return result
  }

  function findStaticBreadcrumPath(currentNode: NavigationNode, endNode: NavigationNode, breadcrumbPath: BreadCrumbItem[]): BreadCrumbItem[] {
    if(!currentNode._i18nCL){
      return
    }
    breadcrumbPath.push({ breadCrumbNode: { relativeUrl: currentNode._i18nCL.relativeUrl, label: currentNode._i18nCL.label }, navigationNode: currentNode })

    for (let i = 0; i < currentNode.children.length; i++) {
      const next = currentNode.children[i]

      if (next._id == endNode._id) {
        breadcrumbPath.push({ breadCrumbNode: { relativeUrl: next._i18nCL.relativeUrl, label: next._i18nCL.label }, navigationNode: next })
        return breadcrumbPath
      }

      if (!visitedNodes.has(next._id)) {
        findStaticBreadcrumPath(next, endNode, breadcrumbPath)
      }
    }

    breadcrumbPath.pop()
    visitedNodes.set(currentNode._id, true)
  }

  if (!navigationTree || !hasContent(pathName)) {
    return null
  }

  const slugs = pathName.split('/')
  slugs.shift() // remove empty slug
  slugs.shift() // remove lang
  let activeNodes = findActiveNodes(navigationTree.children, slugs)

  const context = React.useContext(NavigationNodeContext)
  // if page static then find active nodes for static page
  if (pageExtras && pageExtras.type == PageType.NewsDetail) {
    activeNodes = findActiveNodesForStaticPage(navigationTree.children, context.settings.staticNewsNode, pageExtras.title)
  }

  if (activeNodes.length > 0) {
    if (!activeNodes[0].navigationNode._i18nCL.showInMenu) {
      activeNodes = []
    }
  }

  if (!(activeNodes && activeNodes.length > 0)) {
    return null
  }


  let breadcrumb = activeNodes.map(function (activeItem, index) {
    if (!activeItem) {
      return null
    }

    let slug = <NavLink key={index} to={activeItem.breadCrumbNode.relativeUrl}>
      {activeItem.breadCrumbNode.label}
    </NavLink>
    let icon = null
    if (index > 0) {
      icon = <span className="breadcrumb-level">&raquo;</span>
      slug = <NavLink key={index} to={activeItem.breadCrumbNode.relativeUrl}>
        {activeItem.breadCrumbNode.label}
      </NavLink>
    } if (index == activeNodes.length - 1) {
      icon = <span className="breadcrumb-level">&raquo;</span>
      slug = <span key={index}>{activeItem.breadCrumbNode.label}</span>
    }

    return (
      <li key={index}>
        {icon}
        {slug}
      </li>
    )
  })

  return (
    <div className="breadcrumb">
      <ul>
        {breadcrumb}
      </ul>

      {(pageExtras && pageExtras.type == PageType.NewsDetail) && <span className='share-news'><a target="_blank" href={`http://instagram.com/share?url=${context.router.props.initialLocation}`}>
        <ModIcon type={IconType.Instagram} /></a>
        <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${context.router.props.initialLocation}`} className="fb-xfbml-parse-ignore">
          <ModIcon type={IconType.Facebook} /></a>
      </span>}
    </div>
  )

}