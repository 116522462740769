import * as React from 'react'
import ModApp, {ModAppProps} from "../components/ModApp";
import {IsomorphicURL} from "../common/IsomorphicURL";
declare var ga: any;

export interface RouterLocationExtended extends RouterLocation {
  action: string
  pageYOffset: number
  href: string
}

export interface RouterLocation {
  pathname: string
  hash?: string
  query?: URLSearchParams
}

export interface CmsRouterProps {
  [key: string]: any

  initialLocation: string
}


interface CmsRouterState {
  location: RouterLocationExtended
}

export default class CmsRouter extends React.Component<CmsRouterProps, CmsRouterState> {

  constructor(props: CmsRouterProps) {
    super(props)

    this.state = {
      location: urlToRouterLocation(props.initialLocation)
    }

    this.onPopStateHandler = this.onPopStateHandler.bind(this)
    this.track = this.track.bind(this)

    if (typeof window !== 'undefined') {
      window.onpopstate = this.onPopStateHandler
    }
  }

  private onPopStateHandler(event) {
    const routerLocation = urlToRouterLocation(location.href)
    routerLocation.pageYOffset = event.state.pageYOffset
    this.setState({
      location: routerLocation
    })
    this.track(location.href)
  }

  public push(_location: RouterLocation | string) {

    let newLocation: RouterLocationExtended
    let url: URL

    if (typeof _location === 'string') {
      url = new URL(_location, location.origin)
      newLocation = {
        pathname: url.pathname,
        query: url.searchParams,
        hash: url.hash,
        action: 'PUSH',
        pageYOffset: 0,
        href: '',
      }
    }
    else {
      const nl = _location as RouterLocation
      newLocation = {
        pathname: nl.pathname,
        query: nl.query,
        hash: nl.hash,
        action: 'PUSH',
        pageYOffset: 0,
        href: '',
      }
    }

    if (!newLocation.query) {
      newLocation.query = null
    }

    url = new URL(newLocation.pathname, location.origin)
    url.pathname = newLocation.pathname
    if (newLocation.query) {
      url.search = newLocation.query.toString()
    }
    if (newLocation.hash) {
      url.hash = newLocation.hash
    }

    newLocation.action = 'PUSH'
    newLocation.pageYOffset = 0
    newLocation.href = url.href

    if (typeof history !== 'undefined' && typeof  _location !== 'undefined') {
      history.replaceState({pageYOffset: window.pageYOffset}, "", this.state.location.href)
      history.pushState({pageYOffset: 0}, "", url.href)
      this.setState({location: newLocation})
      this.track(url.href)
    }
  }

  private track(pathname: String): void {
    if (typeof ga !== 'undefined') {
      ga('set', 'page', pathname)
      ga('send', 'pageview')
    }
  }

  render() {
    let _props: ModAppProps = Object.assign({}, this.props, {
      location: this.state.location,
      router: this
    })
    return <ModApp {..._props}/>
  }
}

function urlToRouterLocation(relativeUrl: string): RouterLocationExtended {
  let url = new IsomorphicURL(relativeUrl)
  return {
    pathname: url.pathname,
    query: url.searchParams,
    hash: url.hash,
    action: 'POP',
    pageYOffset: -1,
    href: url.href
  }
}