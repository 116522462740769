import {DancerMediation} from '../../../interfaces/InterfacesEntities'
import Notifications, {notify} from 'react-notify-toast'

export function DanceMediationAdapter(headerData, data) {
  function testXls(name: string, position: number): boolean {
    if (headerData[position] != name) {
      error(name, headerData[position], position)
      return false
    }
    return true
  }

  function fieldToArray(data) {
    return data
      ? data
          .replaceAll(' ', '')
          .split(';')
          .filter(a => a)
      : []
  }

  let result: DancerMediation = {
    name: testXls('Name', 0) && data[0] ? data[0] : '',
    surname: testXls('Vorname', 1) && data[1] ? data[1] : '',
    workLocations: testXls('BR-V_Arbeitsort', 2) && fieldToArray(data[2]),
    websites: testXls('Webseite', 3) && fieldToArray(data[3]),
    mediationLanguages: testXls('Arbeitssprache-n', 4) && fieldToArray(data[4]),
    targetGroups: {
      children: testXls('Kinder', 5) && data[5] ? data[5] : false,
      adolescents: testXls('Jugendliche', 6) && data[6] ? data[6] : false,
      adults: testXls('Erwachsene', 7) && data[7] ? data[7] : false,
      seniors: testXls('Senior-in', 8) && data[8] ? data[8] : false,
      specialNeeds: testXls('Menschen spez Bedürfnisse', 9) && data[9] ? data[9] : false,
      others: testXls('Andere', 10) && data[10] ? data[10] : false,
      comment: testXls('BRV_Bem_Zielgruppen', 18) && data[18] ? data[18] : ''
    },
    mediationFields: {
      danceHealth: testXls('Tanz-Gesundheit', 11) && data[11] ? data[11] : false,
      danceResearch: testXls('Tanz-Forschung', 12) && data[12] ? data[12] : false,
      danceSchool: testXls('Tanz-Schule', 13) && data[13] ? data[13] : false,
      mediationDanceWork: testXls('Vermittlung-Tanzwerken', 14) && data[14] ? data[14] : false,
      danceContext: testXls('Tanz-Kontext', 15) && data[15] ? data[15] : false,
      artificialCommunityDance:
        testXls('künstl community dance', 16) && data[16] ? data[16] : false,
      comment: testXls('Bem_Vermittlungsfelder', 17) && data[17] ? data[17] : ''
    }
  }

  if (!result.surname && !result.name) {
    return null
  }

  return result
}

export function error(shouldBe: string, is: string, position: number) {
  const errorLog = `error parsing xls. Spalte ${position} sollte heissen: ${shouldBe} 'heisst aber: ${is}`
  notify.show(errorLog, 'error', 8000)
  console.error(errorLog)
}
